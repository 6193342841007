import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';

export default function ConnectionPrimarySourceTag() {
  return (
    <Tag variant="rounded" bgColor="#B7F1FF" borderColor="#52C9E5">
      <TagLeftIcon color="highlightDark" boxSize="4" as={Icon} iconImage={IconImage.badgeStar} />
      <TagLabel color="gray.600">Primary data source</TagLabel>
    </Tag>
  );
}
