/* eslint-disable no-nested-ternary */
import { Button, HStack, Text, Box, Stack, chakra, Divider } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { convertDuration, formatDateString } from '../../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../../lib/utils/number-utils';

function ManageScheduleIngestionHistory({ history, sourceId }: any) {
  const ChakraFaCircle = chakra(FaCircle);
  const historyData = history && history[0]?.content;

  return (
    <SectionContainer
      mt={10}
      w="full"
      header={
        <HStack justify="space-between">
          <Text fontSize="md" lineHeight={5} fontWeight="bold">
            Recent ingestion
          </Text>
          {historyData && historyData.length > 0 && (
            <Button as={Link} to={`/sources/${sourceId}/manage/ingestion-history`} variant="ghost">
              See full history
            </Button>
          )}
        </HStack>
      }
    >
      {historyData &&
        historyData.map(
          (item: any) =>
            item.status !== 'SKIPPED' && (
              <Box key={`${item.lastUpdated}-${item.errorMessage}`}>
                <Stack spacing={2}>
                  <Stack direction="row" justify="space-between">
                    <Text fontWeight="bold">
                      {formatDateString(item.startTime, 'LLL dd, yyyy')} at{' '}
                      {formatDateString(item.startTime, 'hh:mm a ')}
                    </Text>
                    <HStack>
                      <Text>
                        {item.status === 'COMPLETED'
                          ? 'Complete'
                          : item.status === 'FAILED'
                          ? 'Error'
                          : ''}
                      </Text>
                      {item.status === 'IN_PROGRESS' ? (
                        <HStack>
                          <Text> In progress </Text>
                          <chakra.span w="14px">
                            <Icon iconImage={IconImage.circularProgress} />
                          </chakra.span>
                        </HStack>
                      ) : (
                        <ChakraFaCircle
                          boxSize={3}
                          color={item.status === 'COMPLETED' ? 'success' : 'error'}
                        />
                      )}
                    </HStack>
                  </Stack>
                  {item.status === 'COMPLETED' && (
                    <Stack spacing={2}>
                      <HStack>
                        <Icon iconImage={IconImage.history} boxSize={5} />
                        <Text>
                          <Text>{convertDuration(item.durationMillis)}</Text>
                        </Text>
                      </HStack>
                      <HStack>
                        <Icon iconImage={IconImage.add} boxSize={5} />
                        <Text>{formatNumber(item.recordsIngested)} total records ingested </Text>
                      </HStack>
                    </Stack>
                  )}
                  {item.status === 'FAILED' && (
                    <Stack spacing={1}>
                      <HStack>
                        <Icon iconImage={IconImage.info} boxSize={5} color="error" />
                        <Text color="error" fontWeight="600">
                          Ingestion error encountered
                        </Text>
                      </HStack>
                      <HStack>
                        <Text ml={7}>{item.errorMessage}</Text>
                      </HStack>
                    </Stack>
                  )}
                </Stack>
                <Divider mt={3} mb={3} color="gray.300" borderWidth="thin" />
              </Box>
            )
        )}
      {historyData && historyData.length === 0 && <Text>No ingestions have been run.</Text>}
      {historyData && historyData.length > 9 && (
        <Button mb={4} as={Link} to={`/sources/${sourceId}/manage/ingestion-history`} w="full">
          See full schedule ingestion history{' '}
        </Button>
      )}
    </SectionContainer>
  );
}

export default ManageScheduleIngestionHistory;
