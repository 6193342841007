/* eslint-disable no-nested-ternary */
import { Box, chakra, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import { convertDuration, formatDateString } from '../../../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../../../lib/utils/number-utils';

interface SourceIngestionHistoryItemProps {
  historyData?: any;
}

export default function SourceIngestionHistoryItem({
  historyData,
}: SourceIngestionHistoryItemProps) {
  const ChakraFaCircle = chakra(FaCircle);
  const renderGridItems = () => {
    if (historyData.status === 'SKIPPED') {
      return null;
    }

    return (
      <>
        <GridItem w={historyData.status === 'IN_PROGRESS' ? '868px' : '240px'}>
          <Text fontWeight="600">
            {formatDateString(historyData.startTime, 'LLL dd, yyyy')} at{' '}
            {formatDateString(historyData.startTime, 'hh:mm a ')}
          </Text>
        </GridItem>
        {historyData.status !== 'FAILED' && historyData.status !== 'IN_PROGRESS' && (
          <>
            <GridItem w="300px">
              <HStack>
                <Icon iconImage={IconImage.history} boxSize={5} />
                <Text>{convertDuration(historyData.durationMillis)}</Text>
              </HStack>
            </GridItem>
            <GridItem w="270px">
              <HStack>
                <Icon iconImage={IconImage.add} boxSize={5} />
                <Text>{formatNumber(historyData.recordsIngested)} total records ingested</Text>
              </HStack>
            </GridItem>
          </>
        )}
        {historyData.status === 'FAILED' && (
          <GridItem w="592px">
            <HStack spacing={1}>
              <Icon iconImage={IconImage.info} boxSize={5} color="error" />
              <Text color="error" fontWeight="600">
                Ingestion error encountered
              </Text>
            </HStack>
            <HStack>
              <Text ml={7}>{historyData.errorMessage}</Text>
            </HStack>
          </GridItem>
        )}
        <GridItem w="120px">
          <HStack justifyContent="flex-end">
            <Text>
              {historyData.status === 'COMPLETED'
                ? 'Complete'
                : historyData.status === 'FAILED'
                ? 'Error'
                : historyData.status === 'IN_PROGRESS'
                ? 'In progress'
                : ''}
            </Text>

            {historyData.status === 'IN_PROGRESS' && (
              <Icon iconImage={IconImage.circularProgress} />
            )}
            {historyData.status !== 'IN_PROGRESS' && (
              <ChakraFaCircle
                boxSize={3}
                color={
                  historyData.status === 'COMPLETED'
                    ? 'success'
                    : historyData.status === 'FAILED'
                    ? 'error'
                    : ''
                }
              />
            )}
          </HStack>
        </GridItem>
      </>
    );
  };

  return (
    <Box>
      {historyData && (
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          {renderGridItems()}
        </Grid>
      )}
    </Box>
  );
}
