import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Can } from '../../../../../context/AuthorizationContext';
import SourceClient from '../../../../../lib/api-client/sources/SourceClient';
import Icon from '../../../../core/Icon/Icon';
import { IconImage } from '../../../../core/Icon/IconConfig';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: (enabled: boolean) => void;
}

function ConfirmationModal({ isOpen, onClose, onConfirmation }: ConfirmationModalProps) {
  const [isLoading, setLoading] = useBoolean(false);
  const modalOnClose = () => {
    setLoading.off();
    onClose();
  };
  const [isValid, setValid] = useState(false);

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={modalOnClose}
      isCentered
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent data-testid="ConfirmationModal">
        <ModalHeader fontWeight="bold">
          Are you sure you want to delete this source system?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          This is a permanent action that will delete the connection along with the previously
          ingested data from this source system.
          <FormControl mt={6}>
            <FormLabel fontWeight="semibold">
              Enter &quot;Delete&quot; below for confirmation
            </FormLabel>
            <Input
              name="deleteInput"
              autoFocus
              onChange={(evt) => setValid(evt.target.value === 'Delete')}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter justifyContent="start">
          <Button
            onClick={() => {
              setLoading.on();
              onConfirmation(true);
            }}
            isLoading={isLoading}
            isDisabled={!isValid}
            mr={4}
            data-testid="DeleteConnectionButton"
          >
            Delete now
          </Button>
          <Button variant="outline" onClick={modalOnClose} disabled={isLoading}>
            No, cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface DeleteConnectionSectionProps {
  sourceId: string;
  sourceName: string;
  onError?: (err?: any) => void;
}

function DeleteConnectionSection({ sourceId, sourceName, onError }: DeleteConnectionSectionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const onConfirmation = async () => {
    try {
      await SourceClient.deleteDataSource(sourceId);
      navigate('/sources/connections', {
        replace: true,
        state: {
          connectionDeleted: sourceName,
        },
      });
    } catch (err) {
      if (onError) {
        onError(err);
      }
      onClose();
    }
  };
  return (
    <chakra.span data-testid="DeleteConnectionSection">
      <Can I="delete" a="DataSource">
        <HStack
          bgColor="gray.200"
          h="40px"
          p="3"
          w="600px"
          justifyContent="space-between"
          boxShadow="md"
          borderRadius="6px"
        >
          <HStack>
            <chakra.span fontSize="sm">
              <Icon iconImage={IconImage.searchData} boxSize="5" />{' '}
            </chakra.span>
            <chakra.span fontSize="sm" fontWeight="bold">
              No longer need this connection?{' '}
            </chakra.span>
          </HStack>
          <Button variant="ghost" textColor="error" onClick={onOpen}>
            Delete connection
          </Button>
        </HStack>
        <ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirmation={onConfirmation} />
      </Can>
    </chakra.span>
  );
}
export default DeleteConnectionSection;
