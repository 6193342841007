import consola from 'consola';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Combobox from '../../../components/core/form/Combobox/Combobox';
import { usePerformConfigAction } from '../../../lib/api-client/sources/SourceData';
import { ConfigAttribute } from '../../../lib/api-client/sources/model/SourceTypes';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';

interface ConfigAttributeLookupControlProps {
  name: string;
  configAttribute: ConfigAttribute;
  selectRefetch?: number;
  lookupConfig: {
    enabled: boolean;
    args?: { name: string; value: any }[];
  };
}
export default function ConfigAttributeLookupControl({
  name,
  configAttribute,
  lookupConfig,
  selectRefetch,
}: ConfigAttributeLookupControlProps) {
  const { dataSource } = useCurrentDataSource();
  const { data, refetch } = usePerformConfigAction(
    dataSource.id,
    {
      stepName: configAttribute.configStep,
      action: configAttribute.valuesLookup?.configAction,
      attributeValues: lookupConfig.args ?? [],
    },
    lookupConfig.enabled
  );
  const { setValue, getValues } = useFormContext();

  const options = (data?.lookupValues ?? []).map((lookupValue) => ({
    label: lookupValue,
    value: lookupValue,
  }));
  const value = getValues(name);

  useEffect(() => {
    const fetchData = async () => {
      if (selectRefetch) {
        try {
          await refetch();
        } catch (error) {
          consola.error('Error refetching data', error);
        }
      }
    };

    fetchData();
  }, [selectRefetch, refetch]);

  return (
    <Combobox
      name={name}
      options={options}
      value={value ? [value] : []}
      allowCustomValue
      onChange={(e: any) => {
        setValue(name, e.value[0], { shouldValidate: true });
      }}
    />
  );
}
