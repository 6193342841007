import Alert from '../../../../components/core/Alert/Alert';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';

export default function ExportErrorAlert() {
  const { exportStatus } = useCurrentDataSource();
  if (exportStatus?.exportState !== 'FAILED') {
    return null;
  }

  return (
    <Alert status="error" title="An error was detected." description={exportStatus.errorMessage} />
  );
}
