import { getScrollSchema, useFetch, useSchema, useScrollSchema } from '@hazae41/xswr';
import { TimeSeriesAggregate, TimeSeriesIntervalName } from '../../model/common';
import { Page } from '../../model/common/Page';
import instance, { getApiClientSchema } from '../ApiClient';
import {
  DataSourceTimeSeriesMetrics,
  TimeSeriesQueryResponse,
} from '../source-metrics/model/TimeSeriesQueryResponse';
import {
  DataRepairAuditEvent,
  DataRepairAutomation,
  DataRepairAutomationType,
  DataRepairMetrics,
  DataRepairSystemMetrics,
} from './data-repair.model';

export function getDataRepairs(automationType: DataRepairAutomationType) {
  return getApiClientSchema<DataRepairAutomation>({
    method: 'get',
    url: `/data-repairs/${automationType}`,
  });
}

export function getDataSourceIssue(automationType: any, sourceId: any) {
  const pageSize = 100;

  const url = `/source-issues/${sourceId}/records?sourceIssueType=${automationType}&size=${pageSize}`;

  return getApiClientSchema<Page<any>>({
    method: 'get',
    url,
  });
}

export function useDataIssue(sourceId: string, automationType: string) {
  const schema = useSchema(getDataSourceIssue, [automationType, sourceId]);
  useFetch(schema);
  return schema;
}

export function useDataRepairs(automationType: DataRepairAutomationType) {
  const schema = useSchema(getDataRepairs, [automationType]);
  useFetch(schema);
  return schema;
}

export function getDataRepairMetrics(startTime?: any) {
  return getApiClientSchema<DataRepairSystemMetrics>({
    method: 'get',
    url: `/data-repair-metrics`,
    params: {
      startTime,
    },
  });
}

export function useDataRepairMetrics(startTime?: any) {
  const schema = useSchema(getDataRepairMetrics, [startTime]);
  useFetch(schema);
  return schema;
}

export function getSourceTimeSeriesData(
  sourceId: string,
  metric: DataSourceTimeSeriesMetrics[],
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  aggregateSummary?: TimeSeriesAggregate[]
) {
  return getApiClientSchema<TimeSeriesQueryResponse>({
    method: 'get',
    url: `/identity-time-series/${sourceId}`,
    params: {
      sourceId,
      metric,
      startDate,
      endDate,
      timezone,
      intervalName,
      aggregateSummary,
    },
  });
}

export function useTimeSeriesMetrics(
  sourceId: string,
  metric: DataSourceTimeSeriesMetrics[],
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  aggregateSummary?: TimeSeriesAggregate[]
) {
  const schema = useSchema(getSourceTimeSeriesData, [
    sourceId,
    metric,
    startDate,
    endDate,
    timezone,
    intervalName as any,
    aggregateSummary,
  ]);
  useFetch(schema);

  return schema;
}

export function getDataRepairTimeSeriesMetrics(
  repairAutomationType: DataRepairAutomationType,
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  metric: DataRepairMetrics
) {
  return getApiClientSchema<TimeSeriesQueryResponse>({
    method: 'get',
    url: `/data-repair-time-series`,
    params: {
      repairAutomationType,
      startDate,
      endDate,
      timezone,
      intervalName,
      metric,
    },
  });
}

export function useDataRepairTimeSeriesMetrics(
  repairAutomationType: DataRepairAutomationType,
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  metric: DataRepairMetrics
) {
  const schema = useSchema(getDataRepairTimeSeriesMetrics, [
    repairAutomationType,
    startDate,
    endDate,
    timezone,
    intervalName,
    metric,
  ]);
  useFetch(schema);
  return schema;
}

export function getDataRepairTimeSeriesMetricsData(
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  metric: DataRepairMetrics,
  aggregateSummary: string
) {
  return getApiClientSchema<TimeSeriesQueryResponse>({
    method: 'get',
    url: `/data-repair-time-series`,
    params: {
      startDate,
      endDate,
      timezone,
      intervalName,
      metric,
      aggregateSummary,
    },
  });
}

export function useDataRepairTimeSeriesMetricsData(
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  metric: DataRepairMetrics,
  aggregateSummary: string
) {
  const schema = useSchema(getDataRepairTimeSeriesMetricsData, [
    startDate,
    endDate,
    timezone,
    intervalName,
    metric,
    aggregateSummary,
  ]);
  useFetch(schema);
  return schema;
}

export function getScrollableDataRepairAuditEvents(filters?: {
  id?: string;
  sourceId?: string;
  repairAutomationTypes?: DataRepairAutomationType[];
  startTime?: string;
  endTime?: string;
  eventType?: string;
}) {
  return getScrollSchema(
    (page?: Page<DataRepairAuditEvent>) => {
      if (page?.last) {
        return undefined;
      }

      const params: any = {};
      if (filters) {
        if (filters.id != null) {
          params.id = filters.id;
        }

        if (filters.sourceId != null) {
          params.sourceId = filters.sourceId;
        }

        if (filters.eventType != null) {
          // eslint-disable-next-line prefer-destructuring
          params.repairAutomationTypes = filters.eventType;
        }
        if (filters.startTime !== undefined && filters.endTime !== undefined) {
          params.startTime = filters.startTime;
          params.endTime = filters.endTime;
        }
      }

      return {
        method: 'get',
        url: '/data-repair-audit-events',
        params: {
          page: page ? page.number + 1 : 0,
          size: 100,
          sort: ['timestamp,desc'],
          ...params,
        },
      };
    },
    (key) => instance(key)
  );
}

export function useScrollableDataRepairAuditEvents(filters?: {
  id?: string;
  sourceId?: string;
  eventType?: string;
}) {
  const query = useScrollSchema(getScrollableDataRepairAuditEvents, [filters]);
  useFetch(query);
  return query;
}

export function getDataMetricsTimeSeriesMetrics(
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  metric: any
) {
  return getApiClientSchema<TimeSeriesQueryResponse>({
    method: 'get',
    url: `/identity-time-series`,
    params: {
      startDate,
      endDate,
      timezone,
      intervalName,
      metric,
    },
  });
}

export function useDataMetricsTimeSeriesMetrics(
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName,
  metric: any
) {
  const schema = useSchema(getDataMetricsTimeSeriesMetrics, [
    startDate,
    endDate,
    timezone,
    intervalName,
    metric,
  ]);
  useFetch(schema);
  return schema;
}
