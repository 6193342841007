import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import consola from 'consola';
import React from 'react';
import { useToast } from '../../../../hooks/use-toast';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { openTrayConfigWindow } from '../../../../lib/utils/iframe-utils';

interface TrayConfigurationButtonProps {
  sourceId?: string;
  onSuccess: () => void;
  // FIXME: Remove once we are good with the new
  onError?: () => void;
}

function TrayConfigurationButton({ sourceId, onSuccess, onError }: TrayConfigurationButtonProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onConfigSuccess = () => {
    onSuccess();
    onClose();
  };

  const onConfigError = (err: any) => {
    consola.error('error thrown from tray.io', err);
    if (onError) {
      onError();
    } else {
      toast({ status: 'error', description: 'Error updating configuration' });
    }
    onClose();
  };

  const manageTrayConfigClicked = () => {
    if (sourceId) {
      SourceClient.getDataSourceConfig(sourceId)
        .then(({ url }) => {
          onOpen();
          openTrayConfigWindow(url, onClose, onConfigSuccess, onConfigError);
        })
        .catch(() => {
          if (onError) {
            onError();
          } else {
            toast({ status: 'error', description: 'Error updating configuration' });
          }
        });
    }
  };

  return (
    <>
      <Button data-testid="TrayConfigurationButton" onClick={manageTrayConfigClicked} width="full">
        Configure connection
      </Button>
      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="0">
          <ModalBody>
            <Center fontSize="2xl" fontWeight="semibold" color="white">
              Complete the connection configuration popup to continue
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default TrayConfigurationButton;
