import { Box, chakra, Heading, HStack } from '@chakra-ui/react';
import { Identity, ProfileTags } from '../../../../lib/api-client/identity/model/Identity';
import ProfileAuthenticityBadge from '../ProfileAuthenticityBadge/ProfileAuthenticityBadge';
import ProfileType from '../ProfileType/ProfileType';
import SimilarProfile from '../SimilarProfile/SimilarProfile';

interface ProfileHeaderProps {
  identity: Pick<Identity, 'identityAttributes' | 'pin' | 'pinType'>;
  authenticityTag?: ProfileTags;
  similarProfile?: any;
}

function ProfileHeader({ identity, authenticityTag, similarProfile }: ProfileHeaderProps) {
  return (
    <Box data-testid="ProfileHeader">
      <Heading fontSize="xl" as="h3" paddingBottom={1}>
        {identity.identityAttributes.name?.primaryValue.value ?? ''}
      </Heading>
      <HStack spacing={2.5}>
        <chakra.span fontSize="sm">PIN: {identity.pin}</chakra.span>
        {authenticityTag && <ProfileAuthenticityBadge tag={authenticityTag} />}
        {identity && <ProfileType pinType={identity.pinType} />}
        {similarProfile && similarProfile.length > 1 && (
          <SimilarProfile similarProfile={similarProfile} pin={identity.pin} />
        )}
      </HStack>
    </Box>
  );
}
export default ProfileHeader;
