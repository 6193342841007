import { Box } from '@chakra-ui/react';
import React, { cloneElement, ReactNode, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DataKey } from 'recharts/types/util/types';
import { formatCompactNumber } from '../../../../lib/utils/number-utils';
import ReferenceLineTooltip from '../ReferenceLineTooltip/ReferenceLineToolTip';

function CustomToolTip({
  active,
  payload,
  children,
  isLastInterval = false,
}: {
  active?: boolean;
  payload?: any;
  children: ReactNode;
  isLastInterval?: boolean;
}) {
  if (active && payload && payload?.length) {
    return (
      <Box
        maxW="320px"
        w="320px"
        color="white"
        background="gray.800"
        borderRadius="6px"
        padding="2"
        fontSize="sm"
      >
        {cloneElement(children as any, { payload, isLastInterval })}
      </Box>
    );
  }

  return null;
}

function MemoCustomToolTip({ tooltipContent, ...rest }: any) {
  return <CustomToolTip {...rest}>{tooltipContent}</CustomToolTip>;
}

interface SimpleBarGraphProps<T> {
  data: T[];
  xDataKey: DataKey<any>;
  yDataKey: DataKey<any>;
  referenceValue?: any;
  width?: number | string;
  height?: number | string;
  tooltipContent?: JSX.Element;
  referenceContent?: ReactNode;
  highlightLastInterval?: boolean;
  yTickFormatter?: (value: T) => any;
  xAxisAngle?: number;
}

function SimpleBarGraph({
  data = [],
  xDataKey,
  yDataKey,
  width = '100%',
  height = '100%',
  tooltipContent,
  referenceValue,
  referenceContent,
  highlightLastInterval = false,
  yTickFormatter = (value) => formatCompactNumber(value).toString(),
  xAxisAngle,
}: SimpleBarGraphProps<any>) {
  const [focusBar, setFocusBar] = useState<number | null>(null);

  if (!Array.isArray(data)) {
    return null;
  }

  return (
    <Box
      data-testid="SimpleBarGraph"
      onMouseOut={() => {
        setFocusBar(null);
      }}
    >
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          data={data}
          onMouseMove={(state: any) => {
            if (state && state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}
        >
          <XAxis
            dataKey={xDataKey}
            tickLine={false}
            stroke="var(--chakra-colors-gray-300)"
            tick={{ fill: 'var(--chakra-colors-gray-800)', fontSize: 12 }}
            dy={10}
            angle={xAxisAngle}
          />
          <YAxis
            tickLine={false}
            stroke="var(--chakra-colors-gray-300)"
            tick={{ fill: 'var(--chakra-colors-gray-800)', fontSize: 12 }}
            tickFormatter={yTickFormatter}
          />
          {tooltipContent && (
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={
                <MemoCustomToolTip
                  tooltipContent={tooltipContent}
                  isLastInterval={highlightLastInterval && (data.length - 1 ?? 0) === focusBar}
                />
              }
            />
          )}
          {referenceValue && (
            <ReferenceLine
              y={referenceValue}
              stroke="var(--chakra-colors-action)"
              strokeWidth="2"
              strokeDasharray="6 4"
              label={
                <Label
                  position="left"
                  content={
                    <ReferenceLineTooltip
                      referenceContent={referenceContent}
                      color="var(--chakra-colors-action)"
                    />
                  }
                />
              }
            />
          )}
          <Bar
            dataKey={yDataKey}
            fill="var(--chakra-colors-action)"
            radius={[5, 5, 0, 0]}
            minPointSize={5}
          >
            {data.map((_: any, index: number) => {
              let color =
                focusBar === index
                  ? 'var(--chakra-colors-actionDark)'
                  : 'var(--chakra-colors-action)';
              if (highlightLastInterval && index === (data?.length ?? 0) - 1) {
                color =
                  focusBar === index
                    ? 'var(--chakra-colors-gray-600)'
                    : 'var(--chakra-colors-gray-400)';
              }
              return (
                <Cell
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  fill={color}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
export default SimpleBarGraph;
