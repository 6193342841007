import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import IngestionHistoryChart from '../../../components/features/connections/IngestionHistoryChart/IngestionHistoryChart';
import IngestionHistoryChartPerSource from '../../../components/features/connections/IngestionHistoryChart/IngestionHistoryChartPerSource';
import { formatNumber } from '../../../lib/utils/number-utils';

interface AddsAndUpdatesActivityProps {
  sourceId: string;
  sourceName: string;
  metrics: any;
}
export default function AddsAndUpdatesActivity({
  sourceId,
  sourceName,
  metrics,
}: AddsAndUpdatesActivityProps) {
  const [events, setEvents] = useState<null | number>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <SectionContainer variant="box">
      <SimpleGrid columns={2}>
        <Stack>
          <Text as="span" fontWeight="bold">
            Adds & updates
          </Text>
          <Text fontWeight="bold" fontSize="2xl">
            {events != null ? formatNumber(events) : ''}
          </Text>
          <Text>total data record add or update events ingested in the last 7 days</Text>
          <Button fontWeight="semibold" onClick={onOpen} variant="ghost" alignSelf="start">
            Explore all activity
          </Button>
        </Stack>

        <Drawer isOpen={isOpen} size="xl" placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent pr={2}>
            <DrawerCloseButton />
            <DrawerHeader>Ingestion activity for {sourceName} </DrawerHeader>
            <DrawerBody>
              <IngestionHistoryChartPerSource
                chartWidth="102%"
                chartHeight={330}
                sourceId={sourceId}
                recordEventsIngested={metrics?.recordEventsIngested?.total}
                recordsDeleted={metrics?.recordEventsDeleted?.total}
                error={metrics?.recordEventsIngestedLastWeek?.errors}
                addUpdateEvents={events ?? 0}
                hideMetricsCards
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <IngestionHistoryChart
          sourceId={sourceId}
          hideFilters
          hideReferenceValue
          chartHeight={140}
          onDataChange={(data) => {
            setEvents(data?.referenceValue?.recordEventsIngested?.sum ?? 0);
          }}
        />
      </SimpleGrid>
    </SectionContainer>
  );
}
