import { SimpleGrid } from '@chakra-ui/react';
import { useFlags } from 'flagsmith/react';
import { FeatureFlags } from '../../../../lib/model/FeatureFlags';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import ExportStatusSection from '../ExportStatusSection/ExportStatusSection';
import IngestionStatusSection from '../IngestionStatusSection/IngestionStatusSection';
import ManageScheduledSection from '../ManageScheduled/ManageScheduledSection';
import RealtimeIngestionSection from '../RealtimeIngestionSection/RealtimeIngestionSection';

export default function IntegratedSourceConfiguration() {
  const { dataSource } = useCurrentDataSource();
  const isIn = dataSource.syncDirection !== 'OUT';
  const isOut = dataSource.syncDirection !== 'IN';
  const flags = useFlags([FeatureFlags.SHOW_CONNECTION_INGESTION_SCHEDULE]);
  const isNotShowschedule =
    dataSource.requiresInternalConfiguration === false &&
    dataSource.requiresExternalConfiguration === false;

  return (
    <SimpleGrid columns={isOut ? 4 : 3} gap={5} minH="0">
      {isIn && (
        <>
          <IngestionStatusSection />
          <RealtimeIngestionSection />
        </>
      )}
      {flags.show_connection_ingestion_schedule.enabled && !isNotShowschedule && (
        <ManageScheduledSection />
      )}
      {isOut && <ExportStatusSection />}
    </SimpleGrid>
  );
}
