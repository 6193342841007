import {
  VStack,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useGetTimeZone } from '../../../../lib/api-client/sources/SourceData';
import { formatDateString } from '../../../../lib/utils/date-time-utils';

interface FormValues {
  frequency?: string;
  interval?: string;
  day?: string;
  time?: string;
  timeZone?: string;
  status?: string;
  dayOfWeek?: string;
  minutesOffset?: number;
}

interface ManageScheduleFormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  data: any;
}

function ManageScheduleForm({ onSubmit, data }: ManageScheduleFormProps) {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      frequency: data?.frequency || '',
      interval: data?.interval || '',
      day: data?.day || '',
      time: data?.time || '',
      status: data?.status || '',
      dayOfWeek: data?.dayOfWeek || '',
      minutesOffset: data?.minutesOffset || 0,
    },
  });

  const { data: timeZoneData } = useGetTimeZone();
  const [intervalOptions, setIntervalOptions] = useState<{ value: number; label: string }[]>([]);
  const [showDailyFields, setShowDailyFields] = useState(false);
  const [showWeeklyFields, setShowWeeklyFields] = useState(false);

  const frequency = watch('frequency');
  const minutesOffset = watch('minutesOffset');
  const time = watch('time');

  useEffect(() => {
    const options: { value: number; label: string }[] = [];
    let showDaily = false;
    let showWeekly = false;

    switch (frequency) {
      case 'MINUTES':
        options.push(
          { value: 5, label: '5 minutes' },
          { value: 10, label: '10 minutes' },
          { value: 15, label: '15 minutes' },
          { value: 30, label: '30 minutes' }
        );
        break;
      case 'HOURS':
        options.push(
          { value: 1, label: '1 hour' },
          { value: 2, label: '2 hours' },
          { value: 3, label: '3 hours' },
          { value: 4, label: '4 hours' },
          { value: 6, label: '6 hours' },
          { value: 12, label: '12 hours' }
        );
        break;
      case 'DAILY':
        showDaily = true;
        break;
      case 'WEEKLY':
        showWeekly = true;
        break;
      default:
        break;
    }

    setIntervalOptions(options);
    setShowDailyFields(showDaily);
    setShowWeeklyFields(showWeekly);
  }, [frequency]);

  useEffect(() => {
    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      const minutesOffsetValue = hours * 60 + minutes;
      setValue('minutesOffset', minutesOffsetValue);
    }
  }, [time, setValue]);

  useEffect(() => {
    if (data?.timeZone) {
      setValue('timeZone', data?.timeZone);
    }
  }, [data, setValue]);

  const timeZoneValue = watch('timeZone');

  useEffect(() => {
    if (data?.frequency) setValue('frequency', data?.frequency);
    if (data?.interval) setValue('interval', data?.interval);
    if (data?.day) setValue('day', data?.day);
    if (data?.time) {
      const timeValue = data?.time.slice(0, 5);
      setValue('time', timeValue);
    }
    if (data?.status) setValue('status', data?.status);
    if (data?.dayOfWeek) setValue('dayOfWeek', data?.dayOfWeek);
    if (data?.minutesOffset) setValue('minutesOffset', data?.minutesOffset);
  }, [data, setValue]);

  useEffect(() => {
    if (minutesOffset) {
      const hours = Math.floor(minutesOffset / 60);
      const minutes = minutesOffset % 60;
      const timeValue = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
      setValue('time', timeValue);
    }
  }, [minutesOffset, setValue]);

  const handleFormSubmit = async (values: FormValues) => {
    const updatedValues = { ...values };
    if (values.frequency === 'DAILY' || values.frequency === 'WEEKLY') {
      updatedValues.minutesOffset = values.minutesOffset ?? 0;
    }

    if (values.timeZone === '') {
      delete updatedValues.timeZone;
    }

    try {
      await onSubmit(updatedValues);
      toast({
        title: 'Schedule has been saved',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        colorScheme: 'successScheme',
      });
    } catch (error) {
      toast({
        title: 'Schedule could not be saved',
        description: 'Please try again',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        colorScheme: 'errorScheme',
      });
    }
  };

  return (
    <SectionContainer
      mt={10}
      w="full"
      header={
        <HStack justify="space-between">
          <Text fontSize="md" lineHeight={5} fontWeight="bold">
            Manage schedule
          </Text>
          {data?.lastUpdated && (
            <Text fontSize="xs" color="gray.600">
              Last updated {formatDateString(data?.lastUpdated, 'LLL dd, yyyy')}
            </Text>
          )}
        </HStack>
      }
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <VStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel>Frequency</FormLabel>
            <Select placeholder="Select one" {...register('frequency')}>
              <option value="MINUTES">Minutes</option>
              <option value="HOURS">Hours</option>
              <option value="DAILY">Days</option>
              <option value="WEEKLY">Weeks</option>
            </Select>
          </FormControl>
          {intervalOptions.length > 0 && (
            <FormControl>
              <FormLabel>Interval</FormLabel>
              <Select placeholder="Select interval" {...register('interval')}>
                {intervalOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {showDailyFields && (
            <HStack>
              <FormControl>
                <FormLabel>Time</FormLabel>
                <Input type="time" placeholder="Enter time of day" {...register('time')} />
              </FormControl>
              <FormControl>
                <FormLabel>Timezone</FormLabel>
                <Select
                  defaultValue={data?.timeZone || ''}
                  placeholder="Select timeZone"
                  {...register('timeZone')}
                >
                  {Array.isArray(timeZoneData) &&
                    timeZoneData.map((timeZoneList) => (
                      <option
                        key={timeZoneList}
                        value={timeZoneList}
                        selected={timeZoneList === timeZoneValue}
                      >
                        {timeZoneList}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </HStack>
          )}
          {showWeeklyFields && (
            <>
              <FormControl>
                <FormLabel>Day of Week</FormLabel>
                <Select placeholder="Select day of week" {...register('dayOfWeek')}>
                  <option value="MON">Monday</option>
                  <option value="TUE">Tuesday</option>
                  <option value="WED">Wednesday</option>
                  <option value="THU">Thursday</option>
                  <option value="FRI">Friday</option>
                  <option value="SAT">Saturday</option>
                  <option value="SUN">Sunday</option>
                </Select>
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Time</FormLabel>
                  <Input type="time" placeholder="Enter time of day" {...register('time')} />
                </FormControl>
                <FormControl>
                  <FormLabel>Timezone</FormLabel>
                  <Select
                    defaultValue={data?.timeZone || ''}
                    placeholder="Select timeZone"
                    {...register('timeZone')}
                  >
                    {Array.isArray(timeZoneData) &&
                      timeZoneData.map((timeZoneList) => (
                        <option
                          key={timeZoneList}
                          value={timeZoneList}
                          selected={timeZoneList === timeZoneValue}
                        >
                          {timeZoneList}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </HStack>
            </>
          )}
          <Button w="100px" loadingText="Saving..." isLoading={isSubmitting} type="submit">
            Save
          </Button>
        </VStack>
      </form>
    </SectionContainer>
  );
}

export default ManageScheduleForm;
