import { useEffect, useState } from 'react';
import Alert from '../../../../components/core/Alert/Alert';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';

export default function InitialSetupAlert() {
  const { dataSource } = useCurrentDataSource();
  const params = new URLSearchParams(window.location.search);
  const [showAlert] = useState(params.get('firstSetup') === 'true');

  useEffect(() => {
    if (showAlert) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('firstSetup');
      window.history.replaceState(null, '', `?${searchParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dataSource.requiresInternalConfiguration || !showAlert) {
    return null;
  }

  const title = `${dataSource.name} is successfully connected to harpin AI`;

  return (
    <Alert
      status="success"
      title={title}
      description="Next, enable the connection and configure the incremental ingestion schedule to start ingesting and processing data."
      closeable
    />
  );
}
