import { Flex } from '@chakra-ui/react';
import React from 'react';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { Can } from '../../../../context/AuthorizationContext';
import ConnectionConfiguration from '../ConnectionConfiguration/ConnectionConfiguration';
import EnableIngestionButton from './EnableIngestionButton';

function IngestionStatusSection() {
  return (
    <Can I="update" a="DataSource">
      <SectionContainer
        title="Source connection"
        variant="box"
        data-testid="IngestionStatusSection"
        w="full"
        titleFontSize="sm"
        display="flex"
        flexDirection="column"
        minH="0"
      >
        <Flex minH="0" h="full" direction="column" justify="space-between" gap="3">
          <EnableIngestionButton />
          <ConnectionConfiguration />
        </Flex>
      </SectionContainer>
    </Can>
  );
}
export default IngestionStatusSection;
