import { Button, Checkbox, Flex, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import { useFlags } from 'flagsmith/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import DataExplorer from '../../../../components/shared/data-explorer/DataExplorer/DataExplorer';
import DataExplorerSkeleton from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerSkeleton';
import DataRecordDetails from '../../../../components/shared/data-explorer/DataRecordDetails/DataRecordDetails';
import EditRecordModal from '../../../../components/shared/data-explorer/EditRecordModal/EditRecordModal';
import { useAuth } from '../../../../context/AuthenticationContext';
import { useSearchIdentityRecords } from '../../../../lib/api-client/identity/IdentityRecordData';
import { SourceIdentityRecordSearch } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';
import { SourceIdentityRecordSearchResponse } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import { DataSourceAttributeMapping } from '../../../../lib/api-client/sources/model/DataSourceAttributeMapping';
import { FeatureFlags } from '../../../../lib/model/FeatureFlags';
import { Page } from '../../../../lib/model/common/Page';
import LocalStorageService from '../../../../lib/services/LocalStorageService';
import { isUndefined } from '../../../../lib/utils/utils';
import ExportCsvButton from '../../../data-explorer/components/ExportCsvButton/ExportCsvButton';
import ReportExplorer from '../../../data-explorer/components/ReportExplorer/ReportExplorer';
import { mapIdentityRecordSearchToReportQuery } from '../../../data-explorer/components/SourceSystemRecordExplorer/SourceSystemRecordExplorer.utils';
import {
  ADDRESS_SCORE_RANGES,
  EMAIL_SCORE_RANGES,
  NAME_SCORE_RANGES,
  VALIDITY_RANGES,
} from '../../../data-explorer/components/SourceSystemRecordExplorer/description-utils';
import { useCurrentDataSource } from '../../context/CurrentDataSourceContext';
import ExternalLinkCell from './ExternalLinkCell';
import IdentityRecordCell from './IdentityRecordCell';
import IdentityRecordAddressCell from './IdentityRecoredAddressCell';
import PhoneCell from './PhoneCell';
import ProfileLinkCell from './ProfileLinkCell';
import ScoreCell from './ScoreCell';
import SourceSystemRecordExplorerFilterDrawer from './SourceSystemRecordExplorerFilterDrawer';
import SplitDuplicatesFooterAction from './SplitDuplicatesFooterAction';
import {
  SourceSystemRecordFilterFormChangeEvent,
  urlParamsToSearch,
} from './source-system-filter-utils';

const columnHelper = createColumnHelper<any>();

const defaultColumnVisibility: VisibilityState = {
  identityRecord_pin: true,
  scores_recordScore: false,
  scores_maxSimilarityScore: false,
  identityRecord_sourceRecordId: true,
  identityRecord_firstName: true,
  identityRecord_middleName: false,
  identityRecord_lastName: true,
  identityRecord_nameSuffix: true,
  identityRecord_emailAddress: true,
  identityRecord_mobilePhone: true,
  identityRecord_homePhone: true,
  identityRecord_workPhone: true,
  identityRecord_dateOfBirth: true,
  'scores_attributeScores.firstName': false,
  'scores_attributeScores.lastName': false,
  'scores_attributeScores.emailAddress': false,
  'scores_attributeScores.mobilePhone': false,
  'scores_attributeScores.homePhone': false,
  'scores_attributeScores.workPhone': false,
  'scores_attributeScores.dateOfBirth': false,
  'scores_attributeScores.address': false,
  identityRecord_gender: false,
  identityRecord_ipAddress: false,
  identityRecord_accountId: false,
  identityRecord_ccLast4: false,
  identityRecord: false,
};

function getDefinedColumns(attributeMappings: DataSourceAttributeMapping[]) {
  const definedMappings = attributeMappings
    .filter((mapping) => mapping.domainType === 'IDENTITY')
    .map((mapping) => mapping.canonicalAttribute);

  const definedColumns: ColumnDef<any, any>[] = [
    columnHelper.display({
      header: ' ',
      id: 'select',
      maxSize: 10,
      enableSorting: false,
      meta: {
        emptyDisplay: true,
      },
      cell: (props) => (
        <div>
          <Checkbox
            onClick={(e) => e.preventDefault()}
            onChange={props.row.getToggleSelectedHandler()}
          />
        </div>
      ),
    }),
    columnHelper.accessor('identityRecord.pin', {
      header: 'PIN',
      minSize: 40,
      cell: (props) => <ProfileLinkCell cellContext={props} />,
    }),
    columnHelper.accessor('scores.recordScore', { header: 'Record score', minSize: 20 }),
    columnHelper.accessor('scores.maxSimilarityScore', { header: 'Similarity score', minSize: 20 }),
    columnHelper.accessor('identityRecord.sourceRecordId', {
      header: 'Source system ID',
      cell: (props) => <ExternalLinkCell cellContext={props} />,
    }),
  ];

  if (definedMappings.includes('firstName') || definedMappings.includes('fullName')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.firstName', {
        header: 'First name',
        minSize: 20,
        cell: (props) => <IdentityRecordCell cellContext={props} />,
      }),
      columnHelper.accessor('scores.attributeScores.firstName', {
        header: 'First name score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={NAME_SCORE_RANGES} />,
      })
    );
  }

  if (definedMappings.includes('middleName') || definedMappings.includes('fullName')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.middleName', {
        header: 'Middle name',
        minSize: 20,
        cell: (props) => <IdentityRecordCell cellContext={props} />,
      })
    );
  }

  if (definedMappings.includes('lastName') || definedMappings.includes('fullName')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.lastName', {
        header: 'Last name',
        minSize: 20,
        cell: (props) => <IdentityRecordCell cellContext={props} />,
      }),
      columnHelper.accessor('scores.attributeScores.lastName', {
        header: 'Last name score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={NAME_SCORE_RANGES} />,
      })
    );
  }
  if (definedMappings.includes('nameSuffix')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.nameSuffix', {
        header: 'Name suffix',
        minSize: 20,
        cell: (props) => <IdentityRecordCell cellContext={props} />,
      })
    );
  }

  if (definedMappings.includes('emailAddress')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.emailAddress', {
        header: 'Email address',
        minSize: 20,
        cell: (props) => <IdentityRecordCell cellContext={props} />,
      }),
      columnHelper.accessor('scores.attributeScores.emailAddress', {
        header: 'Email address score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={EMAIL_SCORE_RANGES} />,
      })
    );
  }

  if (definedMappings.includes('mobilePhone')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.mobilePhone', {
        header: 'Mobile phone number',
        minSize: 20,
        cell: (props) => <PhoneCell cellContext={props} />,
      }),
      columnHelper.accessor('scores.attributeScores.mobilePhone', {
        header: 'Mobile phone score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      })
    );
  }

  if (definedMappings.includes('homePhone')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.homePhone', {
        header: 'Home phone number',
        minSize: 20,
        cell: (props) => <PhoneCell cellContext={props} />,
      }),
      columnHelper.accessor('scores.attributeScores.homePhone', {
        header: 'Home phone score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      })
    );
  }

  if (definedMappings.includes('workPhone')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.workPhone', {
        header: 'Work phone number',
        minSize: 20,
        cell: (props) => <PhoneCell cellContext={props} />,
      }),
      columnHelper.accessor('scores.attributeScores.workPhone', {
        header: 'Work phone score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      })
    );
  }

  if (definedMappings.includes('optInEmail')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.optInEmail', {
        header: 'Opt-in email',
        minSize: 20,
      })
    );
  }

  if (definedMappings.includes('optInPhone')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.optInPhone', {
        header: 'Opt-in phone',
        minSize: 20,
      })
    );
  }

  if (definedMappings.includes('optInText')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.optInText', {
        header: 'Opt-in text',
        minSize: 20,
      })
    );
  }

  if (definedMappings.includes('dateOfBirth')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.dateOfBirth', {
        header: 'Date of birth',
        minSize: 20,
        cell: (props) => <IdentityRecordCell cellContext={props} preferNormalizedValue />,
      }),
      columnHelper.accessor('scores.attributeScores.dateOfBirth', {
        header: 'Date of birth score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      })
    );
  }

  if (definedMappings.includes('gender')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.gender', {
        header: 'Gender',
        minSize: 20,
      })
    );
  }

  if (definedMappings.includes('ipAddress')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.ipAddress', {
        header: 'IP address',
        minSize: 20,
      })
    );
  }

  if (definedMappings.includes('ccLast4')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.ccLast4', {
        header: 'ccLast4',
        minSize: 20,
      })
    );
  }

  if (definedMappings.includes('accountId')) {
    definedColumns.push(
      columnHelper.accessor('identityRecord.accountId', {
        header: 'Account Id',
        minSize: 20,
      })
    );
  }

  if (
    definedMappings.includes('streetAddress') ||
    definedMappings.includes('city') ||
    definedMappings.includes('governingDistrict') ||
    definedMappings.includes('postalCode') ||
    definedMappings.includes('countryCode')
  ) {
    definedColumns.push(
      columnHelper.accessor('identityRecord', {
        header: 'Address',
        minSize: 20,
        enableSorting: false,
        cell: (props) => (
          <IdentityRecordAddressCell
            streetAddress={
              props.getValue().streetAddress?.modified
                ? props.getValue().streetAddress.modified
                : props.getValue().streetAddress.raw ?? props.getValue().streetAddress.normalized
            }
            city={
              props.getValue().city?.modified
                ? props.getValue().city.modified
                : props.getValue().city.raw ?? props.getValue().city.normalized
            }
            governingDistrict={
              props.getValue().governingDistrict?.modified
                ? props.getValue().governingDistrict.modified
                : props.getValue().governingDistrict.raw ??
                  props.getValue().governingDistrict.normalized
            }
            postalCode={
              props.getValue().postalCode?.modified
                ? props.getValue().postalCode.modified
                : props.getValue().postalCode.raw ?? props.getValue().postalCode.normalized
            }
            countryCode={
              props.getValue().countryCode?.modified
                ? props.getValue().countryCode.modified
                : props.getValue().countryCode.raw ?? props.getValue().countryCode.normalized
            }
          />
        ),
      }),
      columnHelper.accessor('scores.attributeScores.address', {
        header: 'Address score',
        minSize: 20,
        cell: (props) => <ScoreCell cellContext={props} labels={ADDRESS_SCORE_RANGES} />,
      })
    );
  }
  return definedColumns;
}

type FetchParams = {
  search?: Omit<SourceIdentityRecordSearch, 'sourceId'>;
  pagination: PaginationState;
  sorting: SortingState;
};

function sortingStateToSortParam(sortingState: SortingState) {
  return sortingState.map((s) => `${s.id.replaceAll('_', '.')},${s.desc ? 'desc' : 'asc'}`);
}

function storeColumnVisibility(visibility: VisibilityState, tenantId = 'unknown') {
  const { select, ...rest } = visibility;
  LocalStorageService.setItem(`seviin.${tenantId}.dora.columns`, JSON.stringify(rest));
}

function getColumnVisibilityFromStorage(tenantId = 'unknown'): VisibilityState {
  const storedValue = LocalStorageService.getItem(`seviin.${tenantId}.dora.columns`);

  try {
    let parsedJson;
    if (storedValue != null) {
      parsedJson = JSON.parse(storedValue);
    }

    return parsedJson ?? defaultColumnVisibility;
  } catch (err) {
    return defaultColumnVisibility;
  }
}

export default function SourceSystemRecordExplorer() {
  const { tenantId } = useAuth();
  const { dataSource } = useCurrentDataSource();
  const [searchParams] = useSearchParams();
  const [showReportView, setShowReportView] = useState(
    searchParams.get('reportView')?.toLowerCase() === 'true'
  );
  const [recordSearch, setRecordSearch] = useState<SourceSystemRecordFilterFormChangeEvent>();
  const [fetchParams, setFetchParams] = useState<FetchParams>({
    search: urlParamsToSearch(searchParams),
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    sorting: [{ id: 'identityRecord_pin', desc: true }],
  });

  const flags = useFlags([FeatureFlags.SHOW_DORA_REPORT_VIEW]);

  useEffect(() => {
    if (searchParams.get('reportView')?.toLowerCase() !== 'true') {
      setShowReportView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const search: any = useMemo(
    () => (fetchParams.search ? { ...fetchParams.search, sourceId: dataSource.id } : undefined),
    [fetchParams, dataSource]
  );

  const { data, loading, fetch } = useSearchIdentityRecords(
    search,
    {
      pagination: {
        pageSize: fetchParams.pagination.pageSize,
        pageNumber: fetchParams.pagination.pageIndex,
      },
      sort: sortingStateToSortParam(fetchParams.sorting),
    },
    !showReportView
  );
  const [modifiedData, setModifiedData] = useState<Page<SourceIdentityRecordSearchResponse>>();

  const onFetcherParamChange = useCallback(
    (params: FetchParams) => {
      setModifiedData(undefined);
      setFetchParams({ search: fetchParams.search, ...params });
    },
    [fetchParams.search]
  );

  const definedColumns = useMemo(
    () => getDefinedColumns(dataSource.attributeMappings),
    [dataSource.attributeMappings]
  );

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    select: !!fetchParams.search?.duplicates,
    ...getColumnVisibilityFromStorage(tenantId),
  });
  const columnVisibilityMemo = useMemo(
    () => ({
      ...columnVisibility,
      select: !!fetchParams.search?.duplicates,
    }),
    [columnVisibility, fetchParams.search?.duplicates]
  );

  const editRecordDisclosure = useDisclosure();
  const editDetailsRecordDisclosure = useDisclosure();

  const [editRecordIndex, setEditRecordIndex] = useState<number>();

  const refetchData = useCallback(async () => {
    await fetch();
  }, [fetch]);

  const reportQuery = mapIdentityRecordSearchToReportQuery(dataSource.id, fetchParams.search!);

  const defaultSortOrder = useMemo(() => {
    if (fetchParams.search?.duplicates) {
      return [{ id: 'identityRecord_pin', desc: false }];
    }
    return fetchParams.sorting;
  }, [fetchParams.search?.duplicates, fetchParams.sorting]);

  if (!dataSource || loading) {
    return <DataExplorerSkeleton />;
  }

  return (
    <Flex data-testid="SourceSystemRecordExplorer" flexDir="column" h="full">
      {!showReportView && (
        <>
          {flags.show_dora_report_view.enabled && (
            <SectionContainer variant="box" mb={4}>
              <HStack>
                <Icon iconImage={IconImage.info} color="highlightDark" />
                <Flex w="full" justify="space-between">
                  <Stack spacing={0}>
                    <Text fontWeight="bold">
                      Use this tool to explore a sample set of data records that meet the filter
                      selections below.
                    </Text>
                    <Text>
                      If you’d like to access the full set of data records, you can opt to load it
                      here or export a CSV.
                    </Text>
                  </Stack>
                  <HStack>
                    <Button variant="outline" onClick={() => setShowReportView(true)}>
                      Load all records
                    </Button>
                    <ExportCsvButton reportParams={reportQuery} />
                  </HStack>
                </Flex>
              </HStack>
            </SectionContainer>
          )}
          <DataExplorer
            data={modifiedData ?? data}
            columns={definedColumns}
            groupColumnId={fetchParams.search?.duplicates ? 'identityRecord_pin' : undefined}
            filterDescriptions={recordSearch?.descriptions}
            pageUrl={recordSearch?.pageUrl}
            defaultColumnVisibility={columnVisibilityMemo}
            defaultSortOrder={defaultSortOrder}
            defaultPagination={fetchParams.pagination}
            onFetcherParamChange={onFetcherParamChange}
            onRowSelected={(e) => {
              setEditRecordIndex(e.index);
              editRecordDisclosure.onOpen();
            }}
            onRowSelectedDataDetails={(e) => {
              setEditRecordIndex(e.index);
              editDetailsRecordDisclosure.onOpen();
            }}
            onColumnVisibilityChange={(visibility) => {
              setColumnVisibility(visibility);
              storeColumnVisibility(visibility, tenantId);
            }}
            icon={{
              label: 'data records',
            }}
            footerActions={
              <SplitDuplicatesFooterAction
                onDupesFlagged={async () => {
                  await refetchData();
                }}
              />
            }
          >
            <SourceSystemRecordExplorerFilterDrawer
              onFilterChange={(e) => {
                setRecordSearch(e);
                setFetchParams({
                  pagination: { pageIndex: 0, pageSize: 100 },
                  search: e.data,
                  sorting: e.data.duplicates
                    ? [{ id: 'identityRecord_pin', desc: false }]
                    : fetchParams.sorting,
                });
              }}
            />
          </DataExplorer>
        </>
      )}

      <EditRecordModal
        isOpen={editRecordDisclosure.isOpen}
        onClose={() => editRecordDisclosure.onClose()}
        data={
          isUndefined(editRecordIndex)
            ? undefined
            : (modifiedData ?? data)?.content?.[editRecordIndex]
        }
        onRowValueChanged={(editedRecord) => {
          const d = modifiedData ?? data;
          const content = d?.content ? [...d.content] : [];
          if (!isUndefined(editRecordIndex)) {
            content[editRecordIndex] = editedRecord;
          }
          if (d) {
            setModifiedData({ ...d, content });
          }
        }}
      />
      <DataRecordDetails
        isOpen={editDetailsRecordDisclosure.isOpen}
        onClose={() => editDetailsRecordDisclosure.onClose()}
        editRecordModalOpen={editRecordDisclosure.onOpen}
        editRecordModalClose={editDetailsRecordDisclosure.onClose}
        data={
          isUndefined(editRecordIndex)
            ? undefined
            : (modifiedData ?? data)?.content?.[editRecordIndex]
        }
      />

      {showReportView && (
        <ReportExplorer
          reportId="IDENTITY_RECORDS"
          reportParams={reportQuery}
          filterLabel="Selected filters"
          filterDescriptions={recordSearch?.descriptions}
          dataLabel="data records"
          hasSecondaryExport
        >
          <SourceSystemRecordExplorerFilterDrawer
            onFilterChange={(e) => {
              let { pageUrl } = e;
              if (!pageUrl?.includes('reportView')) {
                if (pageUrl?.includes('?')) {
                  pageUrl = `${pageUrl}&reportView=true`;
                } else if (showReportView) {
                  pageUrl = `${pageUrl}?reportView=true`;
                }
                window.history.replaceState(null, '', pageUrl);
              }
              setRecordSearch(e);
              setFetchParams({
                ...fetchParams,
                search: e.data,
                sorting: e.data.duplicates
                  ? [{ id: 'identityRecord_pin', desc: false }]
                  : fetchParams.sorting,
              });
            }}
          />
        </ReportExplorer>
      )}
    </Flex>
  );
}
