import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import DataMappingSetupSection from '../DataMappingSetupSection';
import ExternalConfigurationSection from '../ExternalConfigurationSection';
import SetupConnectionGrid from '../SetupConnectionGrid';
import SourceConfigAttributesSection from '../SourceConfigAttributesSection';
import { useRemoteIngestion } from '../useRemoteIngestion';

interface IntegrationSetupProps {
  onError: () => void;
}

export default function IntegrationSetup({ onError }: IntegrationSetupProps) {
  const { dataSource: source, refetchDataSource } = useCurrentDataSource();
  useRemoteIngestion(source);

  const isNativeConfiguration = source.requiresInternalConfiguration;

  const isConfigured = source.configured;

  return (
    <SetupConnectionGrid>
      {source.requiresExternalConfiguration && (
        <ExternalConfigurationSection
          source={source}
          onComplete={refetchDataSource}
          onError={onError}
        />
      )}
      {isNativeConfiguration && <SourceConfigAttributesSection />}
      <DataMappingSetupSection isDisabled={!isConfigured} />
    </SetupConnectionGrid>
  );
}
