import {
  Box,
  BoxProps,
  chakra,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useDisclosure,
  TabList,
  Flex,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from '@chakra-ui/react';
import consola from 'consola';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import PrimaryIdentityFieldBadge from '../../../components/core/badges/PrimaryIdentityFieldBadge/PrimaryIdentityFieldBadge';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { Can } from '../../../context/AuthorizationContext';
import { useToast } from '../../../hooks/use-toast';
import IdentityClient from '../../../lib/api-client/identity/IdentityClient';
import {
  useGetIdentityByPin,
  useGetIdentityTopPin,
} from '../../../lib/api-client/identity/IdentityData';
import {
  IdentityAttribute,
  IdentityAttributeValue,
  PrimaryIdentityAttributeValue,
  ProfileTags,
} from '../../../lib/api-client/identity/model/Identity';
import { Nullable } from '../../../lib/model/common';
import { formatDateString } from '../../../lib/utils/date-time-utils';
import { isHttpError } from '../../../lib/utils/error-utils';
import ProfileDataRecordsPage from '../ProfileDataRecordsPage/ProfileDataRecordsPage';
import { ProfilePageLinkState } from '../ProfilePage/ProfilePage';
import PrimaryValueChangeConfirmationModal from '../components/PrimaryValueChangeConfirmationModal/PrimaryValueChangeConfirmationModal';
import ProfileAttributeScoreBadge from '../components/ProfileAttributeScoreBadge/ProfileAttributeScoreBadge';
import ProfileCompletenessBadge from '../components/ProfileCompletenessBadge/ProfileCompletenessBadge';
import ProfileFeedbackButton from '../components/ProfileFeedbackButton/ProfileFeedbackButton';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';

function IdentityTooltip({ primaryValue }: { primaryValue: PrimaryIdentityAttributeValue }) {
  if (primaryValue.overridden) {
    return (
      <chakra.span>{`This primary data field value was set by ${primaryValue.overriddenBy} on
        ${
          primaryValue.overriddenAt
            ? formatDateString(primaryValue.overriddenAt, 'MMM d, yyyy')
            : ''
        }.`}</chakra.span>
    );
  }
  return <>This primary data field value was set by harpin AI machine learning model.</>;
}

function IdentityAttributeActions({
  pin,
  attribute,
  componentAttribute,
  attributeLabel,
  value,
  oldValue,
}: {
  pin: string;
  value: string;
  attribute: string;
  componentAttribute: string;
  attributeLabel: string;
  oldValue?: string;
}) {
  const confirmationDisclosure = useDisclosure();
  const toast = useToast();
  const { refetch } = useGetIdentityByPin(pin);

  const onConfirmation = async () => {
    try {
      await IdentityClient.updatePrimaryValueOverride(pin, attribute, value);
      toast({ description: 'Primary value updated successfully!' });
      await refetch();
    } catch (err) {
      consola.error(err);
      toast({
        description: `There was an error updating the ${attributeLabel} primary value.`,
      });
    }
  };

  return (
    <Menu placement="bottom-end">
      <MenuButton
        aria-label="View profile data records"
        as={IconButton}
        variant="ghost"
        icon={<Icon iconImage={IconImage.moreAlt} />}
        color="action"
        boxSize={4}
      />
      <MenuList fontSize="sm">
        <MenuItem
          as={Link}
          to={`/profiles/${pin}/identities?tab=source-data`}
          state={{
            filters: {
              componentAttribute,
              value,
            },
          }}
        >
          See source data records
        </MenuItem>
        {oldValue != null && (
          <Can I="update" an="ConsumerProfile">
            <MenuItem onClick={confirmationDisclosure.onOpen}>Set as primary field value</MenuItem>
          </Can>
        )}
        <PrimaryValueChangeConfirmationModal
          field={{
            fieldLabel: attributeLabel,
            newValue: value,
            oldValue: oldValue ?? value,
          }}
          onConfirmation={onConfirmation}
          isOpen={confirmationDisclosure.isOpen}
          onClose={confirmationDisclosure.onClose}
        />
      </MenuList>
    </Menu>
  );
}

type IdentityAttributeFieldProps = {
  label: string;
  attribute: string;
  value: Nullable<IdentityAttribute>;
  pin: string;
};
function IdentityAttributeField({
  label,
  attribute,
  value,
  pin,
  ...rest
}: IdentityAttributeFieldProps & BoxProps) {
  let format: ((value: any) => any) | undefined;
  if (attribute === 'phone') {
    format = (attrValue: IdentityAttributeValue | undefined) => {
      if (!attrValue) {
        return undefined;
      }

      return {
        value: `(${attrValue.components[0]?.type.substring(0, 1)}) ${attrValue.value}`,
        attribute: attrValue.components[0]?.type,
      };
    };
  }

  let formattedPrimary;
  if (format) {
    formattedPrimary = format(value?.primaryValue);
  }

  return (
    <SectionContainer
      variant="box"
      header={
        <HStack justifyContent="space-between" fontSize="sm" fontWeight="semibold" p={3}>
          <chakra.span color="gray.800">{label}</chakra.span>
          <chakra.span fontSize="xs" color="gray.600">
            {value ? value.otherValues.length + 1 : 0} total on record
          </chakra.span>
        </HStack>
      }
      titleMargin="0"
      fontSize="sm"
      p={0}
      {...rest}
    >
      {!value ? (
        <Text p={3}>Not on record</Text>
      ) : (
        <Stack p={0}>
          <HStack p={3} bgColor="highlightHover" justifyContent="space-between">
            <chakra.span w="300px">
              {formattedPrimary?.value ?? value.primaryValue.value}
            </chakra.span>
            ;
            <HStack>
              <Tooltip
                hasArrow
                label={<IdentityTooltip primaryValue={value.primaryValue} />}
                placement="top"
                shouldWrapChildren
              >
                <PrimaryIdentityFieldBadge />
              </Tooltip>
              <ProfileAttributeScoreBadge
                attribute={formattedPrimary?.attribute ?? attribute}
                attributeValue={value.primaryValue}
              />
              <IdentityAttributeActions
                attribute={attribute}
                componentAttribute={formattedPrimary?.attribute ?? attribute}
                attributeLabel={label.toLowerCase()}
                value={value.primaryValue.value}
                pin={pin}
              />
            </HStack>
          </HStack>
          {value.otherValues.map((attributeValue) => {
            let formattedOther;
            if (format) {
              formattedOther = format(attributeValue);
            }
            return (
              <HStack
                key={formattedOther?.value ?? attributeValue.value}
                p={3}
                justifyContent="space-between"
              >
                <chakra.span>{formattedOther?.value ?? attributeValue.value}</chakra.span>
                <HStack>
                  <ProfileAttributeScoreBadge
                    attribute={formattedOther?.attribute ?? attribute}
                    attributeValue={attributeValue}
                  />
                  <IdentityAttributeActions
                    attribute={attribute}
                    componentAttribute={formattedOther?.attribute ?? attribute}
                    attributeLabel={label.toLowerCase()}
                    oldValue={value.primaryValue.value}
                    value={attributeValue.value}
                    pin={pin}
                  />
                </HStack>
              </HStack>
            );
          })}
        </Stack>
      )}
    </SectionContainer>
  );
}

function ProfilePageAllIdentityDataPage() {
  const { id = '' } = useParams();
  const location = useLocation();
  const state = location.state as ProfilePageLinkState;

  const {
    data: identity,
    loading: identityLoading,
    error: identityError,
  } = useGetIdentityByPin(id, state?.identity);
  const [isLoading, setLoading] = useBoolean(true);
  const [initializationError, setInitializationError] = useState(false);
  const [pinTag, setPinTag] = useState<ProfileTags[]>();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('tab');
  const [activeTab, setActiveTab] = useState(activeTabParam === 'source-data' ? 1 : 0);
  const { data: topPinidentity } = useGetIdentityTopPin(id);

  useEffect(() => {
    setActiveTab(activeTabParam === 'source-data' ? 1 : 0);
  }, [activeTabParam, location]);

  useEffect(() => {
    const fetchData = async () => {
      let identityRecordsByPinTag;
      try {
        identityRecordsByPinTag = await IdentityClient.getIdentityRecordsByPinTag(id);
        setPinTag(identityRecordsByPinTag);
      } catch (err) {
        if (isHttpError(err, 404)) {
          navigate('/404', { replace: true });
          return;
        }
        setInitializationError(true);
        return;
      }

      setLoading.off();
    };

    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  if (identityError && isHttpError(identityError, 404)) {
    navigate('/404', { replace: true });
    return null;
  }
  const pinTagValue = pinTag?.find((e) => e.tagKey === 'authenticity');

  return (
    <PageLayout
      pageViewEvent={{ page: 'Profile Page All Identity Data', pageId: id }}
      data-testid="ProfilePageAllIdentityDataPage"
      header={
        <Header
          icon={<Icon iconImage={IconImage.searchUser} />}
          title="All consumer data"
          back={{
            label: 'Back to consumer profile',
            to: `/profiles/${id}`,
          }}
        />
      }
      initializationError={initializationError}
      loading={isLoading && identityLoading}
    >
      {identity && (
        <HStack display="flex" justifyContent="space-between" alignItems="start" pb={6}>
          <ProfileHeader
            identity={identity}
            authenticityTag={pinTagValue}
            similarProfile={topPinidentity}
          />
          <ProfileFeedbackButton pin={identity.pin} />
        </HStack>
      )}
      <Box w="full">
        {identity && (
          <Tabs
            variant="line"
            colorScheme="#005874"
            mb={6}
            index={activeTab}
            onChange={(index) => setActiveTab(index)}
          >
            <TabList>
              <HStack w="full" justifyContent="space-between">
                <Flex>
                  <Tab>
                    <chakra.span>All identity data </chakra.span>
                  </Tab>
                  <Tab> All source data records</Tab>
                </Flex>
                <ProfileCompletenessBadge completeness={identity.completeness} />
              </HStack>
            </TabList>
            <HStack alignItems="start">
              <TabPanels pt={6}>
                <TabPanel p={0}>
                  <SimpleGrid columns={2} gap={4}>
                    <Stack w="full" spacing={4}>
                      <IdentityAttributeField
                        label="Full name"
                        attribute="name"
                        value={identity.identityAttributes.name}
                        pin={identity.pin}
                        data-testid="IdentityDataField_FullName"
                      />
                      <IdentityAttributeField
                        label="Phone number"
                        attribute="phone"
                        value={identity.identityAttributes.phone}
                        pin={identity.pin}
                        data-testid="IdentityDataField_PhoneNumber"
                      />
                    </Stack>
                    <Stack w="full" spacing={4}>
                      <IdentityAttributeField
                        label="Email address"
                        attribute="emailAddress"
                        value={identity.identityAttributes.emailAddress}
                        pin={identity.pin}
                        data-testid="IdentityDataField_EmailAddress"
                      />
                      <IdentityAttributeField
                        label="Date of birth"
                        attribute="dateOfBirth"
                        value={identity.identityAttributes.dateOfBirth}
                        pin={identity.pin}
                        data-testid="IdentityDataField_DateOfBirth"
                      />
                      <IdentityAttributeField
                        label=" Address"
                        attribute="address"
                        value={identity.identityAttributes.address}
                        pin={identity.pin}
                        data-testid="IdentityDataField_MailingAddress"
                      />
                    </Stack>
                  </SimpleGrid>
                </TabPanel>
                <TabPanel p={0}>
                  <ProfileDataRecordsPage />
                </TabPanel>
              </TabPanels>
            </HStack>
          </Tabs>
        )}
      </Box>
    </PageLayout>
  );
}

export default ProfilePageAllIdentityDataPage;
