import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAxiosError } from 'axios';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useToast } from '../../../hooks/use-toast';
import AdminTenantClient from '../../../lib/api-client/tenant/AdminTenantClient';

const createTenantFormSchema = yup.object({
  id: yup
    .string()
    .required('Tenant ID is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Tenant ID can only be letters and numbers')
    .max(16),
  name: yup.string().required('Tenant name is required'),
  emailDomains: yup
    .array()
    .of(
      yup
        .string()
        .matches(/^(?!-)[A-Za-z0-9-]+([-.][a-z0-9]+)*\.[A-Za-z]{2,32}$/, 'Must be a valid domain')
    )
    .min(1, 'At least one email domain is required'),
  type: yup.string().oneOf(['sandbox', 'production']),
});

interface CreateTenantForm {
  id: string;
  name: string;
  emailDomains: string[];
  type: 'sandbox' | 'production';
}

export default function CreateTenantPage() {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateTenantForm>({
    resolver: yupResolver(createTenantFormSchema),
    mode: 'onBlur',
    defaultValues: {
      type: 'sandbox',
    },
  });
  const toast = useToast();

  const watchedType = watch('type');

  const { fields, append } = useFieldArray({ control, name: 'emailDomains' } as any);
  const navigate = useNavigate();

  const onSubmit = async (data: CreateTenantForm) => {
    try {
      await AdminTenantClient.createTenant({
        name: data.name,
        id: data.id,
        emailDomains: data.emailDomains,
        thirdPartyDataUsage: 'NONE',
        dataNetwork: data.type === 'production' ? 'MAIN' : `SBX_${data.id.toUpperCase()}`,
        properties:
          data.type === 'production'
            ? {}
            : {
                disableAddressValidation: 'true',
              },
      });
      toast({
        status: 'success',
        title: 'Tenant created successfully!',
        description: `Login with your user credentials to switch to your new tenant - ${data.name}.`,
      });
      navigate(`/harpin-tools/switch-tenant?tenantId=${data.id}`);
    } catch (e) {
      let description;
      if (isAxiosError(e)) {
        description = e.response?.data?.message;
      }

      toast({
        status: 'error',
        title: 'There was a problem creating the tenant.',
        description,
      });
    }
  };

  return (
    <PageLayout
      pageViewEvent={{ page: 'Create new tenant' }}
      header={
        <Header
          icon={<Icon iconImage={IconImage.settings} />}
          title="Create new tenant"
          back={{ label: 'Back to harpin tools', to: '/harpin-tools' }}
        />
      }
      data-testid="CreateTenantPage"
    >
      <chakra.form onSubmit={handleSubmit(onSubmit)} w="500px">
        <Stack spacing={5} pb={10}>
          <FormControl isInvalid={!!errors.type}>
            <FormLabel>Type</FormLabel>
            <Select {...register('type')}>
              <option value="sandbox">Sandbox</option>
              <option value="production">Production</option>
            </Select>
            {watchedType === 'sandbox' && (
              <FormHelperText>
                Sandbox creates a scratch tenant that can be used for testing and demoing.
              </FormHelperText>
            )}
            {watchedType === 'production' && (
              <FormHelperText>Production should only be used for live customers.</FormHelperText>
            )}
            <FormErrorMessage>{errors?.type?.type}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.id}>
            <FormLabel>Tenant ID</FormLabel>
            <Input {...register('id')} />
            <FormErrorMessage>{errors?.id?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Name</FormLabel>
            <Input {...register('name')} />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.emailDomains}>
            <FormLabel>Email domains</FormLabel>
            <Stack>
              <>
                {fields.map((field, index) => (
                  <FormControl key={field.id} isInvalid={!!errors.emailDomains?.[index]}>
                    <Input {...register(`emailDomains.${index}`)} />
                    <FormErrorMessage>{errors?.emailDomains?.[index]?.message}</FormErrorMessage>
                  </FormControl>
                ))}
              </>
              <Box>
                <Button variant="outline" onClick={() => append('')} size="xs">
                  Add a domain
                </Button>
              </Box>
            </Stack>
            <FormErrorMessage>{errors?.emailDomains?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Button type="submit" isLoading={isSubmitting}>
          Create tenant
        </Button>
        <Text pt={3} fontSize="sm">
          NOTE: Clicking create tenant will create your tenant and redirect you to the switch tenant
          page, so that you can login to your new tenant.
        </Text>
      </chakra.form>
    </PageLayout>
  );
}
