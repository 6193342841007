import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import consola from 'consola';
import { PropsWithChildren } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useToast } from '../../../../../hooks/use-toast';
import SourceClient from '../../../../../lib/api-client/sources/SourceClient';
import { ConfigAttribute } from '../../../../../lib/api-client/sources/model/SourceTypes';
import { SourceSystemConfigAttribute } from '../../../../../lib/api-client/sources/sources.model';
import { createFormSchema } from './CredentialConfigForm.utils';

export default function ConfigAttributeForm({
  configAttributesConfig,
}: Pick<ConfigAttributeFormContextProps, 'configAttributesConfig'>) {
  const form = useFormContext();

  return (
    <Stack spacing="4">
      {configAttributesConfig.map((config, index) => {
        const error = form.formState?.errors?.data?.[index]?.value;
        return (
          <FormControl key={config.name} isInvalid={!!error}>
            <FormLabel>{config.label}</FormLabel>
            <Input
              {...form.register(`data.${index}.value`)}
              type={config.dataType === 'STRING' ? 'text' : 'password'}
            />
            {config.description && <FormHelperText>{config.description}</FormHelperText>}
            {error && <FormErrorMessage>{error?.message as any}</FormErrorMessage>}
          </FormControl>
        );
      })}
    </Stack>
  );
}

interface ConfigAttributeFormContextProps extends PropsWithChildren {
  sourceId: string;
  configAttributes: SourceSystemConfigAttribute[];
  configAttributesConfig: ConfigAttribute[];
  onSuccess: () => void;
}

interface Form {
  data: SourceSystemConfigAttribute[];
}

export function ConfigAttributeFormContext({
  sourceId,
  configAttributes = [],
  configAttributesConfig,
  onSuccess,
  children,
}: ConfigAttributeFormContextProps) {
  const toast = useToast();
  const form = useForm<Form>({
    resolver: yupResolver(createFormSchema(configAttributesConfig)),
    mode: 'onBlur',
    defaultValues: {
      data: configAttributes,
    },
  });

  const onSubmit = async (data: Form) => {
    try {
      const filteredValues = data.data.filter((attribute) => {
        const found = configAttributesConfig.find((c) => c.name === attribute.name);
        if (!found) {
          return false;
        }

        return (
          typeof attribute.value === 'boolean' ||
          (attribute.value ?? '').trim().length > 0 ||
          found.required
        );
      });
      await SourceClient.updateSourceSystemConfigurationAttributes(sourceId, filteredValues);
      onSuccess();
    } catch (err) {
      consola.error('Error updating source system configuration attributes', err);
      toast({ status: 'error', description: 'There was a problem updating the configuration' });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
}
