import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Tooltip,
  Text,
  useDisclosure,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { profileCompletenessColorStrategyCssVar } from '../../../../lib/utils/color-utils';
import { formatNumber } from '../../../../lib/utils/number-utils';

export default function SimilarProfile({
  similarProfile,
  pin,
}: {
  similarProfile: any;
  pin: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ChakraFaCircle = chakra(FaCircle);

  return (
    <>
      <Tooltip
        hasArrow
        placement="top"
        label={`${
          similarProfile ? similarProfile.length - 1 : 0
        } other profiles with similar identity data have been identified. Click for more detail.`}
      >
        <Tag
          onClick={onOpen}
          borderRadius="full"
          colorScheme="highlightScheme"
          fontSize="xs"
          py={0.5}
          px={2}
          minH={5}
        >
          <Icon boxSize={4} iconImage={IconImage.connected} mr={0.5} />
          <Text mr={1}>{similarProfile ? similarProfile.length - 1 : 0} </Text>
          <TagLabel>
            <chakra.span fontWeight="normal">similar profiles</chakra.span>
          </TagLabel>
        </Tag>
      </Tooltip>
      <Modal isCentered size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Similar profiles</ModalHeader>
          <ModalCloseButton top={3} />
          <ModalBody>
            <Text>The following profiles were identified as having similar identity data.</Text>
            <Divider mt={2} color="gray.300" borderWidth="thin" />
            {similarProfile &&
              similarProfile.map((item: any) => (
                <>
                  <HStack key={item.identityAttributes} mb={2} spacing={8} mt={3}>
                    <Text w="160px">PIN: {item.pin}</Text>
                    {item.tags.map((authenticityTag: any) => (
                      <HStack key={authenticityTag?.score} w="200px" spacing={1}>
                        <ChakraFaCircle
                          color={profileCompletenessColorStrategyCssVar(
                            authenticityTag?.score ?? 0
                          )}
                          width="3"
                        />
                        <Text fontWeight="bold">
                          {formatNumber(authenticityTag.score * 100, {
                            useGrouping: false,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                        <Text>{authenticityTag.value} profile</Text>
                      </HStack>
                    ))}
                    <Text w="200px">
                      {item.identityAttributes?.name?.primaryValue?.value ?? ''}
                    </Text>
                    <Button
                      w="140px"
                      onClick={onOpen}
                      variant="outline"
                      to={`/profiles/${item.pin}`}
                      as={Link}
                      target="_blank"
                      isDisabled={pin === item.pin}
                    >
                      {pin === item.pin ? ' Current profile' : ' See profile'}
                    </Button>
                  </HStack>
                  <Divider color="gray.300" borderWidth="thin" />
                </>
              ))}
          </ModalBody>
          <ModalFooter justifyContent="start">
            <Button onClick={onClose} variant="outline">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
