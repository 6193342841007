/* eslint-disable no-nested-ternary */
import { HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import {
  useGetSourceSystemConfigAttributes,
  useGetSourceSystemType,
} from '../../../lib/api-client/sources/SourceData';
import {
  ConfigAttribute,
  ConfigStep,
  SourceSystemType,
} from '../../../lib/api-client/sources/model/SourceTypes';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';
import { ConfigAttributeForm, ConfigAttributeFormContext } from './ConfigAttributeForm';

function CustomTab({
  isCompleted,
  isDisabled,
  isSelected,
  pageConfig,
  configAttributesData,
  selectRefetch,
  children,
  tabIndex,
  selectedValueItem,
  ...props
}: any) {
  const requiredState = pageConfig.attributes
    .filter(
      (config: any) =>
        config.required === true &&
        (config.conditions.length === 0 ||
          config.conditions.some((item: any) => item.value === selectedValueItem))
    )
    .map((item: any) => item.name);

  const badgeState =
    configAttributesData?.length > 0 &&
    requiredState.every((req: any) => {
      const found = configAttributesData.find((c: any) => c.name === req);
      if (found) {
        if (typeof found.value === 'boolean') {
          return true;
        }
        return !!found.value;
      }
      return false;
    });

  const isClickable = !isDisabled || badgeState;
  const handleClick = () => {
    if (isClickable && selectRefetch) {
      selectRefetch();
    }
  };

  return (
    <Tab
      _selected={{
        border: '2px',
        borderColor: 'success',
        bgColor: 'white',
      }}
      pt={1}
      pb={1}
      pl={1}
      pr={2}
      fontSize="14"
      fontWeight="400"
      color={isSelected ? 'gray.800' : isCompleted || badgeState ? 'white' : 'gray.800'}
      border={isSelected || badgeState ? '2px' : 'none'}
      borderColor={isSelected || badgeState ? 'success' : 'none'}
      bgColor={isCompleted || badgeState ? 'success' : 'gray.200'}
      borderRadius="26px"
      cursor={isClickable ? 'pointer' : 'not-allowed'}
      opacity={isClickable ? 1 : 0.5}
      pointerEvents={isClickable ? 'auto' : 'none'}
      onClick={handleClick}
      {...props}
    >
      <Icon
        iconImage={IconImage.success}
        color={isSelected ? 'gray.400' : badgeState ? 'white' : 'gray.400'}
      />
      {children}
    </Tab>
  );
}

type StepConfigAttributeMap = Record<string, ConfigAttribute[]>;
type PageConfig = { step: ConfigStep; attributes: ConfigAttribute[] }[];

function toPageConfig(sourceSystemType: SourceSystemType): PageConfig {
  const stepAttributeMap = (sourceSystemType.configAttributes ?? []).reduce<StepConfigAttributeMap>(
    (previous, current) => {
      if (current.configStep in previous) {
        previous[current.configStep].push(current);
      } else {
        // eslint-disable-next-line no-param-reassign
        previous[current.configStep] = [current];
      }
      return previous;
    },
    {}
  );

  return (sourceSystemType.configSteps ?? []).map((step) => ({
    step,
    attributes: stepAttributeMap[step.name] ?? [],
  }));
}
type SubmittingMessage = { success: boolean; message: string } | null;

function ConnectionConfigurationPage() {
  const { dataSource } = useCurrentDataSource();
  const { data: sourceTypeData, loading } = useGetSourceSystemType(dataSource.sourceSystem ?? '');
  const { data: configAttributesData, refetch: refetchConfigAttributesData } =
    useGetSourceSystemConfigAttributes(dataSource.id);
  const [tabIndex, setTabIndex] = useState(0);
  const [completedTabs, setCompletedTabs] = useState<boolean[]>([false, false, false]);
  const navigate = useNavigate();

  const [isSubmittingMessage, setIsSubmittingMessage] = useState<SubmittingMessage>(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectRefetch, setSelectRefetch] = useState(0);

  if (sourceTypeData == null || configAttributesData == null) {
    // TODO some sort of loading state maybe?
    return null;
  }

  const pageConfigs = toPageConfig(sourceTypeData);

  const handleNext = async () => {
    await refetchConfigAttributesData();
    setCompletedTabs((prev) => {
      const newCompletedTabs = [...prev];
      newCompletedTabs[tabIndex] = true;
      return newCompletedTabs;
    });
    setIsSubmittingMessage(null);
    setSelectRefetch(tabIndex);

    if (tabIndex === pageConfigs.length - 1) {
      navigate(`/sources/${dataSource.id}/manage`);
    } else {
      setSelectRefetch(tabIndex + 1);
      setTabIndex((prevIndex) => Math.min(pageConfigs.length - 1, prevIndex + 1));
    }
  };

  const handleTabChange = async (index: number) => {
    setSelectRefetch(index);
    await refetchConfigAttributesData();

    const requiredState = pageConfigs[index].attributes
      .filter((config: any) => config.required)
      .map((item: any) => item.name);

    const badgeState =
      configAttributesData?.length > 0 &&
      requiredState.every((req: any) => {
        const found = configAttributesData.find((c: any) => c.name === req);
        if (found) {
          if (typeof found.value === 'boolean') {
            return true;
          }
          return !!found.value;
        }
        return false;
      });

    if (completedTabs[index] || index < tabIndex || badgeState) {
      setIsSubmittingMessage(null);
      setTabIndex(index);
      setSelectRefetch(index);
      if (index > 0) {
        setCompletedTabs((prev) => {
          const newCompletedTabs = [...prev];
          newCompletedTabs[index - 1] = true;
          return newCompletedTabs;
        });
      }
    }
  };

  return (
    <PageLayout
      loading={loading}
      pageViewEvent={{ page: 'Connection configuration' }}
      header={
        <Header
          title={`${dataSource.name} connection configuration`}
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          back={{
            label: 'Back to source connections dashboard',
            to: `/sources/${dataSource.id}/manage`,
          }}
        />
      }
    >
      <Tabs
        variant="soft-rounded"
        borderColor="red"
        colorScheme="whiteScheme"
        index={tabIndex}
        onChange={handleTabChange}
        _disabled={{ cursor: 'not-allowed' }}
      >
        <TabList>
          {pageConfigs.map((pageConfig, index) => (
            <HStack spacing={0} key={pageConfig.step.name}>
              <CustomTab
                pageConfig={pageConfig}
                configAttributesData={configAttributesData}
                isCompleted={completedTabs[index]}
                isSelected={tabIndex === index}
                tabIndex={index}
                isDisabled={!completedTabs[index] && tabIndex < index}
                selectedValueItem={selectedValue}
                selectRefetch={tabIndex}
              >
                {pageConfig.step.label}
              </CustomTab>
              {index < pageConfigs.length - 1 && (
                <Text
                  border="2px"
                  w="18px"
                  borderColor={completedTabs[index] ? 'success' : 'gray.300'}
                >
                  {' '}
                </Text>
              )}
            </HStack>
          ))}
        </TabList>
        <TabPanels>
          {pageConfigs.map((pageConfig) => {
            const defaultConfigAttributes = pageConfig.attributes.map((configAttribute) => {
              const found = configAttributesData.find((c) => c.name === configAttribute.name);
              return (
                found ?? {
                  name: configAttribute.name,
                  value: configAttribute.dataType === 'BOOLEAN' ? false : '',
                }
              );
            });
            return (
              <TabPanel pl={-4} key={pageConfig.step.name} w="full">
                <ConfigAttributeFormContext
                  step={pageConfig.step.name}
                  configAttributesConfig={pageConfig.attributes}
                  sourceId={dataSource.id}
                  onSuccess={() => handleNext()}
                  configAttributes={defaultConfigAttributes}
                  setIsSubmittingMessage={setIsSubmittingMessage}
                >
                  <ConfigAttributeForm
                    isSubmittingMessage={isSubmittingMessage}
                    configAttributesConfig={pageConfig.attributes}
                    actions={pageConfig.step.actionsSupported}
                    savedConfigAttributes={configAttributesData}
                    setSelectedValue={setSelectedValue}
                    selectRefetch={selectRefetch}
                  />
                </ConfigAttributeFormContext>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}

export default ConnectionConfigurationPage;
