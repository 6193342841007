import { chakra, HStack } from '@chakra-ui/react';
import React from 'react';
import { FaCircle } from 'react-icons/fa';

const ChakraFaCircle = chakra(FaCircle);

type ConnectionStatus = 'enabled' | 'disabled';

type SourceConnectionStatusIndicatorProps = {
  status: ConnectionStatus;
};

export default function SourceConnectionStatusIndicator({
  status,
}: SourceConnectionStatusIndicatorProps) {
  let statusLabel;
  let statusColor;
  switch (status) {
    case 'enabled':
      statusColor = 'success';
      statusLabel = 'Enabled';
      break;
    default:
      statusColor = 'gray.600';
      statusLabel = 'Disabled';
  }

  return (
    <HStack fontSize="sm" spacing={1}>
      <ChakraFaCircle color={statusColor} />
      <chakra.span fontWeight="semibold">{statusLabel}</chakra.span>
    </HStack>
  );
}
