import {
  recordCategoryToLabel,
  SourceIdentityRecordCategory,
} from '../../../../lib/api-client/identity/model/SourceIdentityRecordCategory';
import {
  recordGroupToLabel,
  SourceIdentityRecordGroup,
} from '../../../../lib/api-client/identity/model/SourceIdentityRecordGroup';
import {
  AttributeSearchOperation,
  IdentityRecordSearchAttribute,
  SearchOperator,
  SourceIdentityRecordOptInAttribute,
  SourceIdentityRecordSearch,
} from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';
import { isDefined, isUndefined } from '../../../../lib/utils/utils';
import {
  ADDRESS_SCORE_RANGES,
  EMAIL_SCORE_RANGES,
  NAME_SCORE_RANGES,
  RECORD_SCORE_RANGES,
  VALIDITY_RANGES,
} from '../../../data-explorer/components/SourceSystemRecordExplorer/description-utils';

export type ScoreFilter = {
  range?: string;
  operator?: string;
  value1?: number;
  value2?: number;
};

export type SourceSystemRecordFilterForm = {
  recordCategory: string;
  recordCompleteness: string;
  pinned: string;
  duplicates: string;
  modifications: string;
  recordScore: ScoreFormFilter;
  firstName: string;
  firstNameScore: ScoreFormFilter;
  lastName: string;
  lastNameScore: ScoreFormFilter;
  email: string;
  emailScore: ScoreFormFilter;
  phone: string;
  phoneScore: ScoreFormFilter;
  dobScore: ScoreFormFilter;
  address: string;
  addressScore: ScoreFormFilter;
  optIn: Record<SourceIdentityRecordOptInAttribute['attribute'], string>;
};

export type SourceSystemRecordFilterFormChangeEvent = {
  pageUrl?: string;
  descriptions: string[];
  data: Omit<SourceIdentityRecordSearch, 'sourceId'>;
};

export type ScoreFormFilter = {
  range?: 'verified' | 'nonverified' | 'suspicious' | 'range' | 'suspiciousEmail' | 'all';
  operator?: string;
  value1?: number;
  value2?: number;
};

export function transformFilterToSearchOperation(
  filter: ScoreFormFilter
): Pick<AttributeSearchOperation, 'operator' | 'values'> | undefined {
  switch (filter?.range) {
    case 'verified':
      return { operator: SearchOperator.Equal, values: [1] };
    case 'nonverified':
      return { operator: SearchOperator.Equal, values: [0] };
    case 'suspicious':
      return { operator: SearchOperator.Between, values: [0.01, 0.99] };
    case 'suspiciousEmail':
      return { operator: SearchOperator.EqualOrNull, values: [-1] };
    case 'range':
      return {
        operator: filter.operator as SearchOperator,
        values: [filter.value1, filter.value2].filter((v) => !isUndefined(v)) as number[],
      };
    default:
      return undefined;
  }
}

export function transformFilterToAttributeSearchOperation(
  attribute: IdentityRecordSearchAttribute,
  filter: ScoreFormFilter
) {
  const searchOperation = transformFilterToSearchOperation(filter);
  return searchOperation ? { attribute, ...searchOperation } : undefined;
}

export function transformCompletenessFilterToSearchOperation(
  value: string
): Pick<AttributeSearchOperation, 'operator' | 'values'> | undefined {
  switch (value) {
    case 'complete':
      return { operator: SearchOperator.Equal, values: [1] };
    case 'incomplete':
      return { operator: SearchOperator.Equal, values: [0] };
    default:
      return undefined;
  }
}

export function transformCompletenessFilterToAttributeSearchOperation(
  attribute: IdentityRecordSearchAttribute,
  value: string
) {
  const searchOperation = transformCompletenessFilterToSearchOperation(value);
  return searchOperation ? { attribute, ...searchOperation } : undefined;
}

export function describeAttributeScoreFilter(
  label: string,
  filter: ScoreFormFilter,
  filterValueLabels: { label: string; value: string }[]
) {
  let filterValueLabel = filterValueLabels.find((fvl) => fvl.value === filter.range)?.label;

  const describeManualScoreFilter = (value: ScoreFilter) => {
    switch (value.operator) {
      case SearchOperator.GreaterThan:
        return `> ${value.value1}`;
      case SearchOperator.Equal:
        return `= ${value.value1}`;
      case SearchOperator.LessThan:
        return `< ${value.value1}`;
      case SearchOperator.Between:
        return `between ${value.value1} and ${value.value2}`;
      default:
        return '';
    }
  };

  if (!filterValueLabel) {
    switch (filter.range) {
      case 'suspicious':
        filterValueLabel = 'Questionable';
        break;
      case 'verified':
        filterValueLabel = 'Accurate';
        break;
      case 'nonverified':
        filterValueLabel = 'Inaccurate';
        break;
      case 'range':
        filterValueLabel = describeManualScoreFilter(filter);
        break;
      default:
        filterValueLabel = 'All';
    }
  }

  return `${label}: ${filterValueLabel}`;
}

export function describeAttributeCompletenessFilter(label: string, value: string) {
  let valueLabel;
  switch (value) {
    case 'complete':
      valueLabel = 'Complete';
      break;
    case 'incomplete':
      valueLabel = 'Incomplete';
      break;
    default:
      valueLabel = 'All';
  }
  return `${label}: ${valueLabel}`;
}

export function describePinnedProfileFilter(label: string, value: string) {
  let valueLabel;
  switch (value) {
    case 'pinned':
      valueLabel = 'Pinned';
      break;
    case 'unpinned':
      valueLabel = 'Not pinned';
      break;
    default:
      valueLabel = 'All';
  }
  return `${label}: ${valueLabel}`;
}

export function describeDuplicateProfileFilter(label: string, value: string) {
  let valueLabel;
  switch (value) {
    case 'duplicate':
      valueLabel = 'Duplicates';
      break;
    default:
      valueLabel = 'All';
  }
  return `${label}: ${valueLabel}`;
}

export function describeRecordCategoryFilter(label: string, value: string) {
  let valueLabel = 'All';
  if (Object.values(SourceIdentityRecordCategory).includes(value as any)) {
    valueLabel = recordCategoryToLabel(value as any);
  }
  if (Object.values(SourceIdentityRecordGroup).includes(value as any)) {
    valueLabel = recordGroupToLabel(value as any);
  }
  return `${label}: ${valueLabel}`;
}

export function describeModifiedRecordFilter(label: string, value: string) {
  let valueLabel;
  switch (value) {
    case 'true':
      valueLabel = 'Edited';
      break;
    case 'false':
      valueLabel = 'Not edited';
      break;
    default:
      valueLabel = 'All';
  }
  return `${label}: ${valueLabel}`;
}

export function describeFilters(filterForm: SourceSystemRecordFilterForm): string[] {
  const attributeDescriptions = [
    [
      ['First name score', filterForm.firstNameScore, NAME_SCORE_RANGES],
      ['First name completeness', filterForm.firstName],
    ],
    [
      ['Last name score', filterForm.lastNameScore, NAME_SCORE_RANGES],
      ['Last name completeness', filterForm.lastName],
    ],
    [
      ['Phone number score', filterForm.phoneScore, VALIDITY_RANGES],
      ['Phone number completeness', filterForm.phone],
    ],
    [
      ['Email address score', filterForm.emailScore, EMAIL_SCORE_RANGES],
      ['Email address completeness', filterForm.email],
    ],
    [['Date of birth score', filterForm.dobScore, VALIDITY_RANGES]],
    [['Address score', filterForm.addressScore, ADDRESS_SCORE_RANGES]],
  ].flatMap(([score, completeness]: any) => {
    const descriptions = [];

    if (score[1] && score[1].range !== 'all') {
      descriptions.push(describeAttributeScoreFilter(score[0], score[1], score[2]));
    }

    if (completeness && completeness[1] && completeness[1] !== 'all') {
      descriptions.push(describeAttributeCompletenessFilter(completeness[0], completeness[1]));
    }

    return descriptions;
  });

  const optInDescriptions: string[] = [];
  if (filterForm.optIn) {
    if (filterForm.optIn.optInEmail) {
      optInDescriptions.push(
        `Opt-in email: ${filterForm.optIn.optInEmail ? 'Opted-in' : 'Opted-out'}`
      );
    }

    if (filterForm.optIn.optInText) {
      optInDescriptions.push(
        `Opt-in text: ${filterForm.optIn.optInText ? 'Opted-in' : 'Opted-out'}`
      );
    }

    if (filterForm.optIn.optInPhone) {
      optInDescriptions.push(
        `Opt-in phone: ${filterForm.optIn.optInPhone ? 'Opted-in' : 'Opted-out'}`
      );
    }
  }

  return [
    describeAttributeScoreFilter('Score', filterForm.recordScore, RECORD_SCORE_RANGES),
    describeAttributeCompletenessFilter('Completeness', filterForm.recordCompleteness),
    describePinnedProfileFilter('Pinnability', filterForm.pinned),
    describeDuplicateProfileFilter('Duplication', filterForm.duplicates),
    describeRecordCategoryFilter('Issue', filterForm.recordCategory),
    describeModifiedRecordFilter('Modification', filterForm.modifications),
    ...attributeDescriptions,
    ...optInDescriptions,
  ];
}

export function formToPageUrlParams(form: SourceSystemRecordFilterForm) {
  const { protocol, host, pathname } = window.location;
  const url = `${protocol}//${host}${pathname}`;
  const shouldAddParam = (value: ScoreFormFilter) =>
    value && (value.range ? value.range !== 'all' : value !== 'all');

  const params = [
    ['recordScore', form.recordScore],
    ['recordScore.operator', form.recordScore.operator],
    ['recordScore.value1', form.recordScore.value1],
    ['recordScore.value2', form.recordScore.value2],
    ['recordCategory', form.recordCategory],
    ['recordCompleteness', form.recordCompleteness],
    ['pinned', form.pinned],
    ['duplicates', form.duplicates],
    ['modifications', form.modifications],
    ['firstName', form.firstName],
    ['firstNameScore', form.firstNameScore],
    ['firstNameScore.operator', form.firstNameScore.operator],
    ['firstNameScore.value1', form.firstNameScore.value1],
    ['firstNameScore.value2', form.firstNameScore.value2],
    ['lastName', form.lastName],
    ['lastNameScore', form.lastNameScore],
    ['lastNameScore.operator', form.lastNameScore.operator],
    ['lastNameScore.value1', form.lastNameScore.value1],
    ['lastNameScore.value2', form.lastNameScore.value2],
    ['emailAddress', form.email],
    ['emailScore', form.emailScore],
    ['emailScore.operator', form.emailScore.operator],
    ['emailScore.value1', form.emailScore.value1],
    ['emailScore.value2', form.emailScore.value2],
    ['phone', form.phone],
    ['phoneScore', form.phoneScore],
    ['phoneScore.operator', form.phoneScore.operator],
    ['phoneScore.value1', form.phoneScore.value1],
    ['phoneScore.value2', form.phoneScore.value2],
    ['dobScore', form.dobScore],
    ['dobScore.operator', form.dobScore.operator],
    ['dobScore.value1', form.dobScore.value1],
    ['dobScore.value2', form.dobScore.value2],
    ['addressScore', form.addressScore],
    ['addressScore.operator', form.addressScore.operator],
    ['addressScore.value1', form.addressScore.value1],
    ['addressScore.value2', form.addressScore.value2],
    ['optIn.optInEmail', form.optIn.optInEmail],
    ['optIn.optInPhone', form.optIn.optInPhone],
    ['optIn.optInText', form.optIn.optInText],
  ]
    .filter((v: any, key) =>
      key === 3 || key === 13 || key === 18 || key === 23 || key === 28 || key === 32 || key === 37
        ? v[1] !== undefined ?? v
        : v
    )
    .filter((v: any) => shouldAddParam(v[1]))
    .map((v: any) => `${v[0]}=${v[1].range ?? v[1]}`)
    .join('&');
  return `${url}${params ? `?${params}` : ''}`;
}

function queryParamValue(
  paramName: string,
  params: URLSearchParams,
  defaultValue: string = 'all'
): any {
  return params.get(paramName) ?? defaultValue;
}

export function urlParamsToForm(params: URLSearchParams): SourceSystemRecordFilterForm {
  return {
    recordCategory: queryParamValue('recordCategory', params),
    recordCompleteness: queryParamValue('recordCompleteness', params),
    pinned: queryParamValue('pinned', params),
    duplicates: queryParamValue('duplicates', params),
    modifications: queryParamValue('modifications', params),
    recordScore: {
      range: queryParamValue('recordScore', params),
      operator: queryParamValue('recordScore.operator', params),
      value1: queryParamValue('recordScore.value1', params),
      value2:
        queryParamValue('recordScore.value2', params) === 'all'
          ? undefined
          : queryParamValue('recordScore.value2', params),
    },
    firstName: queryParamValue('firstName', params),
    firstNameScore: {
      range: queryParamValue('firstNameScore', params),
      operator: queryParamValue('firstNameScore.operator', params),
      value1: queryParamValue('firstNameScore.value1', params),
      value2:
        queryParamValue('firstNameScore.value2', params) === 'all'
          ? undefined
          : queryParamValue('firstNameScore.value2', params),
    },
    lastName: queryParamValue('lastName', params),
    lastNameScore: {
      range: queryParamValue('lastNameScore', params),
      operator: queryParamValue('lastNameScore.operator', params),
      value1: queryParamValue('lastNameScore.value1', params),
      value2:
        queryParamValue('lastNameScore.value2', params) === 'all'
          ? undefined
          : queryParamValue('lastNameScore.value2', params),
    },
    email: queryParamValue('emailAddress', params),
    emailScore: {
      range: queryParamValue('emailScore', params),
      operator: queryParamValue('emailScore.operator', params),
      value1: queryParamValue('emailScore.value1', params),
      value2:
        queryParamValue('emailScore.value2', params) === 'all'
          ? undefined
          : queryParamValue('emailScore.value2', params),
    },
    phone: queryParamValue('phone', params),
    phoneScore: {
      range: queryParamValue('phoneScore', params),
      operator: queryParamValue('phoneScore.operator', params),
      value1: queryParamValue('phoneScore.value1', params),
      value2:
        queryParamValue('phoneScore.value2', params) === 'all'
          ? undefined
          : queryParamValue('phoneScore.value2', params),
    },
    dobScore: {
      range: queryParamValue('dobScore', params),
      operator: queryParamValue('dobScore.operator', params),
      value1: queryParamValue('dobScore.value1', params),
      value2:
        queryParamValue('dobScore.value2', params) === 'all'
          ? undefined
          : queryParamValue('dobScore.value2', params),
    },
    address: queryParamValue('address', params),
    addressScore: {
      range: queryParamValue('addressScore', params),
      operator: queryParamValue('addressScore.operator', params),
      value1: queryParamValue('addressScore.value1', params),
      value2:
        queryParamValue('addressScore.value2', params) === 'all'
          ? undefined
          : queryParamValue('addressScore.value2', params),
    },
    optIn: {
      optInEmail: queryParamValue('optIn.optInEmail', params, ''),
      optInPhone: queryParamValue('optIn.optInPhone', params, ''),
      optInText: queryParamValue('optIn.optInText', params, ''),
    },
  };
}

export function formToSearch(form: SourceSystemRecordFilterForm) {
  const attributeScores = [
    [IdentityRecordSearchAttribute.FirstName, form.firstNameScore],
    [IdentityRecordSearchAttribute.LastName, form.lastNameScore],
    [IdentityRecordSearchAttribute.Phone, form.phoneScore],
    [IdentityRecordSearchAttribute.EmailAddress, form.emailScore],
    [IdentityRecordSearchAttribute.DateOfBirth, form.dobScore],
    [IdentityRecordSearchAttribute.Address, form.addressScore],
  ]
    .map(([attribute, filter]) =>
      transformFilterToAttributeSearchOperation(
        attribute as IdentityRecordSearchAttribute,
        filter as ScoreFormFilter
      )
    )
    .filter((v) => isDefined(v)) as AttributeSearchOperation[];

  const attributeCompleteness = [
    [IdentityRecordSearchAttribute.FirstName, form.firstName],
    [IdentityRecordSearchAttribute.LastName, form.lastName],
    [IdentityRecordSearchAttribute.Phone, form.phone],
    [IdentityRecordSearchAttribute.EmailAddress, form.email],
  ]
    .map(([attribute, filter]: any) =>
      transformCompletenessFilterToAttributeSearchOperation(attribute, filter)
    )
    .filter((v) => isDefined(v)) as AttributeSearchOperation[];

  const optInAttributes = Object.entries(form.optIn)
    .reduce<SourceIdentityRecordOptInAttribute[]>(
      (prev, [key, value]) => [
        ...prev,
        { attribute: key as any, value: value ? Boolean(value) : undefined },
      ],
      []
    )
    .filter((item) => item.value != null);

  return {
    modifications: form.modifications !== 'all' ? form.modifications === 'true' : undefined,
    recordCategories: form.recordCategory === 'all' ? [] : (form.recordCategory?.split(',') as any),
    recordScore: transformFilterToSearchOperation(form.recordScore),
    recordCompleteness: transformCompletenessFilterToSearchOperation(form.recordCompleteness),
    pinned: isDefined(form.pinned) && form.pinned !== 'all' ? form.pinned === 'pinned' : undefined,
    duplicates: form.duplicates === 'duplicate' || undefined,
    attributeCompleteness,
    attributeScores,
    optInAttributes,
  };
}

export function urlParamsToSearch(params: URLSearchParams) {
  const data = urlParamsToForm(params);
  return formToSearch(data);
}
