import { Box, Text } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import { useMemo } from 'react';
import { SourceIdentityRecordSearchResponse } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import { IdentityDataKeys } from '../../../../lib/api-client/identity/model/UpdateIdentityRecord';

interface ScoreCellProps {
  cellContext: CellContext<SourceIdentityRecordSearchResponse, IdentityDataKeys | undefined>;
}
export default function ColumnCell({ cellContext }: ScoreCellProps) {
  const value = useMemo(() => cellContext.getValue(), [cellContext]);

  return (
    <Box>
      {Array.isArray(value)
        ? value.map((item: any) => <Text key={item}>{item}</Text>)
        : (value as any)}
    </Box>
  );
}
