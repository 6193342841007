import { Box, Button, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import SourceConnectionStatusIndicator from '../SourceConnectionStatusIndicator/SourceConnectionStatusIndicator';

export default function RealtimeIngestionSection() {
  const { dataSource, sourceSystemType } = useCurrentDataSource();
  const { id: sourceId, sourceSystem } = dataSource;
  const enableTestIngestion = sourceSystem !== 'customIntegration';
  const status = dataSource.enabled ? 'enabled' : 'disabled';

  return (
    <SectionContainer
      title="Real-time ingestion"
      variant="box"
      w="full"
      titleFontSize="sm"
      display="flex"
      flexDirection="column"
      minH="0"
    >
      <Flex minH="0" h="full" direction="column" justify="space-between">
        <Box>
          <HStack justify="space-between">
            <SourceConnectionStatusIndicator status={status} />
            <Tooltip
              hasArrow
              label="Real-time ingestion is enabled or disabled with the source connection."
              shouldWrapChildren
              placement="top"
            >
              <Icon iconImage={IconImage.info} boxSize={5} color="action" />
            </Tooltip>
          </HStack>
          <Text mt="4">
            {status === 'disabled' ? (
              <>New data events are not currently streaming.</>
            ) : (
              <>New data events are streaming in real-time.</>
            )}
          </Text>
        </Box>
        <HStack mt="3">
          {!dataSource.enabled && enableTestIngestion && (
            <Button w="full" as={Link} to={`/sources/${sourceId}/test-ingestion`}>
              Run test ingestion
            </Button>
          )}
          {sourceSystemType.supportedFeatures.webhooks && (
            <Button w="full" as={Link} to={`/sources/${sourceId}/web-hooks`}>
              Manage webhooks
            </Button>
          )}
        </HStack>
      </Flex>
    </SectionContainer>
  );
}
