import { addMinutes, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import {
  SourceIngestSchedule,
  WeeklySourceIngestionSchedule,
} from '../../../../lib/api-client/sources/sources.model';

function getLocalTime(minutesOffset: number, tz: string) {
  const executionTime = addMinutes(startOfDay(new Date()), minutesOffset);

  return `${format(executionTime, 'h:mm a')} ${format(executionTime, 'zzz', { timeZone: tz })}`;
}

function getDayOfWeek(dayOfWeek: WeeklySourceIngestionSchedule['dayOfWeek']) {
  switch (dayOfWeek) {
    case 'MON':
      return 'Monday';
    case 'TUE':
      return 'Tuesday';
    case 'WED':
      return 'Wednesday';
    case 'THU':
      return 'Thursday';
    case 'FRI':
      return 'Friday';
    case 'SAT':
      return 'Saturday';
    case 'SUN':
      return 'Sunday';
    default:
      return '';
  }
}

export function getScheduleExecution(schedule: SourceIngestSchedule) {
  let frequency;
  switch (schedule.frequency) {
    case 'MINUTES':
      frequency = `every ${schedule.interval} minutes`;
      break;
    case 'HOURS':
      frequency = `every ${schedule.interval} hours`;
      break;
    case 'DAILY':
      frequency = `daily at ${getLocalTime(schedule.minutesOffset, schedule.timeZone)}`;
      break;
    case 'WEEKLY':
      frequency = `weekly on ${getDayOfWeek(schedule.dayOfWeek)} at ${getLocalTime(
        schedule.minutesOffset,
        schedule.timeZone
      )}`;
      break;
    default:
      frequency = '';
  }

  return `Runs ${frequency}`;
}
