import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  HStack,
  Stack,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import DataSourceIcon from '../../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import { formatNumber, formatPercentage } from '../../../../lib/utils/number-utils';
import DataQualityScoreFluctuationChip from '../DataFluctuationChip/DataQualityScoreFluctuationChip';
import DataQualityScoreIndicator from '../DataQualityScoreIndicator/DataQualityScoreIndicator';

interface EnterPriseDataHealthCardProps {
  source: Pick<DataSource, 'name' | 'sourceSystem' | 'category' | 'id' | 'enabled'>;
  totalQualityScore: number;
  totalQualityScorePercentage: number;
  qualityAlert: number;
  hasQualityAlert?: boolean;
}

export default function EnterPriseDataHealthCard({
  source,
  totalQualityScore,
  totalQualityScorePercentage,
  qualityAlert,
  hasQualityAlert = false,
}: EnterPriseDataHealthCardProps) {
  const ChakraFaCircle = chakra(FaCircle);
  let qualityAlertButtonProps: ButtonProps = {};
  let qualityAlertBoxProps: BoxProps = {};
  if (hasQualityAlert) {
    qualityAlertButtonProps = {
      colorScheme: 'errorScheme',
      leftIcon: <Icon iconImage={IconImage.error} boxSize={4} />,
    };
    qualityAlertBoxProps = {
      borderColor: 'error',
      border: '1px solid',
    };
  }
  return (
    <Flex direction="column" position="relative" w="260px">
      <Box
        p={4}
        border="1px"
        boxShadow="base"
        borderRadius="md"
        borderColor="gray.100"
        {...qualityAlertBoxProps}
      >
        <VStack alignItems="start" spacing={3}>
          <HStack>
            <DataSourceIcon sourceSystem={source.sourceSystem} />
            <Stack spacing={0}>
              <Text
                fontWeight="bold"
                fontSize="14"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                maxWidth="200px"
              >
                {source.name}
              </Text>
            </Stack>
          </HStack>

          <HStack spacing={4}>
            <VStack alignItems="start" spacing={1}>
              <Text fontWeight="semibold" fontSize="xs" color="gray.600">
                Quality score
              </Text>
              <HStack spacing={1}>
                <DataQualityScoreIndicator score={totalQualityScore} w={3} />
                <Text>{formatPercentage(totalQualityScore, 0)}</Text>
                <DataQualityScoreFluctuationChip value={totalQualityScorePercentage} />
              </HStack>
            </VStack>
            <VStack alignItems="start" spacing={1}>
              <Text fontWeight="semibold" fontSize="xs" color="gray.600">
                Quality alerts
              </Text>
              <HStack spacing={1}>
                <ChakraFaCircle color={qualityAlert > 0 ? 'error' : 'success'} />
                <Text>{formatNumber(qualityAlert)}</Text>
              </HStack>
            </VStack>
          </HStack>
        </VStack>
        <Button
          variant="solid"
          w="full"
          mt="4"
          as={Link}
          to={`/sources/${source.id}`}
          {...qualityAlertButtonProps}
        >
          {hasQualityAlert ? 'Inspect ' : 'Explore'}
        </Button>
      </Box>
    </Flex>
  );
}
