import { Button } from '@chakra-ui/react';
import { useFlags } from 'flagsmith/react';
import React from 'react';
import { Link } from 'react-router-dom';
import TrayConfigurationButton from '../../../../components/features/connections/TrayConfigurationButton/TrayConfigurationButton';
import { FeatureFlags } from '../../../../lib/model/FeatureFlags';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import ManageSalesforceConfigurationButton from './ManageSalesforceConfigurationSection/ManageSalesforceConfigurationButton';
import ManageSourceConfigAttributesButton from './ManageSourceConfigrationAttributesSection/ManageSourceConfigAttributesButton';

export default function ConnectionConfiguration() {
  const { dataSource, refetchDataSource } = useCurrentDataSource();
  const flags = useFlags([FeatureFlags.SHOW_CONNECTION_CONFIGURATION_PAGE]);

  if (dataSource.requiresExternalConfiguration) {
    return (
      <TrayConfigurationButton sourceId={dataSource.id} onSuccess={() => refetchDataSource()} />
    );
  }

  if (dataSource.sourceSystem === 'salesforceApp') {
    return <ManageSalesforceConfigurationButton />;
  }

  if (
    flags.show_connection_configuration_page.enabled &&
    dataSource.requiresExternalConfiguration === false &&
    dataSource.requiresInternalConfiguration === false
  ) {
    return null;
  }

  return flags.show_connection_configuration_page.enabled ? (
    <Button as={Link} to={`/sources/${dataSource.id}/manage/configuration`} w="full">
      Manage configuration
    </Button>
  ) : (
    <ManageSourceConfigAttributesButton />
  );
}
