import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import IngestionHistoryChart from '../../../components/features/connections/IngestionHistoryChart/IngestionHistoryChart';
import IngestionHistoryChartPerSource from '../../../components/features/connections/IngestionHistoryChart/IngestionHistoryChartPerSource';
import { formatNumber } from '../../../lib/utils/number-utils';

interface DeletesActivityProps {
  sourceId: string;
  metrics?: any;
  sourceName?: string;
}
export default function DeletesActivity({ sourceId, metrics, sourceName }: DeletesActivityProps) {
  const [events, setEvents] = useState<null | number>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <SectionContainer variant="box">
      <SimpleGrid columns={2}>
        <Stack onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <Text as="span" fontWeight="bold">
            Deletes
          </Text>
          <Text fontWeight="bold" fontSize="2xl">
            {events != null ? formatNumber(events) : ''}
          </Text>
          <Text>total data record delete events ingested in the last 7 days</Text>
          <Button
            fontWeight="semibold"
            textColor="action"
            onClick={onOpen}
            variant="link"
            alignSelf="start"
            position="relative"
            zIndex={isHovering ? '1' : '-1'}
          >
            Explore all activity
          </Button>
        </Stack>

        <Drawer isOpen={isOpen} size="xl" placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent pr={2}>
            <DrawerCloseButton />
            <DrawerHeader>Ingestion activity for {sourceName} </DrawerHeader>
            <DrawerBody>
              <IngestionHistoryChartPerSource
                chartWidth="102%"
                chartHeight={330}
                sourceId={sourceId}
                recordEventsIngested={metrics?.recordEventsIngested?.total}
                recordsDeleted={metrics?.recordEventsDeleted?.total}
                error={metrics?.recordEventsIngestedLastWeek?.errors}
                hideMetricsCards
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <IngestionHistoryChart
          sourceId={sourceId}
          initialEventType="delete"
          hideFilters
          hideReferenceValue
          chartHeight={140}
          onDataChange={(data) => {
            setEvents(data?.referenceValue?.recordsDeleted?.sum ?? 0);
          }}
        />
      </SimpleGrid>
    </SectionContainer>
  );
}
