import { Button, Flex, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useGetSourceIngestSchedule } from '../../../../lib/api-client/sources/SourceData';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import LastExecution from './LastExecution';
import ManageScheduleDrawer from './ManageScheduleDrawer';
import ScheduleExecution from './ScheduleExecution';

export default function ManageScheduledSection() {
  const { dataSource, ingestScheduleError } = useCurrentDataSource();
  const { data: sourceIngestSchedule } = useGetSourceIngestSchedule(dataSource.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isNotConfigured = sourceIngestSchedule?.status === 'NOT_CONFIGURED';
  const isError = !!ingestScheduleError;

  let executionDetails;
  if (sourceIngestSchedule) {
    if (isNotConfigured) {
      executionDetails = (
        <Stack>
          <LastExecution schedule={sourceIngestSchedule} />
          <Text>Configure the ingestion schedule to ensure a steady data flow.</Text>
        </Stack>
      );
    } else {
      executionDetails = (
        <Stack>
          <LastExecution schedule={sourceIngestSchedule} />
          <ScheduleExecution schedule={sourceIngestSchedule} />
        </Stack>
      );
    }
  } else {
    executionDetails = (
      <Stack>
        <Skeleton w="full" h="10" />
        <Skeleton w="full" h="5" />
      </Stack>
    );
  }

  return (
    <SectionContainer
      title="Scheduled ingestion"
      variant="box"
      w="full"
      titleFontSize="sm"
      display="flex"
      flexDirection="column"
      minH="0"
      bg={isNotConfigured ? 'highlight' : undefined}
      border={isError ? '1px solid' : undefined}
      borderColor={isError ? 'error' : undefined}
    >
      <Flex minH="0" h="full" direction="column" justify="space-between">
        {executionDetails}
        <Button
          w="full"
          onClick={onOpen}
          leftIcon={isNotConfigured ? <Icon iconImage={IconImage.info} boxSize={4} /> : undefined}
          mt={3}
        >
          Manage schedule
        </Button>
        <ManageScheduleDrawer isOpen={isOpen} onClose={onClose} />
      </Flex>
    </SectionContainer>
  );
}
