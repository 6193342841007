import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { useFlags } from 'flagsmith/react';
import React from 'react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { FeatureFlags } from '../../../lib/model/FeatureFlags';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';
import ManageSourceConfigAttributesButton from '../SourceConnectionManagementPage/ConnectionConfiguration/ManageSourceConfigrationAttributesSection/ManageSourceConfigAttributesButton';
import CompletenessCheckmark from '../shared/CompletenessCheckmark/CompletenessCheckmark';

interface SourceConfigAttributesSectionProps {
  completeLabel?: string;
}

export default function SourceConfigAttributesSection({
  completeLabel = '',
}: SourceConfigAttributesSectionProps) {
  const { dataSource: source } = useCurrentDataSource();
  const flags = useFlags([FeatureFlags.SHOW_CONNECTION_CONFIGURATION_PAGE]);

  const isNativeConfiguration = source.requiresInternalConfiguration;
  const isConfigured = source.configured ?? false;
  if (!isNativeConfiguration) {
    return null;
  }

  return (
    <SectionContainer
      variant="box"
      title="Connection configuration"
      borderRadius="6px"
      header={<CompletenessCheckmark label={completeLabel} isComplete={isConfigured} />}
      data-testid="SecurityCredentialsSetup"
    >
      <VStack alignItems="start" spacing={3}>
        <Text>
          Allow harpin AI to connect to the data source for seamless data ingestion and processing.
        </Text>

        {flags.show_connection_configuration_page.enabled ? (
          <Button as={Link} to={`/sources/${source.id}/manage/configuration`}>
            Configure connection
          </Button>
        ) : (
          <Box>
            <ManageSourceConfigAttributesButton />
          </Box>
        )}
      </VStack>
    </SectionContainer>
  );
}
