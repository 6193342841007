import { chakra, HStack, Spinner } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { SourceIngestSchedule } from '../../../../lib/api-client/sources/sources.model';
import { getScheduleExecution } from './schedule-status.util';

interface ScheduleExecutionProps {
  schedule: SourceIngestSchedule;
  isInProgress?: boolean;
}

const ChakraFaCircle = chakra(FaCircle);

export default function ScheduleExecution({
  schedule,
  isInProgress = false,
}: ScheduleExecutionProps) {
  if (isInProgress) {
    return (
      <HStack spacing="1">
        <Spinner color="action" size="xs" />
        <span>Ingestion is currently in progress</span>
      </HStack>
    );
  }

  if (schedule.status === 'DISABLED') {
    return (
      <HStack spacing="1">
        <ChakraFaCircle boxSize={3} color="gray.600" />
        <span>All future scheduled ingestions are paused</span>
      </HStack>
    );
  }

  return (
    <HStack spacing="1">
      <ChakraFaCircle boxSize={3} color="success" />
      <span>{getScheduleExecution(schedule)}</span>
    </HStack>
  );
}
