import {
  Box,
  Button,
  Center,
  chakra,
  Divider,
  HStack,
  Select,
  Spinner,
  Stack,
  useBoolean,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useScrollableSourceIngestHistory } from '../../../lib/api-client/sources/SourceData';
import { Page } from '../../../lib/model/common/Page';
import { DateDifferenceFormatted } from '../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../lib/utils/number-utils';
import SourceDetails from '../../connection-management/SourceConnectionManagementPage/SourceDetails/SourceDetails';
import Datepicker from '../PublishAuditLogPage/components/PublishAuditLogItem/DatePicker';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import SourceIngestionHistoryItem from './components/PublishAuditLogItem/SourceIngestionHistoryItem';

type LogFilters = {
  startTime?: string;
  endTime?: string;
  statuses?: ('FAILED' | 'COMPLETED' | 'IN_PROGRESS')[];
};

export default function SourceIngestionHistoryPage() {
  const { id = '' } = useParams();
  const { dataSource } = useCurrentDataSource();
  const [initialLoad, setInitialLoad] = useBoolean(true);
  const [filters, setFilters] = useState<LogFilters>({
    statuses: ['COMPLETED', 'FAILED', 'IN_PROGRESS'],
  });
  const [lastPage, setLastPage] = useState<Page<any>>();

  const {
    data: history,
    loading,
    scroll,
  } = useScrollableSourceIngestHistory(dataSource.id, {
    ...filters,
    sort: 'endTime,desc',
    size: 100,
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setFilters((prev) => ({
      ...prev,
      statuses:
        selectedValue === ''
          ? undefined
          : ([selectedValue] as ('FAILED' | 'COMPLETED' | 'IN_PROGRESS')[]),
    }));
  };

  useEffect(() => {
    if (initialLoad && !loading) {
      setInitialLoad.toggle();
    }
  }, [initialLoad, setInitialLoad, loading]);

  useEffect(() => {
    if (history != null) {
      setLastPage(history.slice(-1).pop() as any) as any;
    }
  }, [history]);

  const onNext = useCallback(async () => {
    await scroll();
  }, [scroll]);

  const onTimeChange = (value: any) => {
    if (value?.startDate && value?.endDate) {
      const { formattedStartDate, formattedEndDate } = DateDifferenceFormatted(value);

      setFilters((logFilters) => ({
        ...logFilters,
        startTime: formattedStartDate || undefined,
        endTime: formattedEndDate || undefined,
      }));
    } else {
      setFilters((logFilters) => ({
        ...logFilters,
        startTime: undefined,
        endTime: undefined,
      }));
    }
  };

  return (
    <PageLayout
      pageViewEvent={{ page: 'Schedule ingestion history', pageId: id }}
      header={
        <Header
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          title={`${dataSource.name} schedule ingestion history`}
          back={{
            label: 'Back to connection management',
            to: `/sources/${dataSource.id}/manage`,
          }}
        >
          <Stack pl={6} pr={6} mt={2}>
            <SourceDetails />
            <HStack mt={4}>
              {lastPage && (
                <>
                  <Datepicker onTimeChange={onTimeChange} />
                  <chakra.form px={6} py={3} onSubmit={(e) => e.preventDefault()}>
                    <HStack justify="space-between">
                      <Stack w="230px" direction="row" spacing={7}>
                        <Select placeholder="Show all statuses" onChange={handleSelectChange}>
                          <option value="COMPLETED">Show only completed</option>
                          <option value="FAILED">Show only errors</option>
                        </Select>
                      </Stack>
                    </HStack>
                  </chakra.form>
                  <HStack justifyContent="space-between" w="450px">
                    <Box>{loading && <Spinner size="sm" borderColor="action" />}</Box>
                    <chakra.span fontSize="xs" fontWeight="semibold" color="gray.600" ml="120px">
                      {`Showing ${lastPage.number * lastPage.size + 1} - ${Math.min(
                        (lastPage.number + 1) * lastPage.size,
                        lastPage.totalElements
                      )} of ${formatNumber(lastPage.totalElements)} `}
                    </chakra.span>
                  </HStack>
                </>
              )}
            </HStack>
          </Stack>
        </Header>
      }
      loading={initialLoad}
    >
      {history
        ?.flatMap((page) => page.content)
        .map(
          (item) =>
            item.status !== 'SKIPPED' && (
              <React.Fragment key={`${item.status}-${item.startTime}`}>
                <SourceIngestionHistoryItem historyData={item} />
                <Divider mt={3} mb={3} w="1010px" color="gray.300" borderWidth="thin" />
              </React.Fragment>
            )
        )}
      {history && (
        <Stack divider={<Divider />} spacing={3}>
          {!lastPage?.last && (
            <Center>
              <Button isLoading={loading} onClick={onNext}>
                See next{' '}
                {lastPage
                  ? Math.min(100, lastPage.totalElements - (lastPage.number + 1) * lastPage.size)
                  : ''}
              </Button>
            </Center>
          )}
        </Stack>
      )}
    </PageLayout>
  );
}
