import {
  Box,
  chakra,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBoolean,
  Button,
} from '@chakra-ui/react';
import consola from 'consola';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import ProfilePageActivityRecentPurchaseData from '../../../components/features/profile/ProfilePageActivityPurchaseData/ProfilePageActivityRecentPurchaseData';
import ProfilePageActivityTrackerData from '../../../components/features/profile/ProfilePageActivityTrackerData/ProfilePageActivityTrackerData';
import ProfilePageIdentityData from '../../../components/features/profile/ProfilePageIdentityData/ProfilePageIdentityData';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import CustomerValueClient from '../../../lib/api-client/customer-value/CustomerValueClient';
import { CustomerValue } from '../../../lib/api-client/customer-value/model/CustomerValue';
import {
  useGetIdentityByPin,
  useGetIdentityProfileTagsByPin,
  useGetIdentityTopPin,
} from '../../../lib/api-client/identity/IdentityData';
import { Identity } from '../../../lib/api-client/identity/model/Identity';
import ProfileClient from '../../../lib/api-client/profile/ProfileClient';
import { Events } from '../../../lib/api-client/profile/model/Events';
import { Profile } from '../../../lib/api-client/profile/model/Profile';
import { Transaction } from '../../../lib/api-client/profile/model/Transaction';
import { isHttpError } from '../../../lib/utils/error-utils';
import ProfileFeedbackButton from '../components/ProfileFeedbackButton/ProfileFeedbackButton';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import ProfilePageLoyaltyData from '../components/ProfilePageLoyaltyData/ProfilePageLoyaltyData';
import ProfilePageLoyaltyDataIcon from '../components/ProfilePageLoyaltyData/ProfilePageLoyaltyDataIcon';
import ProfilePageProfileDataSources from '../components/ProfilePageProfileDataSources/ProfilePageProfileDataSources';

export interface ProfilePageLinkState {
  options: { label: string; value: string };
  identity: Identity;
}

function ProfilePage() {
  // For the time being do this cast, clean this up later
  // https://github.com/remix-run/react-router/issues/8200
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as ProfilePageLinkState;

  const {
    data: identity,
    loading: identityLoading,
    error: identityError,
  } = useGetIdentityByPin(id, state?.identity);

  const { data: topPinidentity } = useGetIdentityTopPin(id);

  const {
    data: pinTags,
    loading: pinTagsLoading,
    error: pinTagsError,
  } = useGetIdentityProfileTagsByPin(id);

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [events, setEvents] = useState<Events[]>([]);
  const [profile, setProfile] = useState<Profile>();
  const [totalElements, setTotalElements] = useState(0);
  const [customerValue, setCustomerValue] = useState<CustomerValue>();
  const [initializationError, setInitializationError] = useState(false);
  const [isLoading, setLoading] = useBoolean(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const showLoyalty =
    identity?.sources?.find((s) => s.source.domainType === 'LOYALTY_PROGRAM') != null;

  useEffect(() => {
    const fetchData = async () => {
      const getTransactionsByPin = ProfileClient.getTransactionsByPin(id).then(
        (page) => page.content
      );
      const getProfileByPin = ProfileClient.getProfileByPin(id).then((p) => {
        if (!p) {
          return p;
        }
        return { ...p };
      });
      const getCustomerValueByPin = CustomerValueClient.getCustomerValueByPin(id);

      try {
        const [
          getTransactionsByPinResponse,
          getProfileByPinResponse,
          getCustomerValueByPinResponse,
        ] = await Promise.all([getTransactionsByPin, getProfileByPin, getCustomerValueByPin]);
        setTransactions(getTransactionsByPinResponse);
        setCustomerValue(getCustomerValueByPinResponse);
        setProfile(getProfileByPinResponse);
      } catch (err) {
        setInitializationError(true);
      }

      setLoading.off();
    };

    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  const handleClick = async (key?: string) => {
    try {
      const getEventsByPin = await ProfileClient.getEventsByPin(id, key);
      const { content, totalElements: totalElementsValue } = getEventsByPin;

      setEvents(content);
      return totalElementsValue;
    } catch (err) {
      consola.error(err);
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      const totalElementsValue = await handleClick();
      setTotalElements(totalElementsValue as number);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let loyaltyProgram;
  if (profile && profile.loyaltyPrograms.length > 0) {
    [loyaltyProgram] = profile.loyaltyPrograms;
  }

  if (isHttpError(identityError, 404)) {
    navigate('/404', { replace: true });
    return null;
  }
  const handleTabChange = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  const handleClickValue = (keyItem?: string) => {
    navigate(`/profiles/${id}/purchases`, { state: { selectedTabIndex, keyValue: keyItem } });
  };

  const profileTabData = profile && Object.keys(profile.eventTypeCounts);
  const pinTagValue = pinTags?.find((e) => e.tagKey === 'authenticity');
  const totalRecords = totalElements + (profile?.transactionCount ?? 0);
  return (
    <PageLayout
      data-testid="ProfilePage"
      pageViewEvent={{ page: 'Profile Page' }}
      header={
        <Header
          icon={<Icon iconImage={IconImage.searchUser} />}
          title="Consumer profile"
          // back={{
          //   label: 'Back to search results',
          //   to: '/profiles/search',
          // }}
          previousRecord={state}
        />
      }
      initializationError={initializationError || !!(identityError || pinTagsError)}
      loading={isLoading || identityLoading || pinTagsLoading}
    >
      <Box>
        {identity && (
          <HStack justify="space-between" pb={6} alignItems="start">
            <ProfileHeader
              identity={identity}
              authenticityTag={pinTagValue}
              similarProfile={topPinidentity}
            />
            <ProfileFeedbackButton pin={identity?.pin} />
          </HStack>
        )}
        <HStack spacing="10" alignItems="start" marginBottom="10">
          {identity && <ProfilePageIdentityData identity={identity} />}
        </HStack>
        <HStack spacing="10" justifyContent="space-between">
          <Text fontWeight="700" fontSize="16">
            Activity tracker
          </Text>
        </HStack>
        <Tabs
          variant="line"
          colorScheme="#005874"
          mb={6}
          onChange={handleTabChange}
          index={selectedTabIndex}
        >
          <TabList>
            <HStack w="full" justifyContent="space-between">
              <Flex>
                <Tab>
                  <chakra.span>Transactions ({profile?.transactionCount ?? 0})</chakra.span>
                </Tab>
                {profileTabData?.map((key) => (
                  <Tab key={key} onClick={() => handleClick(key)}>
                    {key} ({profile?.eventTypeCounts[key] ?? 0})
                  </Tab>
                ))}
                {showLoyalty && (
                  <Tab>
                    Loyalty
                    <ProfilePageLoyaltyDataIcon
                      loyaltyProgram={loyaltyProgram}
                      boxSize={4}
                      ml={1}
                    />
                  </Tab>
                )}
              </Flex>
              <Text fontSize="xs" fontWeight="semibold" color="gray.600">
                {totalRecords} total on record
              </Text>
            </HStack>
          </TabList>
          <HStack alignItems="start">
            <TabPanels pt={6}>
              <TabPanel p={0}>
                <ProfilePageActivityRecentPurchaseData
                  transactions={transactions}
                  handleClick={handleClickValue}
                  customerValue={customerValue}
                  profile={profile}
                />
              </TabPanel>
              {profileTabData?.map((key) => (
                <TabPanel p={0} key={key} onClick={() => handleClick(key)}>
                  <ProfilePageActivityTrackerData
                    events={events}
                    handleClick={handleClickValue}
                    keyValue={key}
                    profile={profile}
                  />
                </TabPanel>
              ))}
              {showLoyalty && (
                <TabPanel p={0}>
                  {profile &&
                    profile.loyaltyPrograms
                      .slice(0, 3)
                      .map((loyaltyProgramItem) => (
                        <ProfilePageLoyaltyData loyaltyProgram={loyaltyProgramItem} />
                      ))}
                  {profile && profile.loyaltyPrograms.length === 0 && (
                    <HStack
                      w="700px"
                      border="1px solid"
                      borderRadius="6px"
                      borderColor="gray.100"
                      p={3}
                    >
                      <Icon iconImage={IconImage.unsuccessful} color="error" />
                      <Text>No loyalty membership on record</Text>
                    </HStack>
                  )}
                  {profile && profile.loyaltyPrograms.length > 0 && (
                    <Button
                      mt={2}
                      onClick={() => {
                        handleClickValue();
                      }}
                    >
                      See all activity and insights
                    </Button>
                  )}
                </TabPanel>
              )}
            </TabPanels>
          </HStack>
        </Tabs>
        {identity?.sources && (
          <ProfilePageProfileDataSources sources={identity?.sources} pin={identity.pin} />
        )}
      </Box>
    </PageLayout>
  );
}
export default ProfilePage;
