import ExportErrorAlert from './ExportErrorAlert';
import IngestScheduleErrorAlert from './IngestScheduleErrorAlert';
import InitialSetupAlert from './InitialSetupAlert';

export default function SourceAlerts() {
  return (
    <>
      <InitialSetupAlert />
      <ExportErrorAlert />
      <IngestScheduleErrorAlert />
    </>
  );
}
