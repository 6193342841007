import { BoundCanProps } from '@casl/react';
import { ReactNode } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import Layout from './components/shared/layouts/Layout/Layout';
import { Can } from './context/AuthorizationContext';
import { InfiniteScrollProvider } from './context/InfiniteScrollContext';
import { PageContextProvider } from './context/PageContext';
import { PageHistoryProvider } from './context/PageHistoryContext';
import AuthenticationGuard from './guards/AuthenticationGuard';
import NonAuthenticationGuard from './guards/NonAuthenticationGuard';
import { AppAbilityType } from './lib/services/AuthorizationService';
import AccountSettingsPage from './pages/account-settings/AccountSettingsPage/AccountSettingsPage';
import OAuthSuccess from './pages/authentication/OAuthSuccess';
import SigninPage from './pages/authentication/SigninPage';
import NotFoundPage from './pages/common/NotFoundPage/NotFoundPage';
import ConnectionConfigurationPage from './pages/connection-configuration/ConnectionConfigurationPage/ConnectionConfigurationPage';
import SourceConnectionManagementPage from './pages/connection-management/SourceConnectionManagementPage/SourceConnectionManagementPage';
import SourceConnectionsDashboard from './pages/connection-management/SourceConnectionsDashboard/SourceConnectionsDashboard';
import ConsumerProfileExplorerPage from './pages/data-explorer/ConsumerProfileExplorerPage/ConsumerProfileExplorerPage';
import ReportExplorerPage from './pages/data-explorer/ReportExplorerPage/ReportExplorerPage';
import ProfilePage from './pages/profile/ProfilePage/ProfilePage';
import ProfilePageActivityAllPurchaseData from './pages/profile/ProfilePageActivityAllPurchaseData/ProfilePageActivityAllPurchaseData';
import ProfilePageAllIdentityDataPage from './pages/profile/ProfilePageAllIdentityDataPage/ProfilePageAllIdentityDataPage';
import SearchProfilePage from './pages/profile/SearchProfilePage/SearchProfilePage';
import SecurityPage from './pages/security/SecurityPage/SecurityPage';
import CreateTenantPage from './pages/seviin-tools/CreateTenantPage/CreateTenantPage';
import ReprocessTenantPage from './pages/seviin-tools/ReprocessTenantPage/ReprocessTenantPage';
import SeviinToolsDemo2Page from './pages/seviin-tools/SeviinToolsDemo2Page/SeviinToolsDemo2Page';
import SeviinToolsPage from './pages/seviin-tools/SeviinToolsPage/SeviinToolsPage';
import SwitchTenantPage from './pages/seviin-tools/SwitchTenantPage/SwitchTenantPage';
import TenantManagementPage from './pages/seviin-tools/TenantManagementPage/TenantManagementPage';
import AllRepairAutomation from './pages/sources/AllRepairAutomation/AllRepairAutomation';
import DataMappingPage from './pages/sources/DataMappingPage/DataMappingPage';
import DataQualityIssuesPage from './pages/sources/DataQualityIssuesPage/DataQualityIssuesPage';
import DataQualityIssuesPageDetails from './pages/sources/DataQualityIssuesPageDetails/DataQualityIssuesPageDetails';
import DataRepairPage from './pages/sources/DataRepairPage/DataRepairPage';
import EnterpriseDataDashboardPage from './pages/sources/EnterpriseDataDashboard/EnterpriseDataDashboard';
import IngestErrorLogPage from './pages/sources/IngestErrorLogPage/IngestErrorLogPage';
import MergeRulesPage from './pages/sources/MergeRulesPage/MergeRulesPage';
import ProfileDashboard from './pages/sources/ProfileDashboard/ProfileDashboard';
import PublishAuditLogPage from './pages/sources/PublishAuditLogPage/PublishAuditLogPage';
import SourceDataRecordExplorerPage from './pages/sources/SourceDataRecordExplorerPage/SourceDataRecordExplorerPage';
import SourceDataRecordVersionPage from './pages/sources/SourceDataRecordVersionPage/SourceDataRecordVersionPage';
import SourceIngestionHistoryPage from './pages/sources/SourceIngestionHistoryPage/SourceIngestionHistoryPage';
import SystemDashboardPage from './pages/sources/SystemDashboardPage/SystemDashboardPage';
import {
  ConnectedSystemTestIngestionData,
  FlatFileTestIngestionData,
} from './pages/sources/TestIngestionDataPage/TestIngestionDataPage';
import WebhookManagement from './pages/sources/WebhookManagePage/WebhookManagePage';
import { CurrentDataSourceProvider } from './pages/sources/context/CurrentDataSourceContext';

interface AuthorizedComponentProps {
  children: ReactNode;
}

export function AuthorizedComponent({
  children,
  ...rest
}: AuthorizedComponentProps & BoundCanProps<AppAbilityType>) {
  return (
    <Can {...rest} passThrough>
      {(allowed) => (allowed ? children : <Navigate to="/404" replace />)}
    </Can>
  );
}

const authenticatedRoutes: RouteObject[] = [
  {
    path: '/data-explorer/profile',
    element: <ConsumerProfileExplorerPage />,
  },
  {
    path: '/sources/repair',
    element: <DataRepairPage />,
  },
  {
    path: '/sources',
    element: <EnterpriseDataDashboardPage />,
  },
  {
    path: '/sources/connections',
    element: <SourceConnectionsDashboard />,
  },
  {
    path: '/sources/repair-automation',
    element: <AllRepairAutomation />,
  },
  {
    path: '/sources',
    element: (
      <CurrentDataSourceProvider>
        <Outlet />
      </CurrentDataSourceProvider>
    ),
    children: [
      {
        path: ':id',
        element: <SystemDashboardPage />,
      },
      {
        path: ':id/data-explorer',
        element: <SourceDataRecordExplorerPage />,
      },
      {
        path: ':id/issues',
        element: <DataQualityIssuesPage />,
      },

      {
        path: ':id/issues-details',
        element: <DataQualityIssuesPageDetails />,
      },

      {
        path: ':id/manage',
        element: <SourceConnectionManagementPage />,
      },

      {
        path: ':id/manage/configuration',
        element: <ConnectionConfigurationPage />,
      },

      {
        path: ':id/manage/ingestion-history',
        element: <SourceIngestionHistoryPage />,
      },

      {
        path: ':id/mappings',
        element: <DataMappingPage />,
      },
      // TODO remove this route eventually
      // {
      //   path: ':id/quality-analysis',
      //   element: <SourceSystemDashboard />,
      // },
      {
        path: ':id/merge-rules',
        element: <MergeRulesPage />,
      },
      {
        path: ':id/logs/publish-audit',
        element: <PublishAuditLogPage />,
      },

      {
        path: ':id/ingest-error',
        element: <IngestErrorLogPage />,
      },
      {
        path: ':id/test-ingestion',
        element: <ConnectedSystemTestIngestionData />,
      },
      {
        path: ':id/test-ingestion/:uploadId',
        element: <FlatFileTestIngestionData />,
      },

      {
        path: ':id/web-hooks',
        element: <WebhookManagement />,
      },
      {
        path: ':id/records/:recordId',
        element: <SourceDataRecordVersionPage />,
      },
    ],
  },
  {
    path: '/',
    element: <ProfileDashboard />,
  },
  {
    path: '/reports/:id',
    element: <ReportExplorerPage />,
  },
  {
    path: '/health/sources/issue/:id',
    element: <DataQualityIssuesPage />,
  },
  {
    path: '/security',
    element: <SecurityPage />,
  },
  {
    path: '/settings',
    element: <AccountSettingsPage />,
  },
  {
    path: '/harpin-tools',
    element: (
      <AuthorizedComponent I="manage" a="SeviinAdmin" passThrough>
        <Outlet />
      </AuthorizedComponent>
    ),
    children: [
      {
        path: 'demo2',
        element: <SeviinToolsDemo2Page />,
      },
      {
        path: 'switch-tenant',
        element: <SwitchTenantPage />,
      },
      {
        path: 'new-tenant',
        element: <CreateTenantPage />,
      },
      {
        path: 'tenant-management',
        element: <TenantManagementPage />,
      },

      {
        path: 'reprocess-tenant',
        element: <ReprocessTenantPage />,
      },
      {
        index: true,
        element: <SeviinToolsPage />,
      },
    ],
  },
  {
    path: '/profiles',
    children: [
      {
        path: 'search',
        element: <SearchProfilePage />,
      },
      {
        path: ':id/purchases',
        element: <ProfilePageActivityAllPurchaseData />,
      },
      {
        path: ':id/identities',
        element: <ProfilePageAllIdentityDataPage />,
      },
      {
        path: ':id',
        element: <ProfilePage />,
      },
    ],
  },

  {
    path: '/404',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
];

const routes: RouteObject[] = [
  {
    path: '/signin',
    element: (
      <NonAuthenticationGuard>
        <SigninPage />
      </NonAuthenticationGuard>
    ),
  },
  {
    path: '/oauth/success',
    element: <OAuthSuccess />,
  },
  {
    element: (
      <AuthenticationGuard>
        <InfiniteScrollProvider>
          <PageHistoryProvider>
            <PageContextProvider>
              <Layout />
            </PageContextProvider>
          </PageHistoryProvider>
        </InfiniteScrollProvider>
      </AuthenticationGuard>
    ),
    children: authenticatedRoutes,
  },
];
export default routes;
