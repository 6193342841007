import { chakra, HStack, Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { useGetSourceIngestHistory } from '../../../../lib/api-client/sources/SourceData';
import { SourceIngestSchedule } from '../../../../lib/api-client/sources/sources.model';
import { formatDateString } from '../../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../../lib/utils/number-utils';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';

interface ScheduleExecutionProps {
  schedule: SourceIngestSchedule;
}

const ChakraFaCircle = chakra(FaCircle);

export default function LastExecution({ schedule }: ScheduleExecutionProps) {
  const { dataSource, ingestScheduleError } = useCurrentDataSource();
  const { data: history, loading } = useGetSourceIngestHistory(dataSource.id, {
    page: 0,
    size: 2,
    sort: 'endTime,desc',
  });

  const isNotConfigured = schedule.status === 'NOT_CONFIGURED';
  const ingestHistory = (history?.content ?? []).find((item) => item.status !== 'IN_PROGRESS');

  let lastRunDetails;
  if (isNotConfigured) {
    lastRunDetails = (
      <HStack spacing="1">
        <ChakraFaCircle boxSize={3} color="gray.600" />
        <chakra.span fontWeight="semibold">Incomplete</chakra.span>
      </HStack>
    );
  } else if (loading) {
    lastRunDetails = <Skeleton w="full" h="10" />;
  } else if (ingestHistory != null && ingestHistory.endTime != null) {
    lastRunDetails = (
      <HStack spacing="1">
        <ChakraFaCircle boxSize={3} color={ingestScheduleError ? 'error' : 'success'} />
        <chakra.span fontWeight="semibold">{`Last run ${formatDateString(
          ingestHistory.endTime,
          "MMM d, yyyy 'at' hh:mm a"
        )}`}</chakra.span>
      </HStack>
    );
  } else {
    lastRunDetails = (
      <HStack spacing="1">
        <ChakraFaCircle boxSize={3} color="gray.600" />
        <chakra.span>No ingestions have been run</chakra.span>
      </HStack>
    );
  }

  let recordsIngested;
  if (loading) {
    recordsIngested = <Skeleton ml="4" height="5" w="60%" />;
  } else if (ingestScheduleError) {
    recordsIngested = (
      <chakra.span ml="4" color="error">
        Ingestion error encountered
      </chakra.span>
    );
  } else if (!isNotConfigured && ingestHistory != null) {
    recordsIngested = (
      <chakra.span ml="4">
        {formatNumber(history?.content?.[0]?.recordsIngested ?? 0)} total records ingested
      </chakra.span>
    );
  }

  return (
    <Stack spacing=".5">
      {lastRunDetails}
      {recordsIngested}
    </Stack>
  );
}
