import { useEffect } from 'react';
import { useStartRemoteIngestion } from '../../../lib/api-client/sources/SourceData';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import { getSetupType } from './SetupConnectionPage.utils';
import { useTestIngestionResult } from './TestIngestionResultContext';
import { useTestSample } from './useTestSample';

// eslint-disable-next-line import/prefer-default-export
export function useRemoteIngestion(source: DataSource) {
  const setupType = getSetupType(source.sourceSystem);
  const enableRemoteIngestion =
    (source.configured ?? false) &&
    (setupType === 'INTEGRATION' || setupType === 'MICROSOFT_DYNAMICS');
  const { data: remoteIngestion, refetch: restartRemoteIngestion } = useStartRemoteIngestion(
    source.id,
    'TEST',
    enableRemoteIngestion
  );

  const sampleId = remoteIngestion?.sampleId;

  useTestSample(source.id, sampleId);
  const { setRestartTestIngestionFn } = useTestIngestionResult();

  useEffect(() => {
    setRestartTestIngestionFn(() => () => restartRemoteIngestion());
  }, [setRestartTestIngestionFn, restartRemoteIngestion]);
}
