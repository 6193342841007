/* eslint-disable react/function-component-definition */
import {
  Heading,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ChakraProvider,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface MarkdownRendererProps {
  content: string;
}

interface ComponentProps {
  children?: ReactNode;
}

function H1({ children }: ComponentProps) {
  return (
    <Heading as="h1" size="2xl" mt={4} mb={4}>
      {children}
    </Heading>
  );
}

function H2({ children }: ComponentProps) {
  return (
    <Heading as="h2" size="xl" mt={4} mb={4}>
      {children}
    </Heading>
  );
}

function H3({ children }: ComponentProps) {
  return (
    <Heading as="h3" size="lg" mt={3} mb={3}>
      {children}
    </Heading>
  );
}

function H4({ children }: ComponentProps) {
  return (
    <Heading as="h5" size="sm" mt={2} mb={2}>
      {children}
    </Heading>
  );
}

function H5({ children }: ComponentProps) {
  return (
    <Heading as="h6" size="xs" mt={1} mb={1}>
      {children}
    </Heading>
  );
}

function H6({ children }: ComponentProps) {
  return (
    <Heading as="h6" size="xs" mt={1} mb={1}>
      {children}
    </Heading>
  );
}

function Paragraph({ children }: ComponentProps) {
  return <Text mb={2}>{children}</Text>;
}

function Blockquote({ children }: ComponentProps) {
  return (
    <Box
      pl={4}
      py={2}
      my={4}
      borderLeft="4px"
      borderColor="gray.200"
      bg="gray.50"
      _dark={{ borderColor: 'gray.600', bg: 'gray.800' }}
    >
      <Text fontStyle="italic">{children}</Text>
    </Box>
  );
}

function Code({ children }: ComponentProps) {
  return (
    <Box
      as="code"
      p={2}
      mb={2}
      bg="gray.100"
      borderRadius="md"
      display="block"
      _dark={{ bg: 'gray.700' }}
    >
      {children}
    </Box>
  );
}

function UnorderedList({ children }: ComponentProps) {
  return (
    <Box as="ul" pl={5} mb={2}>
      {children}
    </Box>
  );
}

function OrderedList({ children }: ComponentProps) {
  return (
    <Box as="ol" pl={5} mb={2}>
      {children}
    </Box>
  );
}

function ListItem({ children }: ComponentProps) {
  return (
    <Box as="li" mb={1}>
      {children}
    </Box>
  );
}

function TableComponent({ children }: ComponentProps) {
  return (
    <Table variant="simple" w="max-content" mb={4}>
      {children}
    </Table>
  );
}

function TableHead({ children }: ComponentProps) {
  return <Thead>{children}</Thead>;
}

function TableBody({ children }: ComponentProps) {
  return <Tbody>{children}</Tbody>;
}

function TableRow({ children }: ComponentProps) {
  return <Tr>{children}</Tr>;
}

function TableCell({ children }: ComponentProps) {
  return <Td>{children}</Td>;
}

function TableHeaderCell({ children }: ComponentProps) {
  return <Th>{children}</Th>;
}

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: Paragraph,
  blockquote: Blockquote,
  code: Code,
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  table: TableComponent,
  thead: TableHead,
  tbody: TableBody,
  tr: TableRow,
  td: TableCell,
  th: TableHeaderCell,
};

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => (
  <ChakraProvider>
    <ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
      {content}
    </ReactMarkdown>
  </ChakraProvider>
);

export default MarkdownRenderer;
