import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import consola from 'consola';
import { PropsWithChildren } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Alert from '../../../components/core/Alert/Alert';
import SourceClient from '../../../lib/api-client/sources/SourceClient';
import { ConfigAction, ConfigAttribute } from '../../../lib/api-client/sources/model/SourceTypes';
import { SourceSystemConfigAttribute } from '../../../lib/api-client/sources/sources.model';
import ConfigAttributeControl from './ConfigAttributeControl';

type SubmittingMessage = { success: boolean; message: string } | null;

export function ConfigAttributeForm({
  actions,
  configAttributesConfig: pageConfigAttributesConfig,
  savedConfigAttributes,
  isSubmittingMessage,
  setSelectedValue,
  selectRefetch,
}: {
  actions: ConfigAction[];
  configAttributesConfig: ConfigAttribute[];
  savedConfigAttributes: SourceSystemConfigAttribute[];
  isSubmittingMessage?: SubmittingMessage;
  setSelectedValue?: (value: string) => void;
  selectRefetch?: number;
}) {
  const form = useFormContext();

  const shouldDisplayField = (config: ConfigAttribute) => {
    if (config.conditions.length > 0) {
      return config.conditions.some((condition) => {
        const index = pageConfigAttributesConfig.findIndex((c) => c.name === condition.name);

        const watchedValue = form.watch(`data.${index}.value`);
        const match = condition.value === watchedValue;

        if (index > -1) {
          return match;
        }

        const foundSaved = savedConfigAttributes.findIndex(
          (savedConfig) =>
            savedConfig.name === condition.name && savedConfig.value === condition.value
        );

        return foundSaved > -1;
      });
    }

    return true;
  };

  const lookupConfig = (config: ConfigAttribute) => {
    if (config.valuesLookup && config.valuesLookup.dependsOn.length > 0) {
      const args = config.valuesLookup.dependsOn.map((dependency) => {
        const index = pageConfigAttributesConfig.findIndex((c) => c.name === dependency);
        if (index > -1) {
          return {
            name: dependency,
            value: form.watch(`data.${index}.value`),
          };
        }

        return {
          name: dependency,
          value: undefined,
        };
      });

      return {
        enabled: true,
        args: args.filter((arg) => arg.value != null),
      };
    }

    return {
      enabled: true,
    };
  };

  return (
    <Stack spacing="4">
      {pageConfigAttributesConfig.map((config, index) => {
        const error = form.formState?.errors?.data?.[index]?.value;
        const shouldDisplay = shouldDisplayField(config);

        return (
          <FormControl
            key={config.name}
            isInvalid={!!error}
            display={shouldDisplay ? 'block' : 'none'}
          >
            <FormLabel>{config.label}</FormLabel>
            <ConfigAttributeControl
              name={`data.${index}.value`}
              configAttribute={config}
              disabled={!shouldDisplay}
              lookupConfig={lookupConfig(config)}
              selectedMethod={setSelectedValue}
              selectRefetch={selectRefetch}
            />
            {(config.dataType === 'ENUM' || config.valuesLookup) && (
              <FormHelperText w={config.uiComponent === 'list' ? 'full' : '500px'}>
                {config.description}
              </FormHelperText>
            )}
            {error && <FormErrorMessage>{error?.message as any}</FormErrorMessage>}
          </FormControl>
        );
      })}
      <SimpleGrid columns={2} gap={4} w="500px">
        {actions.map((action) => (
          <Button
            key={action.type}
            type="submit"
            onClick={() =>
              form.setValue('action', { type: action.type, arguments: action.arguments })
            }
          >
            {action.label}
          </Button>
        ))}
      </SimpleGrid>
      {!!isSubmittingMessage && (
        <Alert
          w="500px"
          status={isSubmittingMessage.success ? 'success' : 'error'}
          description={isSubmittingMessage.message}
        />
      )}
    </Stack>
  );
}

interface ConfigAttributeFormContextProps extends PropsWithChildren {
  sourceId: string;
  step: string;
  configAttributes: SourceSystemConfigAttribute[];
  configAttributesConfig: ConfigAttribute[];
  onSuccess: () => void;
  setIsSubmittingMessage?: (message: SubmittingMessage) => void;
}

interface Form {
  data: SourceSystemConfigAttribute[];
  action: any;
}

export function ConfigAttributeFormContext({
  step,
  sourceId,
  configAttributes = [],
  configAttributesConfig,
  onSuccess,
  children,
  setIsSubmittingMessage,
}: ConfigAttributeFormContextProps) {
  const form = useForm<Form>({
    mode: 'onBlur',
    defaultValues: {
      data: configAttributes,
    },
  });

  const onSubmit = async (data: Form) => {
    try {
      const filteredValues = data.data
        .filter(
          (attribute) => configAttributesConfig.find((c) => c.name === attribute.name) != null
        )
        .map((attribute) => ({
          ...attribute,
          value:
            typeof attribute.value === 'string' && attribute.value.trim().length === 0
              ? null
              : attribute.value,
        }));

      const response = await SourceClient.performConfigAction(sourceId, {
        stepName: step,
        action: data.action,
        attributeValues: filteredValues,
      });

      if (setIsSubmittingMessage) {
        setIsSubmittingMessage({
          success: response.success,
          message: response.message ?? '',
        });
      }

      if (response.success && data.action.type === 'SAVE') {
        onSuccess();
      }
    } catch (err) {
      consola.error('Error updating source system configuration attributes', err);
      if (setIsSubmittingMessage) {
        setIsSubmittingMessage({
          success: false,
          message: 'There was a problem updating the configuration',
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
}
