import { ISODateString, Nullable } from '../../model/common';
import { DataSourceDomainTypes } from './model/DataSourceDomainTypes';

export type IngestionType = 'BATCH' | 'TEST' | 'INCREMENTAL';

export interface StartRemoteIngestionResponse {
  sampleId: string;
}

export interface ExportAuditEvent {
  sourceId: string;
  sourceRecordId: string;
  eventType: ExportAuditEventType;
  success: boolean;
  pin: string;
  payload: string;
  errorMessage?: Nullable<string>;
  duration: number;
  eventId: string;
  timestamp: ISODateString;
}

export interface ExportIngestError {
  sourceId: string;
  domainData: string;
  domainType: string;
  errors?: Nullable<string>;
  ingestionTime: ISODateString;
  lastUpdated: ISODateString;
  loadType: string;
  recordId: string;
  sampleId: string;
  sourceData: {
    event: {
      data?: Record<string, any>;
    };
    eventId: string;
    version: number;
  };
}

export interface TestIngestionAttribute {
  canonicalAttribute: string;
  sourceAttribute: string;
  value?: string | null;
  persistedValueName?: string | null;
}

export interface TestIngestionRecord {
  domainType: DataSourceDomainTypes;
  domainData: Record<
    DataSourceDomainTypes,
    { attributes: TestIngestionAttribute[]; errors?: string }
  >;
  ingestionTime: ISODateString;
  lastUpdated: ISODateString;
  recordId: string;
  sourceData: {
    event: {
      data?: Record<string, any>;
    };
    eventId: string;
    version: number;
  };
  sourceId: string;
  sourceAttributes: Record<string, string[]>;
}

export type TestIngestionDataStatus =
  | 'running'
  | 'timeout'
  | 'completedWithWarnings'
  | 'completedWithErrors'
  | 'completedSuccessfully'
  | 'failed';

export interface TestIngestionData {
  warnings?: string[];
  errors?: string[];
  records: TestIngestionRecord[];
  summary: {
    status: TestIngestionDataStatus;
  };
}

export interface TestIngestionDetails {
  sampleId: string;
  tenantId: string;
  sourceId: string;
  status: string;
  createdAt: ISODateString;
  lastUpdated: ISODateString;
}

export interface SourceSystemConfigAttribute {
  name: string;
  value: string | boolean | null;
}

const ExportAuditEventTypes = {
  IDENTITY_CREATED: 'IDENTITY_CREATED',
  IDENTITY_UPDATED: 'IDENTITY_UPDATED',
  IDENTITY_MERGED: 'IDENTITY_MERGED',
  TRANSACTION_UPDATED: 'TRANSACTION_UPDATED',
  TRANSACTION_IDENTITY_REMOVED: 'TRANSACTION_IDENTITY_REMOVED',
  TRANSACTION_LINE_ITEM_CREATED: 'TRANSACTION_LINE_ITEM_CREATED',
  IDENTITY_REFERENCE_UPDATED: 'IDENTITY_REFERENCE_UPDATED',
} as const;
export type ExportAuditEventType =
  (typeof ExportAuditEventTypes)[keyof typeof ExportAuditEventTypes];

export function getExportAuditEventTypeLabel(type: ExportAuditEventType) {
  switch (type) {
    case 'IDENTITY_CREATED':
      return 'Consumer identity added';
    case 'IDENTITY_UPDATED':
      return 'Consumer identity updated';
    case 'IDENTITY_MERGED':
      return 'Consumer identity merged';
    case 'IDENTITY_REFERENCE_UPDATED':
      return 'Consumer identity reference updated';
    case 'TRANSACTION_UPDATED':
      return 'Transaction updated';
    case 'TRANSACTION_IDENTITY_REMOVED':
      return 'Consumer identity removed from transaction';
    case 'TRANSACTION_LINE_ITEM_CREATED':
      return 'Transaction line item added';
    default:
      return '';
  }
}

export type PerformConfigActionRequest = {
  stepName: string;
  action: any;
  attributeValues?: SourceSystemConfigAttribute[];
};

export type PerformConfigActionResponse = {
  success: boolean;
  message: string;
  lookupValues?: string[];
};

interface BaseSourceIngestSchedule {
  status: 'ENABLED' | 'DISABLED' | 'NOT_CONFIGURED';
  lastStatusChange: ISODateString;
  timeZone: string;
  lastUpdated: ISODateString;
}

export interface MinutesSourceIngestionSchedule extends BaseSourceIngestSchedule {
  frequency: 'MINUTES';
  interval: 5 | 10 | 15 | 30;
}

export interface HoursSourceIngestionSchedule extends BaseSourceIngestSchedule {
  frequency: 'HOURS';
  interval: 1 | 2 | 3 | 4 | 6 | 8 | 12;
}

export interface DailySourceIngestionSchedule extends BaseSourceIngestSchedule {
  frequency: 'DAILY';
  minutesOffset: number;
}

export interface WeeklySourceIngestionSchedule extends BaseSourceIngestSchedule {
  frequency: 'WEEKLY';
  minutesOffset: number;
  dayOfWeek: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';
}

export type SourceIngestSchedule =
  | MinutesSourceIngestionSchedule
  | HoursSourceIngestionSchedule
  | DailySourceIngestionSchedule
  | WeeklySourceIngestionSchedule;

export interface IngestHistory {
  jobId: string;
  status: 'COMPLETED' | 'FAILED' | 'IN_PROGRESS' | 'SKIPPED';
  startTime: ISODateString;
  endTime: ISODateString;
  durationMillis: number;
  recordsIngested: number;
  errorMessage?: string;
  triggerType: 'SCHEDULED' | 'ON_DEMAND';
  triggeredBy: string;
  lastUpdated: ISODateString;
}
