import {
  Checkbox,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea,
  Text,
  Tooltip,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import { ConfigAttribute } from '../../../lib/api-client/sources/model/SourceTypes';
import ConfigAttributeLookupControl from './ConfigAttributeLookupControl';
import MarkdownRenderer from './MarkdownRenderer';

type ConfigAttributeControlProps = {
  name: string;
  configAttribute: ConfigAttribute;
  disabled: boolean;
  selectedMethod?: any;
  selectRefetch?: number;
  lookupConfig: {
    enabled: boolean;
    args?: { name: string; value: any }[];
  };
};

export default function ConfigAttributeControl({
  name,
  configAttribute,
  disabled,
  lookupConfig,
  selectedMethod,
  selectRefetch,
}: ConfigAttributeControlProps) {
  const form = useFormContext();
  const [selectedValue, setSelectedValue] = useState('');

  const defaults = {
    required: { value: configAttribute.required ?? false, message: 'Field is required' },
    setValueAs: (value: string) => value.trim(),
    disabled,
  };

  const inputWidth =
    configAttribute.dataType === 'ENUM' && configAttribute.uiComponent === 'list'
      ? 'full'
      : '500px';

  let validations: any = {};
  if (configAttribute.validations && configAttribute.validations.length > 0) {
    validations = {
      validate: (value: string) => {
        if (configAttribute.dataType === 'SECRET' && value === '*** Redacted value ***') {
          return true;
        }

        const messages = (configAttribute.validations ?? [])
          .map((validation) => {
            switch (validation.type) {
              case 'LENGTH_BETWEEN':
                return yup
                  .string()
                  .min(validation.intValues[0])
                  .max(validation.intValues[1])
                  .isValidSync(value)
                  ? null
                  : `Must be between ${validation.intValues[0]} and ${validation.intValues[1]} characters`;
              case 'MATCHES_REGEX':
                return yup.string().matches(new RegExp(validation.stringValue)).isValidSync(value)
                  ? null
                  : `Must match ${validation.stringValue}`;
              default:
                return null;
            }
          })
          .filter((m) => m != null);

        return messages.length === 0 ? true : messages;
      },
    };
  }

  if (configAttribute.dataType === 'STRING') {
    if (configAttribute.valuesLookup) {
      return (
        <Box w={inputWidth}>
          <ConfigAttributeLookupControl
            lookupConfig={lookupConfig}
            name={name}
            configAttribute={configAttribute}
            selectRefetch={selectRefetch}
          />
        </Box>
      );
    }

    return (
      <InputGroup w={inputWidth}>
        <Input
          {...form.register(name, {
            ...defaults,
            ...validations,
          })}
          type="text"
        />
        <InputRightElement>
          {!!configAttribute.description && (
            <Tooltip
              hasArrow
              placement="top"
              label={configAttribute.description}
              shouldWrapChildren
            >
              <Icon boxSize={4} iconImage={IconImage.info} color="action" />
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>
    );
  }

  if (configAttribute.dataType === 'SECRET') {
    return (
      <InputGroup w={inputWidth}>
        <Input
          {...form.register(name, {
            ...defaults,
            ...validations,
          })}
          type="password"
        />
        <InputRightElement>
          {!!configAttribute.description && (
            <Tooltip
              hasArrow
              placement="top"
              label={configAttribute.description}
              shouldWrapChildren
            >
              <Icon boxSize={4} iconImage={IconImage.info} color="action" />
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>
    );
  }

  if (configAttribute.dataType === 'TEXT') {
    return (
      <InputGroup w={inputWidth}>
        <Textarea
          {...form.register(name, {
            ...defaults,
            ...validations,
          })}
        />
        <InputRightElement>
          {!!configAttribute.description && (
            <Tooltip
              hasArrow
              placement="top"
              label={configAttribute.description}
              shouldWrapChildren
            >
              <Icon boxSize={4} iconImage={IconImage.info} color="action" />
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>
    );
  }

  if (configAttribute.dataType === 'BOOLEAN') {
    return (
      <InputGroup w={inputWidth}>
        <Checkbox {...form.register(name)} />
        <InputRightElement>
          {!!configAttribute.description && (
            <Tooltip
              hasArrow
              placement="top"
              label={configAttribute.description}
              shouldWrapChildren
            >
              <Icon boxSize={4} iconImage={IconImage.info} color="action" />
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>
    );
  }

  if (configAttribute.dataType === 'ENUM' && configAttribute.uiComponent === 'list') {
    const { register, setValue, watch } = form;
    const { onBlur } = register(name, {
      ...defaults,
      ...validations,
    });

    const value = watch(name);
    const handleRadioChange = (nextValue: string) => {
      setValue(name, nextValue);
    };

    return (
      <>
        <RadioGroup onChange={handleRadioChange} onBlur={onBlur} value={value}>
          <Stack spacing={1}>
            {configAttribute.enumValues.map((enumValue) => (
              <div key={enumValue.name}>
                <Radio
                  value={enumValue.name}
                  isChecked={selectedValue === enumValue.name}
                  onChange={() => {
                    setSelectedValue(enumValue.name);
                    selectedMethod(enumValue.name);
                  }}
                >
                  <Text fontWeight="600">{enumValue.label}</Text>
                </Radio>
                <Text ml={7} w="full">
                  {enumValue.description}
                </Text>
              </div>
            ))}
          </Stack>
        </RadioGroup>
        {configAttribute.enumValues.map(
          (enumValue) =>
            enumValue.instructionalText &&
            selectedValue === enumValue.name && (
              <Accordion
                key={enumValue.name}
                border="1px solid"
                borderColor="gray.100"
                boxShadow="sm"
                borderRadius="6px"
                allowToggle
                mt={4}
                mb={4}
              >
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <HStack justifyContent="space-between">
                        <Text pl="16px" fontWeight="bold">
                          Next steps
                        </Text>
                        <AccordionButton color="action" fontWeight="semibold" width="fit-content">
                          {isExpanded ? 'Hide details' : 'Show details'}
                          <AccordionIcon
                            ml="2"
                            color="action"
                            borderRadius="50%"
                            borderColor="action"
                            borderWidth="thin"
                            boxSize="16px"
                          />
                        </AccordionButton>
                      </HStack>
                      <AccordionPanel pb={4} hidden={!isExpanded}>
                        <MarkdownRenderer content={enumValue.instructionalText} />
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            )
        )}
      </>
    );
  }

  if (configAttribute.dataType === 'ENUM') {
    return (
      <Select
        w={inputWidth}
        {...form.register(name, {
          ...defaults,
          ...validations,
        })}
      >
        {configAttribute.enumValues.map((enumValue) => (
          <option key={enumValue.name} value={enumValue.name}>
            {enumValue.label}
          </option>
        ))}
      </Select>
    );
  }

  return null;
}
