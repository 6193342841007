import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useToast } from '../../../hooks/use-toast';
import AdminIdentityClient from '../../../lib/api-client/identity/AdminIdentityClient';
import AdminTenantClient from '../../../lib/api-client/tenant/AdminTenantClient';
import { Tenant } from '../../../lib/api-client/tenant/model/Tenant';

const reprocessTenantFormSchema = yup.object({
  id: yup.string().required('Tenant ID is required'),
  resolution: yup.string().oneOf(['batch', 'online']),
});

interface ReprocessTenantForm {
  id: string;
  resolution: 'batch' | 'online';
}

export default function ReprocessTenantPage() {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ReprocessTenantForm>({
    resolver: yupResolver(reprocessTenantFormSchema),
    mode: 'onBlur',
    defaultValues: {
      resolution: 'batch',
    },
  });
  const watchedResolution = watch('resolution');
  const toast = useToast();

  const onSubmit = async (data: ReprocessTenantForm) => {
    try {
      await AdminIdentityClient.reprocessEvents({
        tenantId: data.id,
        onlineResolution: data.resolution === 'online',
      });
      toast({
        status: 'success',
        title: 'Reprocessing tenant events...',
      });
    } catch (e) {
      let description;
      if (isAxiosError(e)) {
        description = e.response?.data?.message;
      }

      toast({
        status: 'error',
        title: 'There was a problem reprocessing events for the tenant.',
        description,
      });
    }
  };

  useEffect(() => {
    AdminTenantClient.getTenants().then((res) => setTenants(res.content));
  }, []);

  return (
    <PageLayout
      pageViewEvent={{ page: 'Reprocess tenant events' }}
      header={
        <Header
          icon={<Icon iconImage={IconImage.sync} />}
          title="Reprocess tenant events"
          back={{ label: 'Back to harpin AI tools', to: '/harpin-tools' }}
        />
      }
      data-testid="ReprocessTenantPage"
    >
      <chakra.form onSubmit={handleSubmit(onSubmit)} w="500px">
        <Stack spacing={5} pb={10}>
          <FormControl isInvalid={!!errors.id}>
            <FormLabel>Tenant ID</FormLabel>
            <Select placeholder="Select a tenant" {...register('id')}>
              {tenants
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((tenant) => (
                  <option key={tenant.id} value={tenant.id}>
                    {tenant.name}
                  </option>
                ))}
            </Select>
            <FormErrorMessage>{errors?.id?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.resolution}>
            <FormLabel>Resolution</FormLabel>
            <Select {...register('resolution')}>
              <option value="batch">Batch</option>
              <option value="online">Online</option>
            </Select>
            {watchedResolution === 'online' && (
              <FormHelperText>
                Online resolution is typically used when there is a smaller amount of total records
                (~50k total records).
              </FormHelperText>
            )}
            <FormErrorMessage>{errors?.resolution?.type}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Button type="submit" isLoading={isSubmitting}>
          Reprocess tenant
        </Button>
        <Text pt={3} fontSize="sm">
          NOTE: Clicking reprocess tenant will reprocess{' '}
          <chakra.span fontWeight="bold">ALL ingested data</chakra.span> by applying latest mappings
          and rules to identity data.
        </Text>
      </chakra.form>
    </PageLayout>
  );
}
