/* eslint-disable no-nested-ternary */
import {
  Button,
  chakra,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  VStack,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Tooltip,
  DrawerOverlay,
} from '@chakra-ui/react';
import consola from 'consola';
import { FaCircle } from 'react-icons/fa';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import {
  useGetSourceIngestSchedule,
  useScrollableSourceIngestHistory,
} from '../../../../lib/api-client/sources/SourceData';
import { formatDateString } from '../../../../lib/utils/date-time-utils';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import ManageScheduleForm from './ManageScheduleForm';
import ManageScheduleIngestionHistory from './ManageScheduleIngestionHistory';

interface ManageScheduleDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  frequency?: string;
  interval?: string;
  day?: string;
  time?: string;
  timezone?: string;
}

function ManageScheduleDrawer({ isOpen, onClose }: ManageScheduleDrawerProps) {
  const { dataSource: source } = useCurrentDataSource();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { data, refetch } = useGetSourceIngestSchedule(source.id);
  const { data: history } = useScrollableSourceIngestHistory(source.id, {
    sort: 'endTime,desc',
    statuses: ['COMPLETED', 'FAILED', 'IN_PROGRESS'],
    size: 10,
  });
  const statusData = source.enabled ? 'ENABLED' : 'DISABLED';
  const onSubmit = async (item: FormValues) => {
    const { timezone, ...rest } = item;
    const updatedSchedule = {
      ...rest,
      ...(timezone !== '' && { timezone }),
      status: statusData,
    };

    await SourceClient.updateIngestion(source.id, updatedSchedule);
    await refetch();
  };

  const handleRunIngestionNow = async () => {
    await SourceClient.createIngestion(source.id);
    onModalClose();
  };

  const handlePauseIngestionNow = async (sourceId: string) => {
    try {
      const updatedSchedule = { ...data, status: 'DISABLED' };
      await SourceClient.updateIngestion(sourceId, updatedSchedule);
      await refetch();
    } catch (error) {
      consola.error('Error pausing ingestion', error);
    }
  };

  const handleResumeIngestionNow = async (sourceId: string) => {
    try {
      const updatedSchedule = { ...data, status: 'ENABLED' };
      await SourceClient.updateIngestion(sourceId, updatedSchedule);
      await refetch();
    } catch (error) {
      consola.error('Error resuming ingestion', error);
    }
  };

  const ChakraFaCircle = chakra(FaCircle);

  const statusType =
    data?.status === 'ENABLED'
      ? 'Activated since'
      : data?.status === 'DISABLED'
      ? 'Paused since'
      : 'Incomplete';

  return (
    <>
      <Drawer size="md" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Manage scheduled ingestion</DrawerHeader>
          <DrawerBody>
            <SectionContainer variant="box" w="full" display="flex" flexDirection="column" minH="0">
              <VStack spacing={3} alignItems="start">
                <VStack spacing={0} alignItems="start">
                  <Text color="gray.600" fontSize="xs" fontWeight="semibold">
                    Schedule status
                  </Text>
                  <HStack fontSize="sm" spacing={1}>
                    <ChakraFaCircle color={data?.status === 'ENABLED' ? 'success' : 'gray.600'} />
                    <chakra.span>
                      {' '}
                      {statusType} {formatDateString(data?.lastUpdated ?? '', 'LLL dd, yyyy')}
                    </chakra.span>
                  </HStack>
                </VStack>

                {data?.status === 'ENABLED' && (
                  <Tooltip
                    placement="top"
                    label="Scheduled ingestion can not resume while the source connection is disabled."
                    aria-label="A tooltip"
                    isDisabled={source.enabled}
                  >
                    <Button
                      w="full"
                      variant="outline"
                      onClick={() => handlePauseIngestionNow(source.id)}
                      leftIcon={<Icon iconImage={IconImage.pause} boxSize={4} />}
                      isDisabled={!source.enabled}
                    >
                      Pause all future scheduled ingestions
                    </Button>
                  </Tooltip>
                )}
                {data?.status === 'DISABLED' && (
                  <Tooltip
                    placement="top"
                    label="Scheduled ingestion can not resume while the source connection is disabled."
                    aria-label="A tooltip"
                    isDisabled={source.enabled}
                  >
                    <Button
                      w="full"
                      variant="outline"
                      onClick={() => handleResumeIngestionNow(source.id)}
                      leftIcon={<Icon iconImage={IconImage.resume} boxSize={4} />}
                      isDisabled={!source.enabled}
                    >
                      Resume scheduled ingestion
                    </Button>
                  </Tooltip>
                )}

                <Tooltip
                  placement="top"
                  label="Ingestion can not run while the source connection is disabled."
                  aria-label="A tooltip"
                  isDisabled={source.enabled}
                >
                  <Button
                    w="full"
                    variant="outline"
                    onClick={onModalOpen}
                    isDisabled={!source.enabled}
                  >
                    Run one-time ingestion now
                  </Button>
                </Tooltip>
              </VStack>
            </SectionContainer>
            <ManageScheduleForm data={data} onSubmit={onSubmit} />
            <ManageScheduleIngestionHistory history={history} sourceId={source.id} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal size="sm" isOpen={isModalOpen} isCentered onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader w="380px">Are you sure you want to run a one-time ingestion?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>This will run a one-time ingestion from {source.name}</Text>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <HStack spacing={3}>
              <Button onClick={handleRunIngestionNow}>Yes, run now</Button>
              <Button variant="outline" onClick={onModalClose}>
                No, cancel
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ManageScheduleDrawer;
