import {
  Box,
  HStack,
  Text,
  chakra,
  SimpleGrid,
  Button,
  Switch,
  Divider,
  VStack,
  useBoolean,
  Tag,
  TagLabel,
  Tooltip,
} from '@chakra-ui/react';
import { useXSWR } from '@hazae41/xswr';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { Can } from '../../../context/AuthorizationContext';
import DataRepairClient from '../../../lib/api-client/data-repair/DataRepairClient';
import {
  getDataRepairs,
  useDataRepairMetrics,
  useDataRepairs,
} from '../../../lib/api-client/data-repair/DataRepairData';
import { formatDateString } from '../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../lib/utils/number-utils';
import DataRepairAllAutomationChart from '../DataRepairPage/components/DataRepairActivityChart/DataRepairAllAutomationChart';

export const EMAIL_DOMAIN_MISSPELLINGS_QUERY_PARAM = 'emailDomainMisspellings';
export const NAME_IS_REPEATED_QUERY_PARAM = 'nameIsRepeated';
export const NAME_FROM_EMAIL_QUERY_PARAM = 'nameFromEmail';
export const SUFFIX_IN_NAME_QUERY_PARAM = 'suffixInName';
export const MIDDLE_INITIAL_IN_NAME_QUERY_PARAM = 'middleInitialInName';

function AutomationMetricsCard({
  value,
  label,
  repairValue,
}: {
  value: number;
  label: string;
  repairValue?: number;
}) {
  return (
    <Box p={3} border="1px solid" borderColor="gray.100" boxShadow="md" borderRadius="6px">
      <HStack spacing={1}>
        <Text fontSize="xl" fontWeight="bold">
          {formatNumber(value)}
        </Text>
        {repairValue && (
          <Tooltip
            hasArrow
            placement="top"
            label={
              repairValue > 0
                ? `${formatNumber(repairValue)} repairs in the last 24 hours `
                : ' No repairs in the last 24 hours'
            }
          >
            <Tag
              rounded="full"
              fontSize="xs"
              minH={5}
              px={1.5}
              bgColor={repairValue > 0 ? 'success' : 'gray.200'}
              color={repairValue > 0 ? 'white' : 'gray.800'}
            >
              {repairValue > 0 && <Icon boxSize={4} iconImage={IconImage.arrowUp} />}
              <TagLabel fontWeight="semibold" lineHeight={1}>
                {repairValue > 0 ? formatNumber(repairValue) : 'no change'}
              </TagLabel>
            </Tag>
          </Tooltip>
        )}
      </HStack>
      <Text>{label} </Text>
    </Box>
  );
}

function AllRepairAutomation() {
  const [initialLoad, setInitialLoad] = useBoolean(true);
  const { data: emailDataRepair } = useDataRepairs('emailDomainMisspellings');
  const { data: nameDataRepair } = useDataRepairs('nameIsRepeated');
  const { data: nameFromEmail } = useDataRepairs('nameFromEmail');
  const { data: suffixInName } = useDataRepairs('suffixInName');
  const { data: middleInitialInName } = useDataRepairs('middleInitialInName');
  const { data: dataRepairMetrics } = useDataRepairMetrics();
  const { make } = useXSWR();

  const [isEmailDataRepairActive, setEmailDataRepairActive] = useState(emailDataRepair?.enabled);
  const [isNameDataRepairActive, setNameDataRepairActive] = useState(nameDataRepair?.enabled);
  const [isNameFromEmailDataRepairActive, setNameFromEmailDataRepairActive] = useState(
    nameFromEmail?.enabled
  );
  const [isSuffixfromNameDataRepairActive, setSuffixfromNameDataRepairActive] = useState(
    suffixInName?.enabled
  );
  const [isMiddleInitialFromNameDataRepairActive, setMiddleInitialFromNameDataRepairActive] =
    useState(middleInitialInName?.enabled);

  useEffect(() => {
    if (emailDataRepair) {
      setEmailDataRepairActive(emailDataRepair.enabled);
      setInitialLoad.off();
    }
    if (nameDataRepair) {
      setNameDataRepairActive(nameDataRepair.enabled);
      setInitialLoad.off();
    }
    if (nameFromEmail) {
      setNameFromEmailDataRepairActive(nameFromEmail.enabled);
      setInitialLoad.off();
    }
    if (suffixInName) {
      setSuffixfromNameDataRepairActive(suffixInName.enabled);
      setInitialLoad.off();
    }
    if (middleInitialInName) {
      setMiddleInitialFromNameDataRepairActive(middleInitialInName.enabled);
      setInitialLoad.off();
    }
  }, [
    emailDataRepair,
    setInitialLoad,
    nameDataRepair,
    nameFromEmail,
    suffixInName,
    middleInitialInName,
  ]);

  const emailDomainSwitchChanged = useCallback(
    async (enabled: boolean) => {
      setEmailDataRepairActive(enabled);
      const emailData = await DataRepairClient.updateDataRepairAutomation(
        'emailDomainMisspellings',
        enabled
      );
      const emailDataRepairs = make(getDataRepairs('emailDomainMisspellings'));
      await emailDataRepairs.mutate((previous: any) => ({
        ...previous,
        emailData,
      }));
    },
    [make, setEmailDataRepairActive]
  );

  const nameRepeatSwitchChanged = useCallback(
    async (enabled: boolean) => {
      setNameDataRepairActive(enabled);
      const nameData = await DataRepairClient.updateDataRepairAutomation('nameIsRepeated', enabled);
      const nameDataRepairs = make(getDataRepairs('nameIsRepeated'));
      await nameDataRepairs.mutate((previous: any) => ({
        ...previous,
        nameData,
      }));
    },
    [make, setNameDataRepairActive]
  );

  const nameFromEmailSwitchChanged = useCallback(
    async (enabled: boolean) => {
      setNameFromEmailDataRepairActive(enabled);
      const nameData = await DataRepairClient.updateDataRepairAutomation('nameFromEmail', enabled);
      const nameDataRepairs = make(getDataRepairs('nameFromEmail'));
      await nameDataRepairs.mutate((previous: any) => ({
        ...previous,
        nameData,
      }));
    },
    [make, setNameFromEmailDataRepairActive]
  );

  const suffixDataSwitchChanged = useCallback(
    async (enabled: boolean) => {
      setSuffixfromNameDataRepairActive(enabled);
      const suffixData = await DataRepairClient.updateDataRepairAutomation('suffixInName', enabled);
      const suffixDataRepairs = make(getDataRepairs('suffixInName'));
      await suffixDataRepairs.mutate((previous: any) => ({
        ...previous,
        suffixData,
      }));
    },
    [make, setSuffixfromNameDataRepairActive]
  );

  const middleInitialDataSwitchChanged = useCallback(
    async (enabled: boolean) => {
      setMiddleInitialFromNameDataRepairActive(enabled);
      const middleInitialData = await DataRepairClient.updateDataRepairAutomation(
        'middleInitialInName',
        enabled
      );
      const middleInitialDataRepairs = make(getDataRepairs('middleInitialInName'));
      await middleInitialDataRepairs.mutate((previous: any) => ({
        ...previous,
        middleInitialData,
      }));
    },
    [make, setMiddleInitialFromNameDataRepairActive]
  );

  const totalEmailDomainMisspellingsData = dataRepairMetrics?.metrics.repairsByAutomationType?.find(
    (e) => e.type === 'emailDomainMisspellings'
  );
  const totalIsRepeatedNameData = dataRepairMetrics?.metrics.repairsByAutomationType?.find(
    (e) => e.type === 'nameIsRepeated'
  );
  const totalIsNameFromEmailData = dataRepairMetrics?.metrics.repairsByAutomationType?.find(
    (e) => e.type === 'nameFromEmail'
  );
  const totalSuffiData = dataRepairMetrics?.metrics.repairsByAutomationType?.find(
    (e) => e.type === 'suffixInName'
  );
  const totalMiddleInitialData = dataRepairMetrics?.metrics.repairsByAutomationType?.find(
    (e) => e.type === 'middleInitialInName'
  );

  return (
    <PageLayout
      pageViewEvent={{ page: 'All Repair automation' }}
      header={
        <Header
          title="All data repair automations"
          icon={<Icon iconImage={IconImage.automation} />}
          back={{
            label: 'Back to all systems dashboard',
            to: `/sources`,
          }}
          noSearch
        />
      }
      loading={initialLoad}
    >
      <SimpleGrid columns={3} spacing={4}>
        <AutomationMetricsCard
          value={dataRepairMetrics?.metrics.totalRepairs ?? 0}
          label="total repairs across all source systems"
        />
        <AutomationMetricsCard
          value={dataRepairMetrics?.metrics.repairedIdentities ?? 0}
          label="total consumer profiles enhanced by repairs"
        />
      </SimpleGrid>
      <SectionContainer title="All data repair automations" mt={6}>
        <SimpleGrid columns={3} spacing={4}>
          {emailDataRepair && (
            <SectionContainer
              variant="box"
              p={3}
              header={
                <Text fontWeight="bold" fontSize="sm">
                  Repair misspelled email domains
                </Text>
              }
              display="flex"
              flexDirection="column"
            >
              <VStack alignItems="start" spacing={3}>
                <HStack>
                  <Icon iconImage={IconImage.automation} />
                  <Text fontSize="sm">
                    {formatNumber(totalEmailDomainMisspellingsData?.totalRepairs ?? 0)}
                  </Text>
                  <Text fontSize="sm">total repairs</Text>
                </HStack>
                <Divider borderColor="gray.300" opacity="1" w="320px" />
                <HStack justifyContent="space-between" spacing={10} w="full">
                  <HStack spacing={0}>
                    <Text fontWeight="bold">{emailDataRepair.enabled ? 'Active' : 'Inactive'}</Text>{' '}
                    <chakra.span whiteSpace="nowrap">
                      {`${
                        // eslint-disable-next-line no-nested-ternary
                        emailDataRepair.enabled
                          ? ', running'
                          : emailDataRepair.lastEnabledOrDisabledAt
                          ? ', paused'
                          : ''
                      } ${
                        emailDataRepair.lastEnabledOrDisabledAt
                          ? `since ${formatDateString(
                              emailDataRepair.lastEnabledOrDisabledAt,
                              'MMM dd, yyyy'
                            )}`
                          : ''
                      }`}
                    </chakra.span>
                  </HStack>
                  <Can I="update" an="DataRepair">
                    <Switch
                      isChecked={isEmailDataRepairActive}
                      onChange={() => emailDomainSwitchChanged(!isEmailDataRepairActive)}
                    />
                  </Can>
                </HStack>
                <Button
                  variant="outline"
                  w="full"
                  as={Link}
                  to={`/sources/repair?automationType=${EMAIL_DOMAIN_MISSPELLINGS_QUERY_PARAM}`}
                  mt={4}
                >
                  Review repair log
                </Button>
              </VStack>
            </SectionContainer>
          )}
          {nameDataRepair && (
            <SectionContainer
              variant="box"
              p={3}
              header={
                <Text fontSize="sm" fontWeight="bold">
                  Repair names repeated across data fields
                </Text>
              }
              display="flex"
              flexDirection="column"
            >
              <VStack alignItems="start" spacing={3}>
                <HStack>
                  <Icon iconImage={IconImage.automation} />
                  <Text fontSize="sm">
                    {formatNumber(totalIsRepeatedNameData?.totalRepairs ?? 0)}
                  </Text>
                  <Text fontSize="sm">total repairs</Text>
                </HStack>
                <Divider borderColor="gray.300" opacity="1" w="320px" />
                <HStack justifyContent="space-between" spacing={10} w="full">
                  <HStack spacing={0}>
                    <Text fontWeight="bold">{nameDataRepair.enabled ? 'Active' : 'Inactive'}</Text>
                    <chakra.span whiteSpace="nowrap">
                      {`${
                        // eslint-disable-next-line no-nested-ternary
                        nameDataRepair.enabled
                          ? ', running'
                          : nameDataRepair.lastEnabledOrDisabledAt
                          ? ', paused'
                          : ''
                      } ${
                        nameDataRepair.lastEnabledOrDisabledAt
                          ? `since ${formatDateString(
                              nameDataRepair.lastEnabledOrDisabledAt,
                              'MMM dd, yyyy'
                            )}`
                          : ''
                      }`}
                    </chakra.span>
                  </HStack>
                  <Can I="update" an="DataRepair">
                    <Switch
                      isChecked={isNameDataRepairActive}
                      onChange={() => nameRepeatSwitchChanged(!isNameDataRepairActive)}
                    />
                  </Can>
                </HStack>
                <Button
                  variant="outline"
                  w="full"
                  as={Link}
                  to={`/sources/repair?automationType=${NAME_IS_REPEATED_QUERY_PARAM}`}
                  mt={4}
                >
                  Review repair log
                </Button>
              </VStack>
            </SectionContainer>
          )}
          {nameFromEmail && (
            <SectionContainer
              variant="box"
              p={3}
              header={
                <Text fontSize="sm" fontWeight="bold">
                  Repair names using the email address
                </Text>
              }
              display="flex"
              flexDirection="column"
            >
              <VStack alignItems="start" spacing={3}>
                <HStack>
                  <Icon iconImage={IconImage.automation} />
                  <Text fontSize="sm">
                    {formatNumber(totalIsNameFromEmailData?.totalRepairs ?? 0)}
                  </Text>
                  <Text fontSize="sm">total repairs</Text>
                </HStack>
                <Divider borderColor="gray.300" opacity="1" w="320px" />
                <HStack justifyContent="space-between" spacing={10} w="full">
                  <HStack spacing={0}>
                    <Text fontWeight="bold">{nameFromEmail.enabled ? 'Active' : 'Inactive'}</Text>
                    <chakra.span whiteSpace="nowrap">
                      {`${
                        // eslint-disable-next-line no-nested-ternary
                        nameFromEmail.enabled
                          ? ', running'
                          : nameFromEmail.lastEnabledOrDisabledAt
                          ? ', paused'
                          : ''
                      } ${
                        nameFromEmail.lastEnabledOrDisabledAt
                          ? `since ${formatDateString(
                              nameFromEmail.lastEnabledOrDisabledAt,
                              'MMM dd, yyyy'
                            )}`
                          : ''
                      }`}
                    </chakra.span>
                  </HStack>
                  <Can I="update" an="DataRepair">
                    <Switch
                      isChecked={isNameFromEmailDataRepairActive}
                      onChange={() => nameFromEmailSwitchChanged(!isNameFromEmailDataRepairActive)}
                    />
                  </Can>
                </HStack>
                <Button
                  variant="outline"
                  w="full"
                  as={Link}
                  to={`/sources/repair?automationType=${NAME_FROM_EMAIL_QUERY_PARAM}`}
                  mt={4}
                >
                  Review repair log
                </Button>
              </VStack>
            </SectionContainer>
          )}

          {suffixInName && (
            <SectionContainer
              variant="box"
              p={3}
              header={
                <Text fontSize="sm" fontWeight="bold">
                  Repair names containing suffix
                </Text>
              }
              display="flex"
              flexDirection="column"
            >
              <VStack alignItems="start" spacing={3}>
                <HStack>
                  <Icon iconImage={IconImage.automation} />
                  <Text fontSize="sm">{formatNumber(totalSuffiData?.totalRepairs ?? 0)}</Text>
                  <Text fontSize="sm">total repairs</Text>
                </HStack>
                <Divider borderColor="gray.300" opacity="1" w="320px" />
                <HStack justifyContent="space-between" spacing={10} w="full">
                  <HStack spacing={0}>
                    <Text fontWeight="bold">{suffixInName.enabled ? 'Active' : 'Inactive'}</Text>
                    <chakra.span whiteSpace="nowrap">
                      {`${
                        // eslint-disable-next-line no-nested-ternary
                        suffixInName.enabled
                          ? ', running'
                          : suffixInName.lastEnabledOrDisabledAt
                          ? ', paused'
                          : ''
                      } ${
                        suffixInName.lastEnabledOrDisabledAt
                          ? `since ${formatDateString(
                              suffixInName.lastEnabledOrDisabledAt,
                              'MMM dd, yyyy'
                            )}`
                          : ''
                      }`}
                    </chakra.span>
                  </HStack>
                  <Can I="update" an="DataRepair">
                    <Switch
                      isChecked={isSuffixfromNameDataRepairActive}
                      onChange={() => suffixDataSwitchChanged(!isSuffixfromNameDataRepairActive)}
                    />
                  </Can>
                </HStack>
                <Button
                  variant="outline"
                  w="full"
                  as={Link}
                  to={`/sources/repair?automationType=${SUFFIX_IN_NAME_QUERY_PARAM}`}
                  mt={4}
                >
                  Review repair log
                </Button>
              </VStack>
            </SectionContainer>
          )}

          {middleInitialInName && (
            <SectionContainer
              variant="box"
              p={3}
              header={
                <Text fontSize="sm" fontWeight="bold">
                  Repair names containing middle initial
                </Text>
              }
              display="flex"
              flexDirection="column"
            >
              <VStack alignItems="start" spacing={3}>
                <HStack>
                  <Icon iconImage={IconImage.automation} />
                  <Text fontSize="sm">
                    {formatNumber(totalMiddleInitialData?.totalRepairs ?? 0)}
                  </Text>
                  <Text fontSize="sm">total repairs</Text>
                </HStack>
                <Divider borderColor="gray.300" opacity="1" w="320px" />
                <HStack justifyContent="space-between" spacing={10} w="full">
                  <HStack spacing={0}>
                    <Text fontWeight="bold">
                      {middleInitialInName.enabled ? 'Active' : 'Inactive'}
                    </Text>
                    <chakra.span whiteSpace="nowrap">
                      {`${
                        // eslint-disable-next-line no-nested-ternary
                        middleInitialInName.enabled
                          ? ', running'
                          : middleInitialInName.lastEnabledOrDisabledAt
                          ? ', paused'
                          : ''
                      } ${
                        middleInitialInName.lastEnabledOrDisabledAt
                          ? `since ${formatDateString(
                              middleInitialInName.lastEnabledOrDisabledAt,
                              'MMM dd, yyyy'
                            )}`
                          : ''
                      }`}
                    </chakra.span>
                  </HStack>
                  <Can I="update" an="DataRepair">
                    <Switch
                      isChecked={isMiddleInitialFromNameDataRepairActive}
                      onChange={() =>
                        middleInitialDataSwitchChanged(!isMiddleInitialFromNameDataRepairActive)
                      }
                    />
                  </Can>
                </HStack>
                <Button
                  variant="outline"
                  w="full"
                  as={Link}
                  to={`/sources/repair?automationType=${MIDDLE_INITIAL_IN_NAME_QUERY_PARAM}`}
                  mt={4}
                >
                  Review repair log
                </Button>
              </VStack>
            </SectionContainer>
          )}
        </SimpleGrid>
      </SectionContainer>
      <SectionContainer title="Data repair activity" mt={8}>
        <DataRepairAllAutomationChart />
      </SectionContainer>
    </PageLayout>
  );
}
export default AllRepairAutomation;
