import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Alert from '../../../components/core/Alert/Alert';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useAuth } from '../../../context/AuthenticationContext';
import ApiClient from '../../../lib/api-client/ApiClient';
import SourceClient from '../../../lib/api-client/sources/SourceClient';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import AdminTenantClient from '../../../lib/api-client/tenant/AdminTenantClient';
import { Tenant } from '../../../lib/api-client/tenant/model/Tenant';
import { attributeMappings, generateDemoTransactions } from './demo2-mappings';

interface Demo2Step4Props {
  sourceId: string | undefined;
}

function Demo2Step4({ sourceId, ...rest }: Demo2Step4Props & AccordionItemProps) {
  const [loading, setLoading] = useBoolean();
  const [success, setSuccess] = useBoolean();
  async function loadDemoData() {
    if (loading) {
      return;
    }

    setLoading.on();
    try {
      const data = generateDemoTransactions(50);
      await ApiClient.post<DataSource>(`/sources/${sourceId}/data`, {
        data,
      });
      setSuccess.on();
    } catch (err: any) {
      setSuccess.off();
      // eslint-disable-next-line no-alert
      alert(err?.response?.data?.error ? err.response.data.message : err);
    }
    setLoading.off();
  }

  return (
    <AccordionItem borderBottom="0" {...rest}>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontWeight="semibold">
            Step 4 - Start demo data ingestion
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Button as={Link} to="/" target="_blank" variant="outline">
          Open dashboard in new tab
        </Button>
        <Text pt="8" pb="5">
          Click the button to start ingesting demo data
        </Text>
        <HStack>
          <Button isLoading={loading} onClick={() => loadDemoData()}>
            Start data ingestion
          </Button>
          {success && (
            <Alert status="success" description="Success! You have started data ingestion." />
          )}
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  );
}

interface Demo2Step3Props {
  nextStep: () => void;
  setSourceId: React.Dispatch<React.SetStateAction<string>>;
}

function Demo2Step3({ nextStep, setSourceId, ...rest }: Demo2Step3Props & AccordionItemProps) {
  const [loading, setLoading] = useBoolean();
  const [success, setSuccess] = useBoolean();
  async function createDemoDataSource() {
    if (loading) {
      return;
    }

    setLoading.on();
    try {
      const source = await SourceClient.createDataSource({
        name: 'POS Demo Source',
        category: 'POS',
        attributeMappings,
      });
      await SourceClient.setDataSourceEnabled(source.id, true);
      setSuccess.on();
      setSourceId(source.id);
      nextStep();
    } catch (err) {
      setSuccess.off();
      // eslint-disable-next-line no-alert
      alert(err);
    }
    setLoading.off();
  }

  return (
    <AccordionItem borderBottom="0" {...rest}>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontWeight="semibold">
            Step 3 - Setup demo data source
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Text py="5">Click the button to automatically create an empty demo POS data source.</Text>
        <HStack>
          <Button isLoading={loading} onClick={() => createDemoDataSource()}>
            Create demo source
          </Button>
          {success && (
            <Alert
              status="success"
              description="Success! You have created an demo POS data source."
            />
          )}
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  );
}

interface SwitchTenantForm {
  password: string;
}

const switchTenantFormSchema = yup.object({
  password: yup.string().required('Password is required'),
});

interface Demo2Step2Props {
  nextStep: () => void;
  tenant: Tenant | undefined;
}

function Demo2Step2({ nextStep, tenant, ...rest }: Demo2Step2Props & AccordionItemProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SwitchTenantForm>({
    resolver: yupResolver(switchTenantFormSchema),
    mode: 'onBlur',
  });
  const { signIn, currentUser } = useAuth();
  const [success, setSuccess] = useBoolean();

  const onSubmit: SubmitHandler<SwitchTenantForm> = async ({ password }) => {
    try {
      await signIn({
        email: currentUser?.emailAddress || '',
        password,
        userPool: {
          id: tenant?.userPoolId || '',
          clientId: tenant?.authClientId || '',
        },
      });
      setSuccess.on();
      nextStep();
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err);
    }
  };

  return (
    <AccordionItem borderBottom="0" {...rest}>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontWeight="semibold">
            Step 2 - Switch to new tenant
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Box>
          <Text py="5">Switch to the newly created tenant by providing your password.</Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mb="10">
              <FormControl isInvalid={!!errors.password}>
                <FormLabel fontWeight="semibold">Password</FormLabel>
                <Input type="password" {...register('password')} />
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
            <HStack>
              <Button isLoading={isSubmitting} type="submit">
                Switch tenant
              </Button>
              {success && (
                <Alert status="success" description="Success! You are using new tenant now." />
              )}
            </HStack>
          </form>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
}

interface CreateDemoTenantForm {
  tenantId: string;
  tenantName: string;
}

const createDemoFormSchema = yup.object({
  tenantId: yup
    .string()
    .required('Tenant ID is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Tenant ID can only be letters and numbers'),
  tenantName: yup.string().required('Tenant name is required'),
});

interface Demo2Step1Props {
  nextStep: () => void;
  setTenant: React.Dispatch<React.SetStateAction<Tenant | undefined>>;
}

function Demo2Step1({ nextStep, setTenant, ...rest }: Demo2Step1Props & AccordionItemProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<CreateDemoTenantForm>({
    resolver: yupResolver(createDemoFormSchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<CreateDemoTenantForm> = async ({ tenantId, tenantName }) => {
    const tenant = await AdminTenantClient.createTenant({
      id: tenantId,
      name: tenantName,
      emailDomains: [`demo_${tenantId}.com`],
      dataNetwork: `demo_${tenantId}`,
      thirdPartyDataUsage: 'NONE',
      properties: {},
    });
    setTenant(tenant);
    nextStep();
  };

  return (
    <AccordionItem borderTop="0" {...rest}>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontWeight="semibold">
            Step 1 - Create a demo tenant
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Text py="5">Create a new demo tenant by providing a tenant id and tenant name.</Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack mb="10">
            <FormControl isInvalid={!!errors.tenantId}>
              <FormLabel fontWeight="semibold">Tenant ID</FormLabel>
              <Input type="text" {...register('tenantId')} />
              <FormErrorMessage>{errors?.tenantId?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.tenantName}>
              <FormLabel fontWeight="semibold">Tenant name</FormLabel>
              <Input type="text" {...register('tenantName')} />
              <FormErrorMessage>{errors?.tenantName?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
          <Button isLoading={isSubmitting} type="submit">
            Create tenant
          </Button>
        </form>
      </AccordionPanel>
    </AccordionItem>
  );
}

function Demo2Tab() {
  const [step, setStep] = useState(0);
  const [tenant, setTenant] = useState<Tenant>();
  const [sourceId, setSourceId] = useState<string>('');
  const nextStep = () => {
    setStep(step + 1);
  };
  return (
    <Accordion index={step} border="1px solid #888" borderRadius="4px">
      <Demo2Step1 nextStep={nextStep} setTenant={setTenant} isDisabled={step !== 0} />
      <Demo2Step2 nextStep={nextStep} tenant={tenant} isDisabled={step !== 1} />
      <Demo2Step3 nextStep={nextStep} setSourceId={setSourceId} isDisabled={step !== 2} />
      <Demo2Step4 sourceId={sourceId} isDisabled={step !== 3} />
    </Accordion>
  );
}

function SeviinToolsDemo2Page() {
  return (
    <PageLayout
      pageViewEvent={{ page: 'Demo2  Tool' }}
      header={
        <Header
          icon={<Icon iconImage={IconImage.settings} />}
          title="Demo2  Tool"
          back={{ label: 'Back to harpin AI tools', to: '/harpin-tools' }}
        />
      }
      data-testid="SeviinToolsDemo2Page"
    >
      <Text mb="10">
        Use Demo 2 to create a tenant and trigger the ingestion from a demo data source.
      </Text>
      <Demo2Tab />
    </PageLayout>
  );
}
export default SeviinToolsDemo2Page;
