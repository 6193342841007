import { isUndefined } from '../../../../lib/utils/utils';

export const RECORD_SCORE_RANGES = [
  { label: 'Accurate', value: 'verified' },
  { label: 'Questionable', value: 'suspicious' },
  { label: 'Inaccurate', value: 'nonverified' },
];

export const NAME_SCORE_RANGES = [
  { label: 'Recognized', value: 'verified' },
  { label: 'Questionable', value: 'suspicious' },
  { label: 'Unrecognized', value: 'nonverified' },
];

export const EMAIL_SCORE_RANGES = [
  { label: 'Valid', value: 'verified' },
  { label: 'Unverifiable', value: 'suspiciousEmail' },
  { label: 'Invalid', value: 'nonverified' },
];

export const ADDRESS_SCORE_RANGES = [
  { label: 'Valid', value: 'verified' },
  { label: 'Unverifiable', value: 'suspicious' },
  { label: 'Invalid', value: 'nonverified' },
];

export const VALIDITY_RANGES = [
  { label: 'Valid', value: 'verified' },
  { label: 'Invalid', value: 'nonverified' },
];

export function getDataRecordScoreLabel(
  score: number | undefined | null,
  labels: { label: string }[]
) {
  if (isUndefined(score) || score === null) {
    return '';
  }

  if (labels.length === 2) {
    return score === 1 ? labels[0].label : labels[1].label;
  }

  if (score === 0) {
    return labels[2].label;
  }

  if (score === 1) {
    return labels[0].label;
  }

  return labels[1].label;
}
