import Alert from '../../../../components/core/Alert/Alert';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';

export default function IngestScheduleErrorAlert() {
  const { ingestScheduleError } = useCurrentDataSource();
  if (!ingestScheduleError) {
    return null;
  }

  return (
    <Alert
      status="error"
      title="Ingestion error encountered"
      description={ingestScheduleError}
      closeable
    />
  );
}
