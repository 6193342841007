import {
  Box,
  Button,
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import TimeSeriesFilter, {
  filterConfig,
  SelectedTimeSeriesFilter,
} from '../../../components/shared/visualizations/TimeSeriesFilter/TimeSeriesFilter';
import { useDataRepairMetrics } from '../../../lib/api-client/data-repair/DataRepairData';
import IdentitySourceMetricsClient from '../../../lib/api-client/source-metrics/IdentitySourceMetricsClient';
import SourceMetricsClient from '../../../lib/api-client/source-metrics/SourceMetricsClient';
import { IdentityMetrics } from '../../../lib/api-client/source-metrics/model/IdentityDataSourceMetrics';
import { useSourceIssues } from '../../../lib/api-client/sources/SourceIssuesData';
import { SourceIssuePriority } from '../../../lib/api-client/sources/model/SourceIssuePriority';
import { TimeSeriesIntervalName } from '../../../lib/model/common';
import { formatDateString, getCurrentTimezone } from '../../../lib/utils/date-time-utils';
import { formatNumber, formatPercentage } from '../../../lib/utils/number-utils';
import DataQualityScoreFluctuationChip from '../components/DataFluctuationChip/DataQualityScoreFluctuationChip';
import DataQualityScoreIndicator from '../components/DataQualityScoreIndicator/DataQualityScoreIndicator';
import EnterPriseDataHealthCard from '../components/EnterpriseDataHealthCard/EnterpriseDataHealthCard';
import DataIngestSection from './DataIngestSection/DataIngestSection';
import EnterpriseSimpleLineGraph from './EnterpriseSimpleLineGraph';
import PieChartVisualization from './PieChartVisualization';
import { SourceColorContextProvider, useSourceColor } from './SourceColorContext';

interface IdentityMetricsProps {
  identityMetrics: IdentityMetrics;
}

function DataRecordsSection({ identityMetrics }: IdentityMetricsProps) {
  const { getColorForSource } = useSourceColor();
  const { sources, distribution } = useMemo<{ sources: any[]; distribution: number[] }>(() => {
    const orderedSources = identityMetrics.sources
      .sort((a, b) => b.metrics.totalRecords - a.metrics.totalRecords)
      .map((s) => ({
        id: s.source.id,
        name: s.source.name,
        value: s.metrics.totalRecords,
        color: getColorForSource(s.source.id),
      }));

    const total = orderedSources.reduce((prev, curr) => prev + curr.value, 0);
    const d =
      total === 0
        ? ([] as number[])
        : orderedSources.reduce(
            (prev, curr, idx) => {
              const percentage =
                idx + 1 === orderedSources.length
                  ? prev.percentage
                  : Math.max(Math.round((curr.value / total) * 100), 0);
              return {
                percentage: prev.percentage - percentage,
                distribution: [...prev.distribution, percentage],
              };
            },
            { percentage: 100, distribution: [] as number[] }
          ).distribution;

    return {
      sources: orderedSources,
      distribution: d,
    };
  }, [identityMetrics, getColorForSource]);

  return (
    <Box p={4} border="1px" boxShadow="base" borderRadius="md" borderColor="gray.100">
      <HStack spacing={3} fontSize="sm" mb={2}>
        <Text fontWeight="bold" fontSize="xl">
          {formatNumber(identityMetrics.metrics.totalRecords)}
        </Text>
        <Text>total data records across all data sources</Text>
      </HStack>
      <Box overflow="clip" rounded="full">
        <Flex>
          {distribution.map((d, idx) => (
            <Tooltip
              w="200px"
              bgColor="gray.100"
              placement="top"
              key={`tooltip-${sources[idx].id}`}
              label={
                <VStack alignItems="start">
                  <HStack>
                    <Tag variant="solid" bgColor={sources[idx].color} size="sm">
                      {' '}
                    </Tag>
                    <chakra.span fontWeight="bold" fontSize="large" color="gray.800">
                      {formatNumber(sources[idx].value)}{' '}
                    </chakra.span>
                  </HStack>
                  <Text fontWeight="400" color="gray.800">
                    total data records from {sources[idx].name}{' '}
                  </Text>
                </VStack>
              }
            >
              <Box
                h={4}
                bgColor={sources[idx].color}
                w={`${d}%`}
                key={`data-records-graph-${sources[idx].id}`}
              />
            </Tooltip>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}

function DataRepairsSection({
  totalRepairs,
  totalRepairLast24Hours,
}: {
  totalRepairs?: number;
  totalRepairLast24Hours?: number;
}) {
  return (
    <Box
      p={4}
      border="1px"
      boxShadow="base"
      borderRadius="md"
      borderColor="gray.100"
      justifyContent="space-between"
    >
      <HStack>
        <Text fontWeight="bold" fontSize="xl">
          {formatNumber(totalRepairs ?? 0)}
        </Text>
        <Text fontSize="sm" mb={2}>
          total data repairs across all connected source systems
        </Text>
      </HStack>

      <Tag rounded="full" fontSize="xs" minH={4} px={2} py={1.5} mt={2} bgColor="gray.200">
        <TagLabel fontWeight="bold" lineHeight={1}>
          {totalRepairLast24Hours}
          <chakra.span fontWeight="400"> in the last 24 hours </chakra.span>
        </TagLabel>
      </Tag>
    </Box>
  );
}

function ToolTipContent({ payload }: any) {
  const qualityScore = formatPercentage(payload?.[0]?.value);

  return (
    <VStack alignItems="start" spacing={0}>
      <Text fontWeight="bold">{qualityScore} data quality score </Text>
      <Text> during this time window</Text>
    </VStack>
  );
}

function EnterpriseDataDashboardPage() {
  const [identityMetrics, setSystemSourceIdentityMetrics] = useState<IdentityMetrics>();
  const [timeseries, setTimeSeries] = useState<{ label: string; value: number }[]>();
  const { data: useSourceIssuesData } = useSourceIssues(SourceIssuePriority.High);
  const sourceIssues = useSourceIssuesData ?? {
    content: [],
    qualityIssueAlertChange: 0,
    qualityIssueAlertCount: 0,
    totalRecordsWithSourceIssues: 0,
  };
  const { data: dataRepairMetrics, loading: dataRepairMetricsLoading } = useDataRepairMetrics();
  const isLoading = !(identityMetrics && sourceIssues) || dataRepairMetricsLoading;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filter, setFilter] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });

  useEffect(() => {
    async function load() {
      const now = new Date();
      const [getIdentitySources, getSystemTimeSeriesData] = await Promise.all([
        IdentitySourceMetricsClient.getIdentitySources(),
        SourceMetricsClient.getSystemTimeSeriesData(
          ['most_recent:dataQualityScore'],
          filter.config.getStartDate(now),
          now,
          getCurrentTimezone(),
          filter?.interval as TimeSeriesIntervalName,
          'count'
        ).then((data) => {
          if (data.series.length < 1) {
            return [];
          }

          return data.series[0].dataPoints.map((d) => ({
            label: formatDateString(d.startDate, 'M/d'),
            value: d.value,
          }));
        }),
      ]);
      setTimeSeries(getSystemTimeSeriesData);
      setSystemSourceIdentityMetrics(getIdentitySources);
    }

    void load();
  }, [filter.config, filter?.interval]);

  const qualityAlertSourceIdCounts = useMemo(() => {
    const counts = sourceIssues?.content?.reduce((acc: any, item: any) => {
      acc[item.sourceId] = (acc[item.sourceId] || 0) + 1;
      return acc;
    }, {});
    return counts;
  }, [sourceIssues.content]);

  const qualityAlertSourceIds = useMemo(
    () => Object.keys(qualityAlertSourceIdCounts),
    [qualityAlertSourceIdCounts]
  );

  const getColorForValue = (value: number): string => {
    if (value >= 0 && value < 0.6) {
      return '#BD123B';
    }
    if (value >= 0.6 && value < 0.8) {
      return '#F35135';
    }
    return '#00866E';
  };

  const dotColor = timeseries && timeseries.map((item: any) => getColorForValue(item.value));

  const onFilterChanged = (selectedFilter: SelectedTimeSeriesFilter) => {
    setFilter(selectedFilter);
  };

  return (
    <SourceColorContextProvider>
      <PageLayout
        pageViewEvent={{ page: 'Enterprise data dashboard' }}
        header={
          <Header
            title="Enterprise data dashboard"
            icon={<Icon iconImage={IconImage.databases} />}
            noSearch
          />
        }
        loading={isLoading}
      >
        <Stack spacing={6}>
          <SimpleGrid columns={2} spacing={4}>
            <DataRecordsSection identityMetrics={identityMetrics!} />
            <DataRepairsSection
              totalRepairs={dataRepairMetrics?.metrics?.totalRepairs}
              totalRepairLast24Hours={dataRepairMetrics?.metrics?.totalRepairsLastDay}
            />
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4}>
            <DataIngestSection />
            <PieChartVisualization width={164} height={174} />
          </SimpleGrid>
          <SectionContainer title="Data quality">
            <SimpleGrid columns={2} spacing={4}>
              <Box
                p={4}
                border="1px"
                boxShadow="base"
                borderRadius="md"
                borderColor="gray.100"
                h="60px"
              >
                {identityMetrics && (
                  <HStack spacing={3} fontSize="sm" justifyContent="space-between">
                    <HStack>
                      <DataQualityScoreIndicator
                        score={identityMetrics.metrics.dataQualityScore}
                        w={3}
                      />
                      <Text fontWeight="bold" fontSize="xl">
                        {formatPercentage(identityMetrics.metrics.dataQualityScore)}
                      </Text>
                      <Text>enterprise data quality score</Text>
                      <DataQualityScoreFluctuationChip
                        value={identityMetrics.metrics.dataQualityScoreChange}
                      />
                    </HStack>
                    <Button onClick={onOpen} disabled variant="outline">
                      {' '}
                      Learn more
                    </Button>
                  </HStack>
                )}
              </Box>

              <Drawer isOpen={isOpen} size="xl" placement="right" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton
                    size="lg"
                    right="unset"
                    top="40px"
                    left="-40px"
                    fontSize="xs"
                    color="white"
                    bgColor="action"
                    _hover={{ backgroundColor: 'actionDark' }}
                    borderRightRadius={0}
                  />
                  <DrawerHeader mb={-4}>Enterprise data quality score</DrawerHeader>

                  <DrawerBody>
                    <Text mb={2}>
                      This is the percentage of high quality data records ingested across all
                      connected data sources. Data quality is determined by a data record’s
                      usability for identity resolution which considers data accuracy, completeness
                      and pinnability.
                    </Text>
                    <Box mt={6}>
                      <Box mb={4}>
                        <TimeSeriesFilter onFilterChanged={onFilterChanged} hideHourFilter />
                      </Box>
                      <EnterpriseSimpleLineGraph
                        referenceValue={0.8}
                        xDataKey="label"
                        yDataKey="value"
                        data={timeseries ?? []}
                        width="100%"
                        height={340}
                        yTickFormatter={(v) => formatPercentage(v)}
                        yDomain={[0.1, 1]}
                        variant={dotColor}
                        tooltipContent={<ToolTipContent interval={filter.interval} />}
                        activeVariant={dotColor}
                        referenceContent="80% or above is the target score"
                      />
                    </Box>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
              <Box
                p={4}
                border="1px"
                boxShadow="base"
                borderRadius="md"
                borderColor="gray.100"
                h="60px"
              >
                <HStack spacing={3} fontSize="sm" justifyContent="space-between">
                  <HStack>
                    <Text fontWeight="bold" fontSize="xl">
                      {formatNumber(sourceIssues?.qualityIssueAlertCount ?? 0)}
                    </Text>
                    <Text>quality alerts across all data sources</Text>
                    <DataQualityScoreFluctuationChip
                      value={sourceIssues?.qualityIssueAlertChange ?? 0}
                    />
                  </HStack>
                  <Tooltip
                    label="Alerts are triggered when a data quality issue is detected in 10% or more of the records from a data source."
                    fontSize="md"
                    placement="left"
                  >
                    <chakra.span>
                      {' '}
                      <Icon iconImage={IconImage.help} color="action" />
                    </chakra.span>
                  </Tooltip>
                </HStack>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={4} spacing={6} mt={5} w="full">
              {identityMetrics?.sources
                .sort((a, b) => {
                  const highA = qualityAlertSourceIds.includes(a.source.id);
                  const highB = qualityAlertSourceIds.includes(b.source.id);
                  if (highA && !highB) {
                    return -1;
                  }
                  if (highB && !highA) {
                    return 1;
                  }
                  return a.source.name.localeCompare(b.source.name);
                })
                .map((source) => (
                  <EnterPriseDataHealthCard
                    key={`Enterprise-data-health-${source.source.id}`}
                    source={source.source}
                    totalQualityScore={source.metrics.dataQualityScore}
                    totalQualityScorePercentage={source.metrics.dataQualityScoreChange}
                    qualityAlert={qualityAlertSourceIdCounts[source.source.id] || 0}
                    hasQualityAlert={qualityAlertSourceIds.includes(source.source.id)}
                  />
                ))}
            </SimpleGrid>
          </SectionContainer>
        </Stack>
      </PageLayout>
    </SourceColorContextProvider>
  );
}
export default EnterpriseDataDashboardPage;
