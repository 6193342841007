import { format, formatDistanceStrict, formatRelative, isValid, parseISO } from 'date-fns';

/**
 * Format ISO date string to another format
 *
 * @param dateStr date string in ISO-8601 format
 * @param formatStr format using unicode tokens - https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 */
export function formatDateString(dateStr: string, formatStr: string) {
  if (!dateStr || !isValid(parseISO(dateStr))) {
    return '';
  }
  return format(parseISO(dateStr), formatStr);
}

/**
 * Format ISO date string to relative format
 *
 * @param dateStr date string in ISO-8601 format
 * @param baseDate base date to be relative from
 */
export function formatRelativeDateString(dateStr: string, baseDate = new Date()) {
  return formatRelative(parseISO(dateStr), baseDate);
}

export function DateDifferenceFormatted(value: any) {
  const [year, month, day] = value.startDate.split('-');
  const [hour, minute] = value.startTime.split(':');
  const [endYear, endMonth, endDay] = value.endDate.split('-');
  const [endHour, endMinute] = value.endTime.split(':');

  // eslint-disable-next-line radix
  let convertedHour = parseInt(hour);
  // eslint-disable-next-line radix
  let convertedEndHour = parseInt(endHour);

  if (value.startTimezone === 'PM' && convertedHour !== 12) {
    convertedHour += 12;
  } else if (value.startTimezone === 'AM' && convertedHour === 12) {
    convertedHour = 0;
  }

  if (value.endTimezone === 'PM' && convertedEndHour !== 12) {
    convertedEndHour += 12;
  } else if (value.endTimezone === 'AM' && convertedEndHour === 12) {
    convertedEndHour = 0;
  }

  const convertedStartDate = new Date(year, month - 1, day, convertedHour, minute);

  const convertedEndDate = new Date(endYear, endMonth - 1, endDay, convertedEndHour, endMinute);

  const formattedStartDate = convertedStartDate.toISOString();
  const formattedEndDate = convertedEndDate.toISOString();

  return { formattedStartDate, formattedEndDate };
}

/**
 * Get the timezone in the browser
 */
export function getCurrentTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Format ISO date string to relative format strict and round to floor
 *
 * @param date date object or date string in ISO-8601 format
 * @param baseDate base date to be relative from
 */
export function formatRelativeDateStringStrict(date: string | Date, baseDate = new Date()) {
  return `${formatDistanceStrict(typeof date === 'string' ? parseISO(date) : date, baseDate, {
    roundingMethod: 'floor',
  }).replace('minute', 'min')} ago`;
}

export function getFormattedSixMonthAgoDate(date: Date) {
  date.setMonth(date.getMonth() - 6);
  const formattedDate = `${date.toISOString().split('T')[0]}T00:00:00Z`;
  return formattedDate;
}

export function getDateDifferenceString(earliestDate: any, latestDate: any) {
  const differenceInMilliseconds = earliestDate.getTime() - latestDate.getTime();

  const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) - 1;

  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30);
  const remainingDays = days - years * 365 - months * 30;

  let result = '';
  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''}`;
  }
  if (months > 0) {
    if (result.length > 0) {
      result += ', ';
    }
    result += `${months} month${months > 1 ? 's' : ''}`;
  }
  if (remainingDays >= 0) {
    if (result.length > 0) {
      result += ' and ';
    }
    result += `${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
  }

  return result;
}

export function formatDays(days: any) {
  const years = Math.floor(days / 365);
  const remainingDays = days % 365;
  const months = Math.floor(remainingDays / 30);
  const remainingDaysInMonth = remainingDays % 30;

  let result = '';

  if (years > 0) {
    result += `${years} ${years === 1 ? 'year' : 'years'}`;
  }

  if (months > 0) {
    if (result.length > 0) {
      result += ', ';
    }
    result += `${months} ${months === 1 ? 'month' : 'months'}`;
  }

  if (remainingDaysInMonth > 0) {
    if (result.length > 0) {
      result += ' and ';
    }
    result += `${remainingDaysInMonth} ${remainingDaysInMonth === 1 ? 'day' : 'days'}`;
  }

  return result;
}

export function convertDuration(durationMillis: any) {
  const seconds = Math.floor(durationMillis / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30.44);
  const remainingDays = Math.floor((days % 365) % 30.44);
  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  const parts = [];

  if (years > 0) parts.push(`${years} years`);
  if (months > 0) parts.push(`${months} months`);
  if (remainingDays > 0) parts.push(`${remainingDays} days`);
  if (remainingHours > 0) parts.push(`${remainingHours} hours`);
  if (remainingMinutes > 0) parts.push(`${remainingMinutes} minutes`);
  if (remainingSeconds > 0) parts.push(`${remainingSeconds} seconds`);

  return `${parts.join(', ')} duration`;
}
