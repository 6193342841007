import {
  Box,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ColumnDef, createColumnHelper, PaginationState } from '@tanstack/react-table';
import consola from 'consola';
import React, { ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CyborgMachineLearning } from '../../../assets/images/cyborg-machine-learning.svg';
import Alert from '../../../components/core/Alert/Alert';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import DataExplorer from '../../../components/shared/data-explorer/DataExplorer/DataExplorer';
import DataRecordDetails from '../../../components/shared/data-explorer/DataRecordDetails/DataRecordDetails';
import EditRecordModal from '../../../components/shared/data-explorer/EditRecordModal/EditRecordModal';
import { downloadData } from '../../../lib/api-client/ApiClient';
import { recordCategoryToLabel } from '../../../lib/api-client/identity/model/SourceIdentityRecordCategory';
import { recordGroupToLabel } from '../../../lib/api-client/identity/model/SourceIdentityRecordGroup';
import { SourceIdentityRecordSearchResponse } from '../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import IssueReportExecutionClient from '../../../lib/api-client/reports/IssueReportExecutionClient ';
import {
  useIssueReportExecution,
  useGetIssueReport,
} from '../../../lib/api-client/reports/ReportExecutionData';
import SourceDatabyIdClient from '../../../lib/api-client/reports/SourceDataByID';
import { StartReportExecution } from '../../../lib/api-client/reports/report.model';
import { Page } from '../../../lib/model/common/Page';
import { formatNumber } from '../../../lib/utils/number-utils';
import { isUndefined } from '../../../lib/utils/utils';
import {
  ADDRESS_SCORE_RANGES,
  EMAIL_SCORE_RANGES,
  NAME_SCORE_RANGES,
  VALIDITY_RANGES,
} from '../../data-explorer/components/SourceSystemRecordExplorer/description-utils';
import {
  PRIORITY_QUERY_RECORD_CATEGORY,
  PRIORITY_QUERY_RECORD_GROUP,
} from '../DataQualityIssuesPage/DataQualityIssuesPage';
import ColumnCell from '../components/SourceSystemRecordExplorer/ColumnCell';
import ProfileLinkCell from '../components/SourceSystemRecordExplorer/ProfileLinkCell';
import ScoreCell from '../components/SourceSystemRecordExplorer/ScoreCell';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor((data) => data?.pin, {
    header: 'PIN',
    id: 'pin',
    minSize: 200,
    enableSorting: false,
    cell: (cellContext) => <ProfileLinkCell cellContext={cellContext} />,
  }),
  columnHelper.accessor((data) => data?.sourceRecordId, {
    header: 'Source Record ID ',
    minSize: 180,
    id: 'sourceRecordId',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.firstNames, {
    header: 'first name ',
    minSize: 180,
    id: 'firstNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.firstNameScores, {
    header: 'First name score ',
    minSize: 180,
    id: 'firstNameScores',
    enableSorting: false,
    cell: (props) => <ScoreCell cellContext={props} labels={NAME_SCORE_RANGES} />,
  }),

  columnHelper.accessor((data) => data?.lastNames, {
    header: 'Last name ',
    minSize: 180,
    id: 'lastNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.lastNameScores, {
    header: 'Last name score ',
    minSize: 180,
    id: 'lastNameScores',
    cell: (props) => <ScoreCell cellContext={props} labels={NAME_SCORE_RANGES} />,

    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.middleNames, {
    header: 'Middle name ',
    minSize: 180,
    id: 'middleNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.nameSuffixes, {
    header: 'Name suffix ',
    minSize: 180,
    id: 'nameSuffixes',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.emailAddresses, {
    header: 'Email Address',
    minSize: 280,
    id: 'emailAddresses',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data.emailAddressScores, {
    header: 'Email address score',
    minSize: 280,
    cell: (props) => <ScoreCell cellContext={props} labels={EMAIL_SCORE_RANGES} />,
    id: 'emailAddressScores',
    enableSorting: false,
  }),
  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('MOBILE');
      if (mobileIndex !== -1) {
        return data.phoneNumbers[mobileIndex];
      }
      return '';
    },
    {
      header: 'Mobile phone',
      minSize: 20,
      id: 'mobilePhoneNumber',
      enableSorting: false,
      cell: (props) => <ColumnCell cellContext={props} />,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('MOBILE');
      if (mobileIndex !== -1) {
        return data.phoneNumberScores[mobileIndex];
      }
      return '';
    },
    {
      header: 'Mobile phone score',
      minSize: 180,
      id: 'mobilePhoneNumberScores',
      cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      enableSorting: false,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const homeIndex = data.phoneNumberTypes.indexOf('HOME');
      if (homeIndex !== -1) {
        return data.phoneNumbers[homeIndex];
      }
      return '';
    },
    {
      header: 'Home phone',
      minSize: 20,
      id: 'homePhoneNumber',
      enableSorting: false,
      cell: (props) => <ColumnCell cellContext={props} />,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('HOME');
      if (mobileIndex !== -1) {
        return data.phoneNumberScores[mobileIndex];
      }
      return '';
    },
    {
      header: 'Home phone score',
      minSize: 180,
      id: 'homePhoneNumberScores',
      cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) => {
      const workIndex = data.phoneNumberTypes.indexOf('WORK');
      if (workIndex !== -1) {
        return data.phoneNumbers[workIndex];
      }
      return '';
    },
    {
      header: 'Work phone',
      minSize: 20,
      id: 'workPhoneNumber',
      enableSorting: false,
      cell: (props) => <ColumnCell cellContext={props} />,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('WORK');
      if (mobileIndex !== -1) {
        return data.phoneNumberScores[mobileIndex];
      }
      return '';
    },
    {
      header: 'Work phone score',
      minSize: 180,
      id: 'workPhoneNumberScores',
      cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      enableSorting: false,
    }
  ),

  columnHelper.accessor((data) => data?.cities, {
    header: 'City ',
    minSize: 180,
    id: 'cities',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.countries, {
    header: 'Country ',
    minSize: 180,
    id: 'countries',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.countryCodes, {
    header: 'Country code ',
    minSize: 180,
    id: 'countryCodes',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.governingDistricts, {
    header: 'Governing district ',
    minSize: 180,
    id: 'governingDistricts',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.localMunicipalities, {
    header: 'Local municipality ',
    minSize: 180,
    id: 'localMunicipalities',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.postalCodes, {
    header: 'Postal code ',
    minSize: 180,
    id: 'postalCodes',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.addressScores, {
    header: ' Address score',
    minSize: 180,
    id: 'addressScores',
    cell: (props) => <ScoreCell cellContext={props} labels={ADDRESS_SCORE_RANGES} />,

    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.dateOfBirth, {
    header: 'Date of birth',
    minSize: 20,
    id: 'dateOfBirth',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.dateOfBirthScore, {
    header: 'Date of birth score',
    minSize: 20,
    id: 'dateOfBirthScore',
    cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
    enableSorting: false,
  }),
  columnHelper.accessor((data) => data?.gender, {
    header: 'Gender',
    minSize: 20,
    id: 'gender',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.fieldNames, {
    header: 'field Names',
    minSize: 20,
    id: 'fieldNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.fieldValues, {
    header: 'Field Values',
    minSize: 20,
    id: 'fieldValues',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
];

function ReportLoading() {
  return (
    <Flex direction="column" h="full" flexFlow="column">
      <Center h="full">
        <VStack>
          <HStack>
            <Spinner color="action" />

            <Text fontSize="xl" fontWeight="bold">
              One moment while we load the full set of data quality issues ...
            </Text>
          </HStack>
          <Text>
            Please remain on this screen to avoid disruption. If the data quality issues don’t load
            within a few minutes, try refreshing.
          </Text>
          <CyborgMachineLearning />
        </VStack>
      </Center>
    </Flex>
  );
}

type SearchParams = {
  pagination: PaginationState & {
    nextToken?: string;
  };
};

const PAGE_SIZE = 100;

interface ReportExplorerProps {
  reportId: string;
  reportParams?: Omit<StartReportExecution, 'reportType'>;
  children?: ReactNode;
}
export default function ReportExplorerQualityIssue({
  reportId: sourceIssueType,
  reportParams: defaultReportParams,

  children,
}: ReportExplorerProps) {
  const [queryParams] = useSearchParams();
  const runOnLoad = queryParams.get('execute')?.toLowerCase() !== 'false' ?? true;
  const { dataSource } = useCurrentDataSource();
  const [searchParameters, setSearchParameters] = useState<SearchParams>({
    pagination: {
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    },
  });
  const recordGroup = queryParams.get(PRIORITY_QUERY_RECORD_GROUP) ?? '';
  const recordCategory = queryParams.get(PRIORITY_QUERY_RECORD_CATEGORY) ?? '';

  const [nextTokens, setNextTokens] = useState<(string | undefined)[]>([undefined]);
  const toast = useToast();

  const editRecordDisclosure = useDisclosure();
  const editDetailsRecordDisclosure = useDisclosure();
  const [exportDataResponse, setExportDataResponse] = useState();
  const [editRecordIndex, setEditRecordIndex] = useState<any>();
  const [modifiedData, setModifiedData] = useState<Page<SourceIdentityRecordSearchResponse>>();

  const handleExportSubmit = async (sourceRecordID: any) => {
    try {
      const response = await SourceDatabyIdClient.exportIssueReportData(
        dataSource.id,
        sourceRecordID
      );

      setExportDataResponse(response);
    } catch (error) {
      consola.error(error);
    }
  };

  const reportParams = {
    ...defaultReportParams,
    sourceIssueType,
  };

  const { data: IssuereportMetadata = { reportId: '' } } = useIssueReportExecution(
    dataSource?.id,
    reportParams as any
  );

  const { data: reportPage } = useGetIssueReport(
    dataSource.id,
    IssuereportMetadata?.reportId ?? '',
    {
      nextToken: searchParameters.pagination.nextToken,
      pageSize: searchParameters.pagination.pageSize,
      enabled: !!IssuereportMetadata?.reportId,
    }
  );

  let filteredColumns;
  if (reportPage?.content) {
    const phoneNumberTypes = reportPage.content.flatMap(
      (content: any) => content?.phoneNumberTypes
    );
    const phoneNumberScores = reportPage.content.flatMap(
      (content: any) => content?.phoneNumberScores
    );
    const hasMobile = phoneNumberTypes?.includes('MOBILE');
    const hasHome = phoneNumberTypes?.includes('HOME');
    const hasWork = phoneNumberTypes?.includes('WORK');

    filteredColumns = columns.filter((item) => {
      if (
        (item.id === 'mobilePhoneNumber' && hasMobile) ||
        (item.id === 'homePhoneNumber' && hasHome) ||
        (item.id === 'workPhoneNumber' && hasWork) ||
        (item.id === 'mobilePhoneNumberScores' && hasMobile) ||
        (item.id === 'homePhoneNumberScores' && hasHome) ||
        (item.id === 'workPhoneNumberScores' && hasWork)
      ) {
        return true;
      }
      return reportPage.content.some(
        (content: { hasOwnProperty: (arg0: string | undefined) => any }) =>
          // eslint-disable-next-line no-prototype-builtins
          content?.hasOwnProperty(item.id)
      );
    });

    const mobileScoreIndex = phoneNumberTypes?.indexOf('MOBILE');
    const homeScoreIndex = phoneNumberTypes?.indexOf('HOME');
    const workScoreIndex = phoneNumberTypes?.indexOf('WORK');
    if (mobileScoreIndex !== -1 && homeScoreIndex !== -1 && workScoreIndex !== -1) {
      const mobileScore = phoneNumberScores?.[mobileScoreIndex];
      const homeScore = phoneNumberScores?.[homeScoreIndex];
      const workScore = phoneNumberScores?.[workScoreIndex];
      reportPage.content[0].phoneNumberScores = [mobileScore, homeScore, workScore];
    }
  }
  const [pageUrl] = useState(window.location.href);
  useEffect(() => {
    if (reportPage) {
      if (nextTokens[searchParameters.pagination.pageIndex + 1] !== reportPage.nextToken) {
        const tokenCache = [...nextTokens];
        tokenCache[searchParameters.pagination.pageIndex + 1] = reportPage.nextToken;
        setNextTokens(tokenCache);
      }
    }
  }, [reportPage, searchParameters, nextTokens]);

  if (!reportPage && runOnLoad) {
    return <ReportLoading />;
  }

  const exportData = async () => {
    toast({
      title: 'CSV is being prepared for export.',
      description: 'Hold tight. This may take a few min.',
    });

    await IssueReportExecutionClient.exportIssueReportData(
      dataSource.id,
      IssuereportMetadata.reportId
    ).then((res) => downloadData(res, `${sourceIssueType}_export_${new Date().getTime()}.csv`));
    toast({
      title: 'CSV has been exported.',
      status: 'success',
    });
  };

  const handleCloseEditModal = () => {
    editRecordDisclosure.onClose();
  };

  let page: Page<unknown> = {
    content: [],
    totalPages: 1,
    totalElements: 0,
    first: true,
    last: true,
    size: 0,
    number: 1,
    numberOfElements: 0,
  };

  if (reportPage) {
    page = {
      content: reportPage.content,
      totalPages: Math.ceil(reportPage.totalRows / searchParameters.pagination.pageSize),
      totalElements: reportPage.totalRows,
      first: searchParameters.pagination.pageIndex === 0,
      last: !!reportPage.nextToken,
      size: reportPage.recordCount ?? 0,
      number: searchParameters.pagination.pageIndex,
      numberOfElements: reportPage?.recordCount ?? 0,
    };
  }

  return (
    <Flex data-testid="ReportExplorer" flexDir="column" h="full">
      {!reportPage && (
        <Alert
          status="error"
          title="We were unable to load the data issue report due to an error."
          description=" To generate a new data report, start by configuring the query parameters."
          mb={5}
        />
      )}
      {!reportPage && (
        <Alert
          status="warning"
          variant="subtle"
          description="Start by configuring the query paramters to generate a custom data issue report."
          mb={5}
        />
      )}

      <HStack spacing={3} mb={4}>
        <DataSourceIcon sourceSystem={dataSource.sourceSystem} boxSize={12} />
        <Box>
          <Text fontWeight="bold" lineHeight={8}>
            {recordGroupToLabel(recordGroup)}: {recordCategoryToLabel(recordCategory as any)}
          </Text>
          <Text lineHeight="6">{formatNumber(reportPage?.totalRows ?? 0)} data records</Text>
        </Box>
      </HStack>
      <DataExplorer
        data={page}
        defaultPagination={searchParameters.pagination}
        columns={(filteredColumns ?? []) as ColumnDef<any>[]}
        defaultSortOrder={(reportParams.sortOrder ?? []).map((s) => ({
          id: s.columnName,
          desc: s.descending ?? false,
        }))}
        defaultDrawerIsOpen={!runOnLoad}
        pageUrl={pageUrl}
        onFetcherParamChange={(params) => {
          const index = params.pagination.pageIndex;
          const nextToken = nextTokens[index];
          setSearchParameters({ ...params, pagination: { ...params.pagination, nextToken } });
        }}
        onExport={async () => {
          exportData();
        }}
        onRowSelected={(e) => {
          handleExportSubmit(e.data?.sourceRecordId);
          setEditRecordIndex(e.data?.sourceRecordId);
          editRecordDisclosure.onOpen();
        }}
        onRowSelectedDataDetails={(e: any) => {
          handleExportSubmit(e.data?.sourceRecordId);
          setEditRecordIndex(e.data?.sourceRecordId);
          editDetailsRecordDisclosure.onOpen();
        }}
        hideEditMode
        hideFilters={!children}
        hideColumn
      >
        {children}
      </DataExplorer>

      <EditRecordModal
        isOpen={editRecordDisclosure.isOpen}
        onClose={handleCloseEditModal}
        data={isUndefined(editRecordIndex) ? undefined : exportDataResponse}
        onRowValueChanged={(editedRecord) => {
          const d = modifiedData ?? exportDataResponse;
          const content = Array.isArray(d) ? [...d] : [];
          if (!isUndefined(editRecordIndex)) {
            content[editRecordIndex] = editedRecord;
          }
          if (d) {
            setModifiedData({ ...d, content } as SetStateAction<
              Page<SourceIdentityRecordSearchResponse> | undefined
            >);
          }
        }}
      />

      <DataRecordDetails
        isOpen={editDetailsRecordDisclosure.isOpen}
        onClose={() => editDetailsRecordDisclosure.onClose()}
        data={exportDataResponse}
        editRecordModalOpen={editRecordDisclosure.onOpen}
        editRecordModalClose={editDetailsRecordDisclosure.onClose}
      />
    </Flex>
  );
}
