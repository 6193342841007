import { SingleQuery } from '@hazae41/xswr';
import { isAxiosError } from 'axios';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  useGetDataSource,
  useGetDataSourceExportStatus,
  useGetSourceIngestHistory,
  useGetSourceSystemType,
} from '../../../lib/api-client/sources/SourceData';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import { ExportStatus } from '../../../lib/api-client/sources/model/ExportStatus';
import { SourceSystemType } from '../../../lib/api-client/sources/model/SourceTypes';

export interface CurrentDataSourceContext {
  dataSource: DataSource;
  sourceSystemType: SourceSystemType;
  exportStatus?: ExportStatus;
  refetchDataSource: SingleQuery<DataSource>['fetch'];
  loading: boolean;
  ingestScheduleError?: string;
}

const Context = createContext<CurrentDataSourceContext | undefined>(undefined);

function assertContext(
  state?: Partial<CurrentDataSourceContext>
): asserts state is CurrentDataSourceContext {
  if (!state?.dataSource) {
    throw new Error(`'state' has a undefined dataSource`);
  }
}

const sourceConfigState: Record<string, DataSource['configState']> = {};

export function CurrentDataSourceProvider({ children }: { children: ReactNode }) {
  const { id = '' } = useParams();
  const { data, error, fetch, loading } = useGetDataSource(id);
  const { data: systemTypeData, error: systemTypeDataError } = useGetSourceSystemType(
    data?.sourceSystem ?? '',
    data?.sourceSystem != null
  );

  const { data: history } = useGetSourceIngestHistory(id, {
    page: 0,
    size: 1,
    sort: 'endTime,desc',
  });
  const latestHistoryStatus = history?.content?.[0]?.status;
  const latestHistoryErrorMessage = history?.content?.[0]?.errorMessage;

  const isExportStatusEnabled =
    data?.properties?.exportMode === 'enabled' && data?.syncDirection !== 'IN';
  const { data: exportStatus } = useGetDataSourceExportStatus(
    data?.id ?? '',
    isExportStatusEnabled
  );

  const state = useMemo(() => {
    if (data) {
      const { configState } = data;
      if (sourceConfigState[data.id] !== configState) {
        if (sourceConfigState[data.id] != null && configState === 'COMPLETE') {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('firstSetup', 'true');
          window.history.replaceState(null, '', `?${searchParams.toString()}`);
        }
        sourceConfigState[data.id] = configState;
      }
    }

    return {
      dataSource: data,
      sourceSystemType: systemTypeData,
      exportStatus,
      refetchDataSource: fetch,
      loading,
      ingestScheduleError: latestHistoryStatus === 'FAILED' ? latestHistoryErrorMessage : undefined,
    };
  }, [
    data,
    systemTypeData,
    exportStatus,
    fetch,
    loading,
    latestHistoryStatus,
    latestHistoryErrorMessage,
  ]);

  if (error || systemTypeDataError) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return <Navigate to="/404" replace />;
    }

    throw error ?? systemTypeDataError;
  }

  if (!state.dataSource || !systemTypeData) {
    return null;
  }

  assertContext(state);
  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export function useCurrentDataSource(): CurrentDataSourceContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useCurrentDataSource must be used within a CurrentDataSourceProvider');
  }

  return context;
}
